import React from 'react';
import { ToastContainer } from 'react-toastify';
import { RoutesComponent } from './Routes';
import AuthWrapper from './providers/AuthWrapper';

function App() {
    return (
        <>
            <AuthWrapper>
                {RoutesComponent()}
                <ToastContainer newestOnTop />
            </AuthWrapper>
        </>
    );
}

export default App;
