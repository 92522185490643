import { nanoid } from '@reduxjs/toolkit';
import { Error } from 'components/Error';
import { Tab } from 'components/Tab';
import { useChartDataParams } from 'hooks/useChartDataParams';
import { useComparisonRequestData } from 'hooks/useComparisonRequestData';
import { PossibleUrlQuery, useQueryParams } from 'hooks/useQueryParams';
import { censusAPI } from 'services/Demographics';
import { DemographicsTable } from '../DemographicsTable';
import styles from '../DemographicsTable.module.scss';

export const DemographicsProjects = () => {
    // queries for main request
    const { queries } = useQueryParams([
        PossibleUrlQuery.scenario_uuids,
        PossibleUrlQuery.aggregate_projects
    ], {
        [PossibleUrlQuery.aggregate_projects]: false
    });

    // main request
    const {
        data: censusProjectsData = [],
        isFetching: censusProjectsDataLoading,
        error: censusProjectsError
    } = censusAPI.useGetDemographicDataQuery({ params: queries }, {
        skip: !queries
    });
    // request for comparison mode
    const {
        comparisonReport: comparisonCensusProjectsData,
        isFetching: comparisonCensusProjectsDataLoading,
        error: comparisonCensusProjectsError
    } = useComparisonRequestData('/demographics/census', true,
        {
            [PossibleUrlQuery.aggregate_projects]: false
        });

    const { data: chartDataParams } = useChartDataParams(true);

    const isLoadingState = censusProjectsDataLoading || comparisonCensusProjectsDataLoading;
    const isErrorState = censusProjectsError || comparisonCensusProjectsError;

    if (isErrorState) {
        return <div className={styles.center}>
            <Error text='Something went wrong. Please try again later or click the button.' />
        </div>;
    }

    // main data object structure 
    // need this for possibility of compare data
    const chartData: {
        id: string,
        response: any,
        scenario: { value: string, label: string } | null,
        project: { value: string, label: string } | null,
    }[] = [
            {
                id: nanoid(),
                ...chartDataParams,
                response: censusProjectsData,
            },
            ...comparisonCensusProjectsData
        ];

    const tabConfig = chartData.map((el, i) => {
        return {
            label: el.scenario?.label || '',
            key: i + '',
            children:
                <>
                    <div className={styles.tableIndent}>
                        {(!isLoadingState) && el?.response?.length !== 0 && <DemographicsTable data={el?.response} />}
                    </div>
                </>
        };
    });
    return (<>
        <Tab config={tabConfig} defaultValue='0' />
    </>);
};