import React from 'react';
import styles from './Error.module.scss';

interface Props {
    text?: string;
}

const Error:React.FC<Props> = ({text = 'Something went wrong. Please try again later.'}) => {
    return <h2 className={styles.error}>{text}</h2>;
};

export default Error;