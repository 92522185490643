import React from 'react';
import style from './style.module.scss';
import classNames from 'classnames';

interface Props {
    children?: any;
    headerContent?: any;
    title?: string;
    icon?: any;
    isBodyScrolled?: boolean;
    className?: any;
}

export const CardWithHeader: React.FC<Props> = ({ children, title, icon, headerContent, isBodyScrolled, className }) => {
    return (<div className={classNames(style.card, className ) } data-testid="card-with-header">
        <div className={style.cardHeader}>
            <div className={style.cardHeaderIcon}>{icon}</div>
            <h2 className={style.cardHeaderTitle}>{title}</h2>
            <div className={style.cardHeaderContent}>
                {headerContent}
            </div>
        </div>
        <div className={classNames(style.cardBody, {
            [style.cardBodyScrolled]: isBodyScrolled
        })} data-testid="card-with-header-body">
            {children}            
        </div>
    </div>);
};