import { nanoid } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { Error } from 'components/Error';
import { Placeholder } from 'components/Placeholder';
import { Chart } from 'elements/Chart';
import { Select } from 'elements/Select';
import { Skeleton } from 'elements/Skeleton';
import { useChartDataParams } from 'hooks/useChartDataParams';
import { useComparisonRequestData } from 'hooks/useComparisonRequestData';
import { PossibleUrlQuery, useQueryParams } from 'hooks/useQueryParams';
import { Task } from 'models/Task';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { projectReportAPI } from 'services/Project';
import { tasksAPI } from 'services/Tasks';
import { selectDashboard } from 'store/reducers/dashboardSlice';
import styles from '../style.module.scss';
import { getSunburstChart } from '../utils/getSunburstChart';

interface Props {
    isScenario?: boolean;
}

export const Workdays: React.FC<Props> = ({ isScenario = true }) => {
    const { data: projectReportData = [] } = projectReportAPI.useGetProjectReportQuery();

    const { scenario_uuids, project_uuids } = useSelector(selectDashboard);

    const [selectedData, setSelectedData] = useState<string>(project_uuids === null ? '' : project_uuids);

    const projectsOptions: { value: string; label: string; }[] = projectReportData.filter((el) => scenario_uuids === el.scenario_uuid).map((element) => {
        return {
            value: element.uuid,
            label: element.name
        };
    });

    const listOfQueries = isScenario ? [PossibleUrlQuery.scenario_uuids] : [PossibleUrlQuery.project_uuids];
    const objWithParams = isScenario ? {} : { [PossibleUrlQuery.project_uuids]: selectedData };

    // queries for main request
    const { queries } = useQueryParams(listOfQueries, objWithParams);

    // main request
    const {
        data: tasksData = [],
        isFetching: tasksDataLoading,
        error: tasksError
    } = tasksAPI.useGetTasksQuery({ params: queries }, {
        skip: !queries
    });

    // request for comparison mode
    const {
        comparisonReport: comparisonTasksData,
        isFetching: comparisonTasksDataLoading,
        error: comparisonTasksError
    } = useComparisonRequestData('/tasks', isScenario, objWithParams);

    const { data: chartDataParams } = useChartDataParams(isScenario, selectedData);

    const isLoadingState = tasksDataLoading || comparisonTasksDataLoading;
    const isErrorState = tasksError || comparisonTasksError;

    if (isErrorState) {
        return <div className={styles.center}>
            <Error text='Something went wrong. Please try again later or click the button.' />
        </div>;
    }

    const chartData: {
        id: string,
        response: Task[],
        scenario: { value: number, label: string } | null,
        project: { value: number, label: string } | null,
    }[] = [
            {
                id: nanoid(),
                ...chartDataParams,
                response: tasksData,
            },
            ...comparisonTasksData
        ];

    const plotlyData = chartData.map((el) => {
        return {
            ...el,
            plotData: getSunburstChart(el.response) || []
        };
    }).flat(2);

    const chartJSX = plotlyData.map((el) => {
        return <Chart key={el.id}
            plotData={el.plotData}
            y='Kilometers'
            title={`${isScenario ? el.scenario?.label : el.project?.label}`}
            style={{
                width: '50%',
                minHeight: '700px'
            }}
        />;
    });

    return <>

        <div className={styles.chartBody}>
            <div>

                {
                    isLoadingState && <Skeleton.ChartSkeleton />
                }
                {(!isLoadingState) && <>
                    <h2 className={styles.chartSectionTitle}>Total Workdays per Role</h2>
                    {!tasksData.length && <Placeholder text='Nothing to show' center />}
                    {!!tasksData.length &&
                        <>
                            {!isScenario && <div className={classNames(styles.sliderBlock, styles.variableBlock)}>
                                <p className={styles.sliderBlockTitle}>Select project: </p>
                                <div className={classNames(styles.inputWrapper, styles.inputWrapperTransparent)}>
                                    <Select
                                        data={projectsOptions}
                                        value={selectedData}
                                        onChange={setSelectedData}
                                        width={{ width: '600px' }}
                                    />
                                </div>
                            </div>}
                            {/* <div className={styles.download}>
                                <CSVButton
                                    data={cvsData}
                                    filename='workdays.csv'
                                    arrOfProps={[
                                        'role',
                                        'task',
                                        'role_task',
                                        'work_days'
                                    ]}
                                />
                            </div> */}

                            <div className={classNames({
                                [styles.workdaysLayout]: plotlyData.length > 1,
                                [styles.workdaysLayoutCenter]: plotlyData.length <= 1
                            })}>
                                {chartJSX}
                            </div>
                        </>
                    }
                </>
                }
            </div>
        </div>
    </>;
};