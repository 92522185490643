import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { MainPage as Main } from 'pages/Main';
import { Map } from 'components/Map';
import { NotFound } from 'pages/NotFound';

import { CreateUser } from 'pages/CreateUser';
import { Areas } from 'pages/Areas';
import { Scenarios } from 'pages/Scenarios';
import { Projects } from 'pages/Projects';
import { ProjectsToolbar } from 'components/ProjectsToolbar';
import { DashboardToolbar } from 'components/DashboardToolbar';
import { HelicopterView } from 'pages/HelicopterView';
import { ProjectsTable } from 'pages/ProjectsTable';
import { ProtectedRoute } from '../components/ProtectedRoute';
import { AtlasPage } from 'pages/Atlas';
import { UI } from 'pages/UI';
import { LoginPage } from 'pages/LoginPage';
import { GlobalLayout } from 'elements/GlobalLayout';
import { SignupPage } from 'pages/SignupPage';

export const RoutesComponent = () => {
    return (
        <>
            <Routes>
                <Route path={ROUTES.SIGN_IN} element={<LoginPage />} />
                <Route path={`${ROUTES.SIGN_UP}/:token?`} element={<SignupPage />} />

                <Route path={ROUTES.ROOT} element={<GlobalLayout />}>
                    <Route index element={<Main />} />
                    <Route path={ROUTES.ATLAS} element={<AtlasPage />} />
                    <Route
                        path={ROUTES.CREATE_USER}
                        element={
                            <ProtectedRoute>
                                <CreateUser />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTES.AREAS} element={<ProjectsToolbar />}>
                        <Route index element={<Areas />} />
                        <Route path={`${ROUTES.AREAS}/:param1`} element={<Scenarios />} />
                        <Route path={`${ROUTES.AREAS}/:param1/:param2`} element={<Projects />} />
                    </Route>
                    <Route path={ROUTES.DASHBOARD} element={<DashboardToolbar />}>
                        <Route index element={<HelicopterView />} />
                        <Route path={`${ROUTES.DASHBOARD}/:param1`} element={<ProjectsTable />} />
                    </Route>
                    <Route
                        path={`${ROUTES.AREAS}/:param1/:param2/:param3`}
                        element={<Map properties layersPanel isMapPage geocoder />}
                    />
                    <Route path={`${ROUTES.UI}`} element={<UI />} />
                    <Route path='*' element={<NotFound />} />
                </Route>
            </Routes>
        </>
    );
};
