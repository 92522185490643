/* eslint-disable quotes */
// import { useEffect, useRef, useState } from 'react';
import { Map } from 'components/Map';
// import { useMap } from 'app/providers/MapProvider';
// import { polygonAPI } from 'services/Polygon';
import styles from './AtlasLayout.module.scss';
import { Button } from 'components/Button';
import { useMap } from 'app/providers/MapProvider';
import { useEffect, useState } from 'react';
import { getGeoJSONFormat } from './utils/getGeoJSONFormat';
import { generateHeatmapArray } from './utils/heatmapColorsArray';

export const AtlasLayout = () => {
    const {
        map,
        areas
    } = useMap();

    const [dataForNodes, setDataForNodes] = useState<any>();

    const handleClick = async () => {
        const data = await fetch('https://atlas-polygon.infranomics.ai/api/v1/polygon', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                // {
                //     'type': 'Polygon',
                //     'coordinates': [
                //         [
                //             [-4.3811510537209415, 53.337182042453804],
                //             [-4.391144472070749, 53.33127667396957],
                //             [-4.381567446151138, 53.324904175193325],
                //             [-4.360175284993403, 53.32450003342041],
                //             [-4.36163265850351, 53.335472677762084],
                //             [-4.3811510537209415, 53.337182042453804]
                //         ]
                //     ]
                // }
                // {"coordinates":[[[-4.382231037995609,53.330555486561536],[-4.380822582894979,53.327993799147634],[-4.37742948651595,53.32722908652562],[-4.370515252383882,53.327840857719906],[-4.3684025697324955,53.33112897759557],[-4.3742924728818195,53.332390630731624],[-4.379798251912263,53.33212301045748],[-4.382231037995609,53.330555486561536]]],"type":"Polygon"}
                areas[0]
            )
        })
            .then(response => response.json())
            .then(response => response)
            // eslint-disable-next-line no-console
            .catch(err => console.error(err));

        setDataForNodes(data);
    };

    useEffect(() => {
        if (!map) return;
        if (!dataForNodes) return;

        if (map.getSource('vertices-source')
            || map.getLayer('vertices-layer')
            || map.getSource('edges-source')
            || map.getLayer('edges-layer')) {
            return;
        }

        map.addSource('vertices-source', {
            type: 'geojson',
            // @ts-ignore
            data: getGeoJSONFormat(dataForNodes.vertices)
        });
        map.addSource('edges-source', {
            type: 'geojson',
            // @ts-ignore
            data: getGeoJSONFormat(dataForNodes.edges)
        });

        map.addLayer({
            'id': 'edges-layer',
            'type': 'line',
            'source': 'edges-source',
            'layout': {
                'line-join': 'round',
                'line-cap': 'round'
            },
            'paint': {
                'line-color': '#000000',
                'line-width': 2
            }
        });
        map.addLayer({
            'id': 'vertices-layer',
            'type': 'circle',
            'source': 'vertices-source',
            'paint': {
                'circle-radius': 4,
                'circle-stroke-width': 1,
                "circle-color": {
                    "property": "naive_cppp",
                    "stops": generateHeatmapArray()
                },
                'circle-stroke-color': 'white'
            }
        });
        // naive_cppp

        return () => {

            if (map.getSource('vertices-source')) {
                map.removeLayer('vertices-layer');
                map.removeSource('vertices-source');
            }
            if (map.getSource('edges-source')) {
                map.removeLayer('edges-layer');
                map.removeSource('edges-source');
            }
        };

    }, [map, dataForNodes]);

    return (<div style={{ height: '100vh' }} className='atlas'>

        <div className={styles.topPanel}>
            <Button onClick={handleClick}>Save</Button>
        </div>

        <Map polygon isAtlasPage />

    </div>);
};
