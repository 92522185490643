import { nanoid } from '@reduxjs/toolkit';
import { Tag } from 'antd';
import { Error } from 'components/Error';
import { Table } from 'elements/Table';
import { useChartDataParams } from 'hooks/useChartDataParams';
import { useComparisonRequestData } from 'hooks/useComparisonRequestData';
import { PossibleUrlQuery, useQueryParams } from 'hooks/useQueryParams';
import { Properties } from 'models/Properties';
import React from 'react';
import { projectReportAPI } from 'services/Project';
import { propertiesAPI } from 'services/Properties';
import { numberFormatter, priceFormatter } from 'utils/priceFormatter';
import styles from '../BillOfMaterials.module.scss';
interface Props {
    isScenario?: boolean;
    selectedProject: string
}

export const UPRNData: React.FC<Props> = ({ isScenario = true, selectedProject }) => {
    const { data: projectReportData = [] } = projectReportAPI.useGetProjectReportQuery();

    const listOfQueries = isScenario
        ? [PossibleUrlQuery.scenario_uuids]
        : [PossibleUrlQuery.project_uuids];
    const objWithParams = isScenario ? {} : {
        [PossibleUrlQuery.project_uuids]: selectedProject,
    };

    // queries for main request
    const { queries } = useQueryParams(listOfQueries, objWithParams);

    // main request
    const {
        data: propertiesData = [],
        error: propertiesDataError
    } = propertiesAPI.useGetPropertiesQuery({ params: queries }, {
        skip: !queries
    });

    // request for comparison mode
    const {
        comparisonReport: comparisonPropertiesData,
        error: comparisonPropertiesError
    } = useComparisonRequestData('/properties', isScenario, objWithParams);

    const { data: chartDataParams } = useChartDataParams(isScenario, selectedProject);

    const isErrorState = propertiesDataError || comparisonPropertiesError;

    if (isErrorState) {
        return <div className={styles.center}>
            <Error text='Something went wrong. Please try again later or click the button.' />
        </div>;
    }

    // main data object structure 
    // need this for possibility of compare data
    const chartData: {
        id: string,
        response: Properties[],
        scenario: { value: string, label: string } | null,
        project: { value: string, label: string } | null,
    }[] = [
            {
                id: nanoid(),
                ...chartDataParams,
                response: propertiesData,
            },
            ...comparisonPropertiesData
        ];

    // const [cvs, setCVSData] = useState<any[]>();

    const tableConfig = [
        {
            label: 'project',
            render: (data: { [key: string]: string }) => projectReportData.find((el) => el.uuid === data.project_uuid)?.name,
            sortValue: (data: { [key: string]: string }) => projectReportData.find((el) => el.uuid === data.project_uuid)?.name,
        },
        {
            label: 'property_id',
            render: (data: { [key: string]: string }) => data.property_id,
            sortValue: (data: { [key: string]: string }) => data.property_id,
        },
        {
            label: 'rfs_day',
            render: (data: { [key: string]: string }) => data.rfs_day,
            sortValue: (data: { [key: string]: string }) => data.rfs_day,
        },
        {
            label: 'has_voucher',
            render: (data: { [key: string]: string }) => data.has_voucher ? <Tag color='green'>{data.has_voucher}</Tag> : <Tag color='magenta'>{data.has_voucher}</Tag>,
            sortValue: (data: { [key: string]: string }) => +data.has_voucher,
        },
        {
            label: 'voucher_value',
            render: (data: { [key: string]: string }) => data.voucher_value && '-',
            sortValue: (data: { [key: string]: string }) => data.voucher_value,
        },
        {
            label: 'cppp',
            render: (data: { [key: string]: string }) => `£${priceFormatter(+data.cppp)}`,
            sortValue: (data: { [key: string]: string }) => data.cppp,
        },
        {
            label: 'oppp',
            render: (data: { [key: string]: string }) => `£${priceFormatter(+data.oppp)}`,
            sortValue: (data: { [key: string]: string }) => data.oppp,
        },
        {
            label: 'lat',
            render: (data: { [key: string]: string }) => data.lat,
            sortValue: (data: { [key: string]: string }) => data.lat,
        },
        {
            label: 'lng',
            render: (data: { [key: string]: string }) => data.lng,
            sortValue: (data: { [key: string]: string }) => data.lng,
        },
        {
            label: 'existing_broadband_speed_high',
            render: (data: { [key: string]: string }) => +data.existing_broadband_speed_high,
            sortValue: (data: { [key: string]: string }) => +data.existing_broadband_speed_high,
        },
        {
            label: 'existing_broadband_speed_low',
            render: (data: { [key: string]: string }) => +data.existing_broadband_speed_low,
            sortValue: (data: { [key: string]: string }) => +data.existing_broadband_speed_low,
        },
        {
            label: 'existing_broadband_product_name',
            render: (data: { [key: string]: string }) => data.existing_broadband_product_name,
            sortValue: (data: { [key: string]: string }) => data.existing_broadband_product_name,
        },
        {
            label: 'address data',
            render: (data: { [key: string]: any }) => JSON.stringify(data.address_blob, null, 4),
        },
    ];

    // useEffect(() => {
    //     if (uprnData.length !== 0) {
    //         const scenarioReportCVS = uprnData?.map((el: { project: any; project_id: any; uprn: any; cppp: any; oppp: any; product_name: any; d_high: any; d_low: any; lat: any; lng: any; postcode: any; single_line_address: any; sub_building: any; building_name: any; street_name: any; locality: any; town_name: any; post_town: any; }) => {
    //             return [el.project, el.project_id, el.uprn, el.cppp, el.oppp, el.uprn, el.product_name, el.d_high, el.d_low, el.lat, el.lng, el.postcode, el.single_line_address, el.sub_building, el.building_name, el.street_name, el.locality, el.town_name, el.post_town, el.uprn];
    //         }) || [];

    //         const csvData = [
    //             ['project', 'project_id', 'uprn', 'cppp', 'oppp', 'uprn', 'product_name', 'd_high', 'd_low', 'lat', 'lng', 'postcode', 'single_line_address', 'sub_building', 'building_name', 'street_name', 'locality', 'town_name', 'post_town', 'uprn'],
    //             ...scenarioReportCVS
    //         ];

    //         setCVSData(csvData);
    //     }
    // }, [uprnData.length]);

    return (<>
        {/* {isLoadingState && <Spinner className={styles.customSpinner} />}
         */}
        <div>
            <p className={styles.title}>Total Scenario UPRN count</p>
            <p className={styles.mainNumber}>{numberFormatter(propertiesData.length)}</p>
            <p className={styles.subtitle}> Across all projects in this scenario</p>
            <Table
                data={chartData.map((el) => el.response).flat(1)}
                config={tableConfig}

            />
        </div>
    </>);
};