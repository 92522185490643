import { nanoid } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { Error } from 'components/Error';
import { Chart } from 'elements/Chart';
import { Select } from 'elements/Select';
import { Skeleton } from 'elements/Skeleton';
import { useChartDataParams } from 'hooks/useChartDataParams';
import { useComparisonRequestData } from 'hooks/useComparisonRequestData';
import { PossibleUrlQuery, useQueryParams } from 'hooks/useQueryParams';
import { Cashflow as CashflowType } from 'models/Cashflow';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { cashflowAPI } from 'services/Cashflows';
import { projectReportAPI } from 'services/Project';
import { selectDashboard } from 'store/reducers/dashboardSlice';
import styles from '../style.module.scss';

interface Props {
    isScenario?: boolean;
}

export const Cashflow: React.FC<Props> = ({ isScenario = true }) => {
    const { data: projectReportData = [] } = projectReportAPI.useGetProjectReportQuery();

    const { scenario_uuids, project_uuids } = useSelector(selectDashboard);

    const [selectedData, setSelectedData] = useState<string>(project_uuids === null ? '' : project_uuids);

    const projectsOptions: { value: string; label: string; }[] = projectReportData.filter((el) => scenario_uuids === el.scenario_uuid).map((element) => {
        return {
            value: element.uuid,
            label: element.name
        };
    });

    const listOfQueries = isScenario ? [PossibleUrlQuery.scenario_uuids] : [PossibleUrlQuery.project_uuids];
    const objWithParams = isScenario ? {} : { [PossibleUrlQuery.project_uuids]: selectedData };

    // queries for main request
    const { queries } = useQueryParams(listOfQueries, objWithParams);

    // main request
    const {
        data: cashflowData = [],
        isFetching: cashflowDataLoading,
        error: cashflowError
    } = cashflowAPI.useGetCashflowQuery({ params: queries }, {
        skip: !queries
    });

    // request for comparison mode
    const {
        comparisonReport: comparisonCashflowData,
        isFetching: comparisonCashflowDataLoading,
        error: comparisonCashflowError
    } = useComparisonRequestData('/cashflows', isScenario, objWithParams);

    const { data: chartDataParams } = useChartDataParams(isScenario, selectedData);

    const isLoadingState = cashflowDataLoading || comparisonCashflowDataLoading;
    const isErrorState = cashflowError || comparisonCashflowError;

    if (isErrorState) {
        return <div className={styles.center}>
            <Error text='Something went wrong. Please try again later or click the button.' />
        </div>;
    }

    // main data object structure 
    // need this for possibility of compare data
    const chartData: {
        id: string,
        response: CashflowType[],
        scenario: { value: string, label: string } | null,
        project: { value: string, label: string } | null,
    }[] = [
            {
                id: nanoid(),
                ...chartDataParams,
                response: cashflowData,
            },
            ...comparisonCashflowData
        ];

    const data = chartData.map((el) => {
        return {
            ...el,
            revenue_total: el.response.map((el) => el.revenue_total),
            bank_balance: el.response.map((el) => el.bank_balance),
            capex_total: el.response.map((el) => el.capex_total),
            voucher: el.response.map((el) => el.this_day_voucher_revenue),
            live_uprns: el.response.map((el) => el.live_properties),
            uprn_total: el.response.map((el) => el.properties_total),

        };
    });

    const plotConfigrevenue_total = data.map((el) => {
        return {
            y: el.revenue_total,
            type: 'scatter',
            name: `${isScenario ? el.scenario?.label : el.project?.label} - revenue_total`,
            mode: 'lines+markers',
            hovertemplate: 'revenue_total: %{y}<extra></extra>',
            line: {
                width: 1
            }
        };
    });
    const plotConfigbank_balance = data.map((el) => {
        return {
            y: el.bank_balance,
            type: 'scatter',
            name: `${isScenario ? el.scenario?.label : el.project?.label} - bank_balance`,
            mode: 'lines+markers',
            hovertemplate: 'bank_balance: %{y}<extra></extra>',
            line: {
                width: 1
            }
        };
    });
    const plotConfigcapex_total = data.map((el) => {
        return {
            y: el.capex_total,
            type: 'scatter',
            name: `${isScenario ? el.scenario?.label : el.project?.label} - uprn_total`,
            mode: 'lines+markers',
            hovertemplate: 'capex_total: %{y}<extra></extra>',
            line: {
                width: 1
            }
        };
    });

    const plotConfig = [...plotConfigrevenue_total, ...plotConfigbank_balance, ...plotConfigcapex_total];

    const plotConfigVoucher = data.map((el) => {
        return {
            y: el.voucher,
            type: 'scatter',
            name: `${isScenario ? el.scenario?.label : el.project?.label} - voucher`,
            mode: 'lines+markers',
            hovertemplate: 'voucher: %{y}<extra></extra>',
            line: {
                width: 1
            }
        };
    });

    const plotConfiglive_uprns = data.map((el) => {
        return {
            y: el.live_uprns,
            type: 'scatter',
            name: `${isScenario ? el.scenario?.label : el.project?.label} - live_uprns`,
            mode: 'lines+markers',
            hovertemplate: 'live_uprns: %{y}<extra></extra>',
            line: {
                width: 1
            }
        };
    });

    const plotConfiguprn_total = data.map((el) => {
        return {
            y: el.uprn_total,
            type: 'scatter',
            name: `${isScenario ? el.scenario?.label : el.project?.label} - uprn_total`,
            mode: 'lines+markers',
            hovertemplate: 'uprn_total: %{y}<extra></extra>',
            line: {
                width: 1
            }
        };
    });

    const plotConfigUPRN = [...plotConfiglive_uprns, ...plotConfiguprn_total];

    return <>
        {!isScenario && <div className={classNames(styles.sliderBlock, styles.variableBlock)}>
            <p className={styles.sliderBlockTitle}>Select project: </p>
            <div className={classNames(styles.inputWrapper, styles.inputWrapperTransparent)}>
                <Select
                    data={projectsOptions}
                    value={selectedData}
                    onChange={setSelectedData}
                    width={{ width: '600px' }}
                />
            </div>
        </div>}
        <div className={styles.chartBody}>
            <div style={{ minHeight: '500px' }}>
                {
                    isLoadingState && <Skeleton.ChartSkeleton />
                }
                {!isLoadingState && <>
                    {/* <div className={styles.download}>
                        <CSVButton
                            data={chartData}
                            arrOfProps={['day', 'revenue_total', 'bank_balance', 'capex_total']}
                            filename="cashflow.csv" />
                    </div> */}
                    <Chart plotData={plotConfig}
                        x="Day"
                        y="GBP"
                        title="Cashflow"
                        layout={{
                            hovermode: 'x unified',
                            hoverlabel: {
                                namelength: 600,
                                bgcolor: '#000',
                                bordercolor: '#000',
                                font: {
                                    color: '#fff',
                                    family: 'var(--default-font, var(--sans-serif))',
                                    size: 13
                                }
                            },
                        }} />
                </>
                }
            </div>
        </div>
        <div className={styles.chartBody}>
            <div style={{ minHeight: '500px' }}>
                {
                    isLoadingState && <Skeleton.ChartSkeleton />
                }
                {!isLoadingState && <>
                    {/* <div className={styles.download}>
                        <CSVButton data={chartData}
                            filename="daily-voucher-revenue.csv"
                            arrOfProps={['day', 'this_day_voucher_revenue']}
                        /> */}
                    {/* </div> */}
                    <Chart plotData={plotConfigVoucher}
                        x="Day"
                        y="GBP"
                        title="Daily Voucher Revenue"
                        layout={{
                            hovermode: 'x unified',
                            hoverlabel: {
                                namelength: 600,
                                bgcolor: '#000',
                                bordercolor: '#000',
                                font: {
                                    color: '#fff',
                                    family: 'var(--default-font, var(--sans-serif))',
                                    size: 13
                                }
                            },
                        }} />
                </>
                }
            </div>
        </div>
        <div className={styles.chartBody}>
            <div style={{ minHeight: '500px' }}>
                {
                    isLoadingState && <Skeleton.ChartSkeleton />
                }
                {!isLoadingState && <>
                    {/* <div className={styles.download}>
                        <CSVButton
                            data={chartData}
                            filename="RFS-live-customers.csv"
                            arrOfProps={['day', 'live_uprns', 'uprn_total']}
                        />
                    </div> */}
                    <Chart plotData={plotConfigUPRN}
                        x="Day"
                        y="GBP"
                        title="Total RFS vs Live Customers"
                        layout={{
                            hovermode: 'x unified',
                            hoverlabel: {
                                namelength: 600,
                                bgcolor: '#000',
                                bordercolor: '#000',
                                font: {
                                    color: '#fff',
                                    family: 'var(--default-font, var(--sans-serif))',
                                    size: 13
                                }
                            },
                        }} />
                </>
                }
            </div>
        </div>
    </>;
};