import mapboxgl from 'mapbox-gl';
import { dimLine } from './dimLine';

export const highlightLine = (map: mapboxgl.Map, lineFeatures: any[], parentChild?: any[]) => {
    dimLine(map);
    map.addSource('hoveredLine', {
        'type': 'geojson',
        'data': {
            'type': 'FeatureCollection',
            'features': lineFeatures
        }
    });

    map.addLayer({
        'id': 'higlightedLineLayer',
        'type': 'line',
        'source': 'hoveredLine',
        'layout': {},
        'paint': {
            'line-width': 3,
            'line-color': 'yellow'
        }
    });

    if (parentChild?.length) {

        map.addSource('hoveredLineParent', {
            'type': 'geojson',
            'data': {
                'type': 'FeatureCollection',
                'features': parentChild
            }
        });

        map.addLayer({
            'id': 'higlightedLineLayerParent',
            'type': 'line',
            'source': 'hoveredLineParent',
            'layout': {},
            'paint': {
                'line-width': 5,
                'line-color': 'green'
            }
        });

    }
};
