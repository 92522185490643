export const selectToken = {
    colorBgElevated:'var(--bg-element)',
    colorBgContainer: 'var(--bg-element)',
    colorBorder: 'var(--border)',
    colorText: 'var(--text-regular)',
    colorTextPlaceholder: 'var(--text-regular)',
    fontSize: 14,

    controlOutline: 'var(--main-dark-blue)',
    controlOutlineWidth: 4,
    controlPaddingHorizontal: 14,
    controlHeight: 44,
};