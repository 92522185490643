import { Modal } from 'components/Modal';
import styles from './ComparisonChartModal.module.scss';
import React, { useEffect, useState } from 'react';
import { scenarioReportAPI } from 'services/Scenarios/ScenarioReportService';
import { projectReportAPI } from 'services/Project/ProjectReportService';
import { useDispatch, useSelector } from 'react-redux';
import { addComparisonAreaId, addComparisonData, addComparisonProjectsId, addComparisonScenarioId, editComparisonData } from 'store/reducers/comparisonSlice';
import { Button } from 'components/Button';
import { Select } from 'elements/Select';

interface Props {
    onClose: () => void;
    isEditMode: boolean;
    editId?: string;
}

export const ComparisonChartModal: React.FC<Props> = ({ onClose, isEditMode, editId }) => {
    const dispatch = useDispatch();
    const { comparisonAreaId, comparisonScenarioId, comparisonProjectsId } = useSelector((store: any) => {
        return store.comparison;
    });

    const editObj = useSelector((store: any) => {
        return store.comparison.comparisonData.find((el: any) => el.id === editId);
    });

    const [modalError, setModalError] = useState<boolean>(false);
    const { data: scenarioReportData = [] } = scenarioReportAPI.useGetScenarioReportQuery();
    const { data: projectReportData = [] } = projectReportAPI.useGetProjectReportQuery();

    useEffect(() => {
        if (editId) {
            dispatch(addComparisonAreaId(editObj?.area.value));
            dispatch(addComparisonScenarioId(editObj?.scenario.value));
            dispatch(addComparisonProjectsId(editObj?.projects.value));
        }
    }, [editId]);

    const handleClose = () => {
        onClose();
        dispatch(addComparisonAreaId(null));
    };

    const areasArray: { uuid: string, name: string }[] = [];

    for (const scenario of scenarioReportData) {
        const find = areasArray.find((el) => {
            return el.uuid === scenario.area_uuid;
        });

        if (!find) {
            areasArray.push({
                uuid: scenario.area_uuid,
                name: scenario.name
            });
        }
    }

    const areaOptions: { value: string; label: string; }[] = areasArray.map((element) => {
        return {
            value: element.uuid,
            label: element.name
        };
    });

    const scenarioOptions: { value: string; label: string; }[] = scenarioReportData.filter((el) => el.area_uuid === comparisonAreaId).map((element) => {
        return {
            value: element.uuid,
            label: element.name
        };
    });

    const projectsOptions: { value: string; label: string; }[] = projectReportData.filter((el) => el.scenario_uuid === comparisonScenarioId).map((element) => {
        return {
            value: element.uuid,
            label: element.name
        };
    });

    const handleSubmit = () => {
        if (comparisonScenarioId === null || comparisonAreaId === null || comparisonProjectsId === null) {
            setModalError(true);
            return;

        } else {
            setModalError(false);
        }
        dispatch(addComparisonData({
            area: areaOptions.find((el) => el.value === comparisonAreaId),
            scenario: scenarioOptions.find((el) => el.value === comparisonScenarioId),
            projects: projectsOptions.find((el) => comparisonProjectsId === el.value)
        }));

        handleClose();
    };

    const handleEdit = () => {
        if (comparisonScenarioId === null || comparisonAreaId === null || comparisonProjectsId === null) {
            setModalError(true);
            return;
        } else {
            setModalError(false);
        }

        dispatch(editComparisonData({
            id: editId,
            area: areaOptions.find((el) => el.value === comparisonAreaId),
            scenario: scenarioOptions.find((el) => el.value === comparisonScenarioId),
            projects: projectsOptions.find((el) => comparisonProjectsId === el.value)
        }));

        handleClose();
    };

    return <Modal className={styles.comparisonModal} onClose={handleClose} >
        <div className={styles.close} onClick={handleClose}></div>
        <h1 className={styles.title}>{isEditMode ? 'Edit comparison' : 'Add comparison'}</h1>
        <h2 className={styles.subtitle}>Compare XYZ...</h2>
        <div className={styles.select}>
            <Select gapBottom isBlock data={areaOptions} width={{ width: '100%' }}
                label='Area:'
                value={comparisonAreaId}
                onChange={(value) => {
                    dispatch(addComparisonAreaId(value));
                }}
            />
            <Select gapBottom isBlock data={scenarioOptions} width={{ width: '100%' }}
                label='Scenario:' value={comparisonScenarioId} onChange={(value) => {
                    dispatch(addComparisonScenarioId(value));
                }} />

            <Select gapBottom isBlock data={projectsOptions} width={{ width: '100%' }}
                label='Project name:' value={comparisonProjectsId} onChange={(value) => {
                    dispatch(addComparisonProjectsId(value));
                }} />

            {
                modalError && <p className={styles.modalError}>Fill out all fields</p>
            }
        </div>
        <div className={styles.modalBottom}>
            <Button isWide onClick={!isEditMode ? handleSubmit : handleEdit}
                style={{
                    display: 'block',
                    width: '100%',
                    marginBottom: '12px',
                    height: '46px',
                    fontSize: '16px'
                }}>
                {isEditMode ? 'Edit' : 'Add'}

            </Button>

            <button className={styles.buttonCancel} onClick={handleClose}>Cancel</button>
        </div>
    </Modal>;
};