import { BreadCrumbs } from './BreadCrumbs';
import styles from './DashboardToolbar.module.scss';
import { Outlet } from 'react-router-dom';
import { Select } from './Select';
import { HeaderPage } from 'elements/HeaderPage';
import { BodyPage } from 'elements/BodyPage';

interface Props {
}

const DashboardToolbar: React.FC<Props> = () => {
  return (<>
    <HeaderPage>
      <div className={styles.content}>
        <BreadCrumbs />
        <Select />
      </div>
    </HeaderPage>
    <BodyPage>
      <Outlet />
    </BodyPage>
  </>);
};

export default DashboardToolbar;