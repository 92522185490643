import classNames from 'classnames';
import { Select } from 'elements/Select';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { projectReportAPI } from 'services/Project';
import { selectDashboard } from 'store/reducers/dashboardSlice';
import styles from '../style.module.scss';
import { CapexLine } from './CapexLine';
import { CapexPoint } from './CapexPoint';
import { OpexLine } from './OpexLine';
import { OpexPoint } from './OpexPoint';
import { UPRNData } from './UPRNDataConfig/UPRNData';

interface Props {
    isScenario?: boolean;
}

export const BillOfMaterials: React.FC<Props> = ({ isScenario = true }) => {
    const { data: projectReportData = [] } = projectReportAPI.useGetProjectReportQuery();

    const { scenario_uuids, project_uuids } = useSelector(selectDashboard);

    const [selectedData, setSelectedData] = useState<string>(project_uuids === null ? '' : project_uuids);

    const projectsOptions: { value: string; label: string; }[] = projectReportData.filter((el) => scenario_uuids === el.scenario_uuid).map((element) => {
        return {
            value: element.uuid,
            label: element.name
        };
    });

    return (<>
        {!isScenario && <div className={classNames(styles.sliderBlock, styles.variableBlock)}>
            <p className={styles.sliderBlockTitle}>Select project: </p>
            <div className={classNames(styles.inputWrapper, styles.inputWrapperTransparent)}>
                <Select
                    data={projectsOptions}
                    value={selectedData}
                    onChange={setSelectedData}
                    width={{ width: '600px' }}
                />
            </div>
        </div>}
        <OpexLine isScenario={isScenario}
            selectedProject={selectedData}
        />
        <OpexPoint isScenario={isScenario}
            selectedProject={selectedData}
        />
        <CapexPoint isScenario={isScenario}
            selectedProject={selectedData}
        />

        <CapexLine isScenario={isScenario}
            selectedProject={selectedData}
        />

        <UPRNData isScenario={isScenario}
            selectedProject={selectedData} />
    </>);
};