import styles from './Select.module.scss';
import classNames from 'classnames';
import { ConfigProvider, Select as SelectAndt, Tooltip } from 'antd';
import { selectToken } from './utils/designToken';
import { scenarioReportAPI } from 'services/Scenarios/ScenarioReportService';
import { useEffect, useState } from 'react';
import { useDispatch , useSelector } from 'react-redux';
import { addAreaId, selectDashboard } from 'store/reducers/dashboardSlice';

const { Option } = SelectAndt;
interface Props {
    val?: any;
}

const SelectAreas: React.FC<Props> = () => {
    const dispatch = useDispatch();
    const { area_uuids:areaId } = useSelector(selectDashboard);

    const [selectedArea, setSelectedArea] = useState(areaId);
    const { data: scenarioReportData = [] } = scenarioReportAPI.useGetScenarioReportQuery();
    const areaIds: { uuid: string, name: string }[] = [];

    useEffect(() => {
        if (areaId === null) {
            setSelectedArea([]);
        }
    }, [areaId]);

    for (const scenario of scenarioReportData) {
        const find = areaIds.find((el) => {
            return el.uuid === scenario.area_uuid;
        });

        if (!find) {
            areaIds.push({
                uuid: scenario.area_uuid,
                name: scenario.name
            });
        }
    }

    const options: { value: string; label: string; }[] = areaIds.map((element) => {
        return {
            value: element.uuid,
            label: element.name
        };
    });

    const handleClickAreas = (value: any) => {
        dispatch(addAreaId(value));
        setSelectedArea(value);
    };

    const optionJSX = options.map((item) => (
        <Option key={item.value} value={item.value}>
            <Tooltip title={item.label} placement="right">
                <div style={{ width: '100%' }}>
                    <div
                        style={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {item.label}
                    </div>
                </div>
            </Tooltip>
        </Option>
    ));

    return <div data-testid="SelectAreas" className={classNames(styles.sort)}><ConfigProvider
        theme={{
            token: selectToken,
            components: {
                Select: { // @ts-ignore
                    clearBg: '#737373',
                }
            }
        }}
    >
        <div className={styles.select}>
            <label className={styles.selectLabel}>Area name: </label>

            <SelectAndt
                showSearch
                className={styles.select}
                style={{ width: '300px' }}
                placeholder="All"
                onChange={handleClickAreas}
                value={selectedArea || []}
                allowClear
                onClear={() => {
                    dispatch(addAreaId(null));
                }}
            >
               {optionJSX}
            </SelectAndt>
        </div>

    </ConfigProvider>
    </div>;
};

export default SelectAreas;