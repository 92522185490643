import { Tag } from 'elements/Tag/Tag';
import style from './style.module.scss';
import { Types } from 'elements/Tag/utils/TagTypes';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import areaImg from './img/area.jpg';
import scenarioImg from './img/scenario.jpg';
import projectImg from './img/project.jpg';

interface Props {
    cardName: string;
    link: string;
    type?: Types
}

export const RecentCard: React.FC<Props> = ({
    cardName,
    link,
    type = Types.area
}) => {
    const navigate = useNavigate();

    const imgObj = {
        [Types.area]: areaImg,
        [Types.scenario]: scenarioImg,
        [Types.project]: projectImg,
    };

    return (<>
        <div className={style.card}>
            <div className={style.cardDescription}>
                <Tag text={type} type={type} />
                <h2 className={style.title}>{cardName}</h2>
            </div>
            <div className={style.cardImg}>
                <img className={style.img} src={imgObj[type]} alt="" />

                <div onClick={() => navigate(link)} className={style.link} data-testid="link">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                        <path d="M10 0.555538V7.77754C10 7.92487 9.94147 8.06618 9.83729 8.17036C9.7331 8.27455 9.5918 8.33308 9.44446 8.33308C9.29712 8.33308 9.15582 8.27455 9.05164 8.17036C8.94745 8.06618 8.88892 7.92487 8.88892 7.77754V1.89647L0.948891 9.8372C0.844649 9.94144 0.703267 10 0.555847 10C0.408427 10 0.267045 9.94144 0.162804 9.8372C0.0585623 9.73295 0 9.59157 0 9.44415C0 9.29673 0.0585623 9.15535 0.162804 9.05111L8.10353 1.11108H2.22246C2.07512 1.11108 1.93382 1.05255 1.82964 0.948363C1.72545 0.84418 1.66692 0.702876 1.66692 0.555538C1.66692 0.4082 1.72545 0.266897 1.82964 0.162714C1.93382 0.0585299 2.07512 0 2.22246 0H9.44446C9.5918 0 9.7331 0.0585299 9.83729 0.162714C9.94147 0.266897 10 0.4082 10 0.555538Z" fill="#0E1665" />
                    </svg>
                </div>
            </div>
        </div>
    </>);
};