import classNames from 'classnames';
import React from 'react';
import { Types } from './utils/TagTypes';
import style from './style.module.scss';

interface Props {
    type?: Types;
    text: string;
}
export const Tag: React.FC<Props> = ({ type, text }) => {
    return <>
        <span className={classNames(style.tag, {
            [style.type1]: type === Types.area,
            [style.type2]: type === Types.project,
            [style.type3]: type === Types.scenario,
        })}>{text}</span>
    </>;
};