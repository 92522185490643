import * as yup from 'yup';

export enum LoginInputs {
    NAME = 'name',
    EMAIL = 'email',
    PASSWORD = 'password',
}

export const validationSchema = yup
    .object({
        // email: yup.string().email().required(),
        name: yup.string().required('This field is required'),
        email: yup.string().required('This field is required'), // rn not an email just we use username as login value
        password: yup
            .string()
            .required('This field is required')
            .min(8, 'Must be at least 8 characters')
            .matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/, 'Must be at least 8 characters, one lower case letter, one upper case letter, one number, one special character'),
        remember: yup.boolean()
    })
    .required();

export const defaultValues = {
    name: '',
    email: '',
    password: '',
};