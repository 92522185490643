import { nanoid } from '@reduxjs/toolkit';
import { getPieData } from 'components/Charts/utils/getPieData';
import { Error } from 'components/Error';
import { Tab } from 'components/Tab';
import { Skeleton } from 'elements/Skeleton';
import { useChartDataParams } from 'hooks/useChartDataParams';
import { useComparisonRequestData } from 'hooks/useComparisonRequestData';
import { PossibleUrlQuery, useQueryParams } from 'hooks/useQueryParams';
import { censusAPI } from 'services/Demographics';
import { numberFormatter } from 'utils/priceFormatter';
import styles from '../DemographicsTable.module.scss';
import { PieChart } from '../PieChart/PieChart';

export const DemographicsScenario = () => {
    // queries for main request
    const { queries } = useQueryParams([
        PossibleUrlQuery.scenario_uuids
    ]);

    // main request
    const {
        data: censusData = [],
        isFetching: censusProjectsLoading,
        error: censusError
    } = censusAPI.useGetDemographicDataQuery({ params: queries }, {
        skip: !queries
    });

    // request for comparison mode
    const {
        comparisonReport: comparisonCensusData,
        isFetching: comparisonCensusDataLoading,
        error: comparisonCensusError
    } = useComparisonRequestData('/demographics/census', true);

    const { data: chartDataParams } = useChartDataParams(true);

    const isLoadingState = censusProjectsLoading || comparisonCensusDataLoading;
    const isErrorState = censusError || comparisonCensusError;

    if (isErrorState) {
        return <div className={styles.center}>
            <Error text='Something went wrong. Please try again later or click the button.' />
        </div>;
    }

    // main data object structure 
    // need this for possibility of compare data
    const chartData: {
        id: string,
        response: any,
        scenario: { value: string, label: string } | null,
        project: { value: string, label: string } | null,
    }[] = [
            {
                id: nanoid(),
                ...chartDataParams,
                response: censusData,
            },
            ...comparisonCensusData
        ];

    const tabConfig = chartData.map((el, i) => {
        const { pieData: chartsData = {} }: any = el.response.length !== 0 ? getPieData(el.response) : {};

        return {
            label: el.scenario?.label || '',
            key: i + '',
            children:
                <>
                    <div>
                        <p className={styles.title}>Total UPRNs in Scenario</p>
                        <p className={styles.mainNumber}>{chartsData?.total_uprns ? numberFormatter(chartsData?.total_uprns) : 0}</p>
                        <p className={styles.subtitle}> Across all projects in this scenario</p>
                    </div>
                    <div className={styles.grid}>
                        {/* {(isLoadingState) && <Spinner className={styles.customSpinner} />} */}
                        {isLoadingState && <Skeleton.ChartSkeleton />}
                        {(!isLoadingState) && <>
                            {chartsData?.accommodation && <div className={styles.chartContainer}>
                                {/* <div className={styles.download}>
                                    <CSVButton
                                        data={[]}
                                        preparedData={[['scenario', ...chartsData?.accommodation?.labels], [el.scenario.label, ...chartsData?.accommodation?.values]]}
                                        filename='accommodation-type.csv'
                                        arrOfProps={chartsData?.accommodation?.labels}
                                    />
                                </div> */}
                                <PieChart title='Accommodation type' data={chartsData?.accommodation} />
                            </div>}

                            {chartsData?.bedrooms && <div className={styles.chartContainer}>
                                {/* <div className={styles.download}>
                                    <CSVButton
                                        data={[]}
                                        preparedData={[['scenario', ...chartsData?.bedrooms?.labels], [el.scenario.label, ...chartsData?.bedrooms?.values]]}
                                        filename='bedroom-count.csv'
                                        arrOfProps={chartsData?.bedrooms?.labels}
                                    />
                                </div> */}
                                <PieChart title='Bedroom Count' data={chartsData?.bedrooms} />
                            </div>}
                            {chartsData?.health && <div className={styles.chartContainer}>
                                {/* <div className={styles.download}>
                                    <CSVButton
                                        data={[]}
                                        preparedData={[['scenario', ...chartsData?.health?.labels], [el.scenario.label, ...chartsData?.health?.values]]}
                                        filename='health.csv'
                                        arrOfProps={chartsData?.health?.labels}
                                    />
                                </div> */}
                                <PieChart title='Health' data={chartsData?.health} />
                            </div>}
                            {chartsData?.qualifications && <div className={styles.chartContainer}>
                                {/* <div className={styles.download}>
                                    <CSVButton
                                        data={[]}
                                        preparedData={[['scenario', ...chartsData?.qualifications?.labels], [el.scenario.label, ...chartsData?.qualifications?.values]]}
                                        filename='qualifications.csv'
                                        arrOfProps={chartsData?.qualifications?.labels}
                                    />
                                </div> */}
                                <PieChart title='Qualifications' data={chartsData?.qualifications} />
                            </div>}
                            {chartsData?.economic_activity && <div className={styles.chartContainer}>
                                {/* <div className={styles.download}>
                                    <CSVButton
                                        data={[]}
                                        preparedData={[['scenario', ...chartsData?.economic_activity?.labels], [el.scenario.label, ...chartsData?.economic_activity?.values]]}
                                        filename='economic-activity.csv'
                                        arrOfProps={chartsData?.economic_activity?.labels}
                                    />
                                </div> */}
                                <PieChart title='Economic Activity' data={chartsData?.economic_activity} />
                            </div>}
                            {chartsData?.household_composition && <div className={styles.chartContainer}>
                                {/* <div className={styles.download}>
                                    <CSVButton
                                        data={[]}
                                        preparedData={[['scenario', ...chartsData?.household_composition?.labels], [el.scenario.label, ...chartsData?.household_composition?.values]]}
                                        filename='household-composition.csv'
                                        arrOfProps={chartsData?.household_composition?.labels}
                                    />
                                </div> */}
                                <PieChart title='Household Composition' data={chartsData?.household_composition} />
                            </div>}
                            {chartsData?.household_size && <div className={styles.chartContainer}>
                                {/* <div className={styles.download}>
                                    <CSVButton
                                        data={[]}
                                        preparedData={[['scenario', ...chartsData?.household_size?.labels], [el.scenario.label, ...chartsData?.household_size?.values]]}
                                        filename='household.csv'
                                        arrOfProps={chartsData?.household_size?.labels}
                                    />
                                </div> */}
                                <PieChart title='Household' data={chartsData?.household_size} />
                            </div>}
                            {chartsData?.occupation && <div className={styles.chartContainer}>
                                {/* <div className={styles.download}>
                                    <CSVButton
                                        data={[]}
                                        preparedData={[['scenario', ...chartsData?.occupation?.labels], [el.scenario.label, ...chartsData?.occupation?.values]]}
                                        filename='household.csv'
                                        arrOfProps={chartsData?.occupation?.labels}
                                    />
                                </div> */}
                                <PieChart title='Occupation' data={chartsData?.occupation} />
                            </div>}
                        </>}

                    </div>
                    {/* <div className={styles.tableIndent}>
                        {(censusProjectsDataLoading || isLoading) && <Spinner className={styles.customSpinner} />}
                        {(!censusProjectsDataLoading || !isLoading) && chartData_censusProjectsData[i]?.response?.length !== 0 && <DemographicsTable data={chartData_censusProjectsData[i]?.response} />}
                    </div> */}
                </>
        };
    });
    return (<>
        {/* {isLoadingState && <Spinner className={styles.customSpinner} />} */}

        {/* {(!isLoadingState) && */}
        <Tab config={tabConfig} defaultValue='0' />
        {/* } */}
    </>);
};