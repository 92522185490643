import classNames from 'classnames';
import styles from './style.module.scss';

export const Skeleton = () => {
    return <div className={styles.skeleton}></div>;
};

const TableEl = () => {
    return <div className={classNames(styles.skeleton, styles.skeletonS)}></div>;
};

Skeleton.TableCell = TableEl;

const ChartSkeleton = () => {
    return (<div className={classNames(styles.chartSkeleton)}>
        <div className={classNames(styles.skeleton, styles.chart)}></div>
    </div>);
};

Skeleton.ChartSkeleton = ChartSkeleton;
