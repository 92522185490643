import styles from './style.module.scss';

export const NextIcon = ({ className, onClick, disabled }: any) => {
    const color = disabled ? 'var(--palette-color-3)' : 'var(--palette-color-2)';

    return (<div className={styles.icon} onClick={onClick}>
        <svg className={className} width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.999975 1L5.73682 6L0.999974 11" stroke={color} strokeWidth="1.05263" strokeLinecap="round" />
        </svg>
    </div>);
};