import { nanoid } from '@reduxjs/toolkit';
import { Error } from 'components/Error';
import { Tab } from 'components/Tab';
import { Chart } from 'elements/Chart';
import { Skeleton } from 'elements/Skeleton';
import { useChartDataParams } from 'hooks/useChartDataParams';
import { useComparisonRequestData } from 'hooks/useComparisonRequestData';
import { PossibleUrlQuery, useQueryParams } from 'hooks/useQueryParams';
import { ProjectSummaryReport } from 'models/ProjectSummaryReport';
import React, { useState } from 'react';
import { projectReportAPI } from 'services/Project';
import { projectSummaryReportAPI } from 'services/ProjectSummary';
import { scenarioReportAPI } from 'services/Scenarios';
import { feasibilityTransformerAllScenarios } from 'utils/feasibilityTransformerAllScenarios/feasibilityTransformerAllScenarios';
import styles from '../style.module.scss';
import { Ranges } from './Ranges';

export enum RangesValues {
    minCPPP = 0,
    maxCPPP = 5000,
    minROI = 0,
    maxROI = 100
}

interface Props {
    variables: {
        averageVoucherValue: number;
        avgOpppPa: number;
        revenuePeriod: number;
        cpppAssetValue: number;
        couponRate: number;
        cppcAssetvalue: number;
    };
}

export const MultiScenarioProjectPlots: React.FC<Props> = ({ variables }) => {
    // Range setup
    const [minMaxArrCPPP, setMinMaxArrCPPP] = useState<number[]>([RangesValues.minCPPP, RangesValues.maxCPPP]);
    const [minMaxArrROI, setMinMaxArrROI] = useState<number[]>([RangesValues.minROI, RangesValues.maxROI]);

    const onChangeCPPP = (value: number[]) => {
        setMinMaxArrCPPP(value);
    };

    const onChangeROI = (value: number[]) => {
        setMinMaxArrROI(value);
    };

    // queries for main request
    const { queries } = useQueryParams([
        PossibleUrlQuery.scenario_uuids,
        PossibleUrlQuery.aggregate_projects
    ], {
        [PossibleUrlQuery.aggregate_projects]: false
    });

    // main request
    const {
        data: projectSummary = [],
        isFetching: projectSummaryLoading,
        error: projectSummaryError
    } = projectSummaryReportAPI.useGetProjectReportQuery({ params: queries }, {
        skip: !queries
    });

    // request for comparison mode
    const {
        comparisonReport: comparisonProjectSummaryData,
        isFetching: comparisonProjectSummaryDataLoading,
        error: comparisonProjectSummaryError
    } = useComparisonRequestData('/project-summary/on-demand', true,
        {
            [PossibleUrlQuery.aggregate_projects]: false
        });

    const { data: chartDataParams } = useChartDataParams(true);

    const isLoadingState = projectSummaryLoading || comparisonProjectSummaryDataLoading;
    const isErrorState = projectSummaryError || comparisonProjectSummaryError;

    if (isErrorState) {
        return <div className={styles.center}>
            <Error text='Something went wrong. Please try again later or click the button.' />
        </div>;
    }

    // // main data object structure 
    // // need this for possibility of compare data
    const chartData: {
        id: string,
        response: ProjectSummaryReport[],
        scenario: { value: string, label: string } | null,
        project: { value: string, label: string } | null,
    }[] = [
            {
                id: nanoid(),
                ...chartDataParams,
                response: projectSummary,
            },
            ...comparisonProjectSummaryData
        ];

    const { data: scenarioReportData = [] } = scenarioReportAPI.useGetScenarioReportQuery();
    const { data: projectReportData = [] } = projectReportAPI.useGetProjectReportQuery();

    const chartDataWProjectNames = chartData.map((el) => {
        return {
            ...el,
            response: el.response.map((item) => {
                return {
                    ...item,
                    project_name: projectReportData.find((el) => el.uuid === item.project_uuid)?.name
                };
            }),
        };
    });

    const transformedChartData = chartDataWProjectNames.map((el) => {
        return {
            ...el,
            response: feasibilityTransformerAllScenarios(el.response, scenarioReportData, variables).project_level
        };
    });

    const getFilteredData = (data: any, prop: string) => {
        if (data.avg_cppp >= minMaxArrCPPP[0]
            && data.roi_at_pen_rate_years >= minMaxArrROI[0]
            && data.avg_cppp <= minMaxArrCPPP[1]
            && data.roi_at_pen_rate_years <= minMaxArrROI[1]) {
            return data;
        }

        return {
            [prop]: 0
        };
    };

    const data = transformedChartData.map((el) => {
        return {
            ...el,
            avg_cppp: el.response.map((item: { avg_cppp: number; }) => getFilteredData(item, 'avg_cppp')).map((item: { avg_cppp: any; }) => item.avg_cppp),
            roi: el.response.map((item: any) => getFilteredData(item, 'roi_at_pen_rate_years')).map((item: { roi_at_pen_rate_years: any; }) => item.roi_at_pen_rate_years),
            uprn_count: el.response.map((item: any) => getFilteredData(item, 'property_count')).map((item: { property_count: any; }) => item.property_count),
            total_capex: el.response.map((item: any) => getFilteredData(item, 'total_capex1')).map((item: { total_capex1: any; }) => item.total_capex1),
            capex_spine_100_pct_leadin: el.response.map((item: any) => getFilteredData(item, 'capex_spine_100_pct_leadin')).map((item: { capex_spine_100_pct_leadin: any; }) => item.capex_spine_100_pct_leadin),
            revenue_d30_r80: el.response.map((item: any) => getFilteredData(item, 'revenue_d30_r80')).map((item: { revenue_d30_r80: any; }) => item.revenue_d30_r80),
            gross_profit_pa: el.response.map((item: any) => getFilteredData(item, 'gross_profit_pa')).map((item: { gross_profit_pa: any; }) => item.gross_profit_pa),
            gross_profit_over_year_period: el.response.map((item: any) => getFilteredData(item, 'gross_profit_over_year_period')).map((item: { gross_profit_over_year_period: any; }) => item.gross_profit_over_year_period),
            EV_per_prem_connected_and_passed: el.response.map((item: any) => getFilteredData(item, 'EV_per_prem_connected_and_passed')).map((item: { EV_per_prem_connected_and_passed: any; }) => item.EV_per_prem_connected_and_passed),
            ev_minus_coupon_plus_period_gp_net_EV: el.response.filter((data: any) => data.avg_cppp >= minMaxArrCPPP[0]
                && data.roi_at_pen_rate_years >= minMaxArrROI[0]
                && data.avg_cppp <= minMaxArrCPPP[1]
                && data.roi_at_pen_rate_years <= minMaxArrROI[1]).map((item: any) => item.project_name),
            project_name: el.response.map((item: { project_name: string; project: string }) => item.project_name),
        };
    });

    // CPPP Spreads
    const cppp_spreads = data.map((el) => {
        return {
            x: el.project_name,
            y: el.avg_cppp,
            type: 'bar',
            name: el?.scenario?.label,
            hovertemplate: '<b>%{x}</b><br>CPPP Spreads: %{y}<extra></extra>',
            transforms: [
                {
                    type: 'sort',
                    target: el.project_name,
                    order: 'ascending'
                }
            ],
        };
    });

    // ROI Years
    const roiConfig = data.map((el) => {
        return {
            x: el.project_name,
            y: el.roi,
            type: 'bar',
            name: el?.scenario?.label,
            hovertemplate: '<b>%{x}</b><br>ROI Years: %{y}<extra></extra>',
            transforms: [
                {
                    type: 'sort',
                    target: el.project_name,
                    order: 'ascending'
                }
            ],
        };
    });

    // UPRN Count
    const uprn_countConfig = data.map((el) => {
        return {
            x: el.project_name,
            y: el.uprn_count,
            type: 'bar',
            name: el?.scenario?.label,
            hovertemplate: '<b>%{x}</b><br>UPRN Count: %{y}<extra></extra>',
            transforms: [
                {
                    type: 'sort',
                    target: el.project_name,
                    order: 'ascending'
                }
            ],
        };
    });

    // Capex
    const capexConfig = data.map((el) => {
        return {
            x: el.project_name,
            y: el.total_capex,
            type: 'bar',
            name: el?.scenario?.label,
            hovertemplate: '<b>%{x}</b><br>Capex: %{y}<extra></extra>',
            transforms: [
                {
                    type: 'sort',
                    target: el.project_name,
                    order: 'ascending'
                }
            ],
        };
    });

    // Avg. CPPC
    const avgCPPC = data.map((el) => {
        return {
            x: el.project_name,
            y: el.capex_spine_100_pct_leadin,
            type: 'bar',
            name: el?.scenario?.label,
            hovertemplate: '<b>%{x}</b><br>Avg. CPPC: %{y}<extra></extra>',
            transforms: [
                {
                    type: 'sort',
                    target: el.project_name,
                    order: 'ascending'
                }
            ],
        };
    });

    // 1Y Revenue
    const revenue1YConfig = data.map((el) => {
        return {
            x: el.project_name,
            y: el.revenue_d30_r80,
            type: 'bar',
            name: el?.scenario?.label,
            hovertemplate: '<b>%{x}</b><br>1Y Revenue: %{y}<extra></extra>',
            transforms: [
                {
                    type: 'sort',
                    target: el.project_name,
                    order: 'ascending'
                }
            ],
        };
    });

    // Gross Profit p/a 3Y
    const grossProfit3YConfig = data.map((el) => {
        return {
            x: el.project_name,
            y: el.gross_profit_pa,
            type: 'bar',
            name: el?.scenario?.label,
            hovertemplate: '<b>%{x}</b><br>Gross Profit p/a 3Y: %{y}<extra></extra>',
            transforms: [
                {
                    type: 'sort',
                    target: el.project_name,
                    order: 'ascending'
                }
            ],
        };
    });

    // gross_profit_over_year_period DEPENTS on TOTAL CAPEX PARAMS
    const grossProfit6YConfig = data.map((el) => {
        return {
            x: el.project_name,
            y: el.gross_profit_over_year_period,
            type: 'bar',
            name: el?.scenario?.label,
            hovertemplate: '<b>%{x}</b><br>Gross Profit Over 6Y Period: %{y}<extra></extra>',
            transforms: [
                {
                    type: 'sort',
                    target: el.project_name,
                    order: 'ascending'
                }
            ],
        };
    });

    // EV by UPRN Connected & Passed
    const UPRNConnectedPassedConfig = data.map((el) => {
        return {
            x: el.project_name,
            y: el.EV_per_prem_connected_and_passed,
            type: 'bar',
            name: el?.scenario?.label,
            hovertemplate: '<b>%{x}</b><br>EV by UPRN Connected & Passed: %{y}<extra></extra>',
            transforms: [
                {
                    type: 'sort',
                    target: el.project_name,
                    order: 'ascending'
                }
            ],
        };
    });

    // EV minus Coupon plus 6Y Gross Profit
    const ev_minus_coupon_plus_period_gp_net_EVConfig = data.map((el) => {
        return {
            x: el.project_name,
            type: 'bar',
            orientation: 'v',
            name: el?.scenario?.label,
            hovertemplate: '<b>%{x}</b><br>EV minus Coupon plus 6Y Gross Profit: %{y}<extra></extra>',
            transforms: [
                {
                    type: 'sort',
                    target: el.project_name,
                    order: 'ascending'
                }
            ],
        };
    });

    // Build Stats Deltas
    const tabsConfig = [
        {
            label: 'CPPP Spreads',
            key: 'CPPP Spreads',
            children: <div style={{ position: 'relative' }}>
                {/* <div className={styles.download}>
                    <CSVButton
                        data={cvsData}
                        filename='cppp_spreads.csv'
                        arrOfProps={['project', 'project_name', 'avg_cppp']}
                    />
                </div> */}
                <Chart plotData={cppp_spreads}
                    legend={{
                        x: 0,
                        y: 1.2,
                    }}
                />
            </div>,
        },
        {
            label: 'ROI Years',
            key: 'ROI Years',
            children: <div style={{ position: 'relative' }}>
                {/* <div className={styles.download}>
                    <CSVButton
                        data={cvsData}
                        filename='roi.csv'
                        arrOfProps={['project', 'project_name', 'roi_at_pen_rate_years']}
                    />
                </div> */}
                <Chart plotData={roiConfig}
                    legend={{
                        x: 0,
                        y: 1.2,
                    }}
                />
            </div>,
        },
        {
            label: 'UPRN Count',
            key: 'UPRN Count',
            children: <div style={{ position: 'relative' }}>
                {/* <div className={styles.download}>
                    <CSVButton
                        data={cvsData}
                        filename='uprn_count.csv'
                        arrOfProps={['project', 'project_name', 'uprn_count']}
                    />
                </div> */}
                <Chart plotData={uprn_countConfig}
                    legend={{
                        x: 0,
                        y: 1.2,
                    }}
                />
            </div>,
        },
        {
            label: 'Capex',
            key: 'Capex',
            children: <div style={{ position: 'relative' }}>
                {/* <div className={styles.download}>
                    <CSVButton
                        data={cvsData}
                        filename='capex.csv'
                        arrOfProps={['project', 'project_name', 'total_capex1']}
                    />
                </div> */}
                <Chart plotData={capexConfig}
                    legend={{
                        x: 0,
                        y: 1.2,
                    }}
                />
            </div>,
        },
        {
            label: 'Avg. CPPC',
            key: 'Avg. CPPC',
            children: <div style={{ position: 'relative' }}>
                {/* <div className={styles.download}>
                    <CSVButton
                        data={cvsData}
                        filename='capex_spine.csv'
                        arrOfProps={['project', 'project_name', 'capex_spine_100_pct_leadin']}
                    />
                </div> */}
                <Chart plotData={avgCPPC}
                    legend={{
                        x: 0,
                        y: 1.2,
                    }}
                />
            </div>,
        },
        {
            label: '1Y Revenue',
            key: '1Y Revenue',
            children: <div style={{ position: 'relative' }}>
                {/* <div className={styles.download}>
                    <CSVButton
                        data={cvsData}
                        filename='revenue_1Y.csv'
                        arrOfProps={['project', 'project_name', 'revenue_d30_r80']}
                    />
                </div> */}
                <Chart plotData={revenue1YConfig}
                    legend={{
                        x: 0,
                        y: 1.2,
                    }}
                />
            </div>,
        },
        {
            label: 'Gross Profit p/a 3Y',
            key: 'Gross Profit p/a 3Y',
            children: <div style={{ position: 'relative' }}>
                {/* <div className={styles.download}>
                    <CSVButton
                        data={cvsData}
                        filename='gross_profit.csv'
                        arrOfProps={['project', 'project_name', 'gross_profit_pa']}
                    />
                </div> */}
                <Chart plotData={grossProfit3YConfig}
                    legend={{
                        x: 0,
                        y: 1.2,
                    }}
                />
            </div>,
        },
        {
            label: 'Gross Profit Over 6Y Period',
            key: 'Gross Profit Over 6Y Period',
            children: <div style={{ position: 'relative' }}>
                {/* <div className={styles.download}>
                    <CSVButton
                        data={cvsData}
                        filename='gross_profit_over_year_period.csv'
                        arrOfProps={['project', 'project_name', 'gross_profit_over_year_period']}
                    />
                </div> */}
                <Chart plotData={grossProfit6YConfig}
                    legend={{
                        x: 0,
                        y: 1.2,
                    }}
                />
            </div>,
        },
        {
            label: 'EV by UPRN Connected & Passed',
            key: 'EV by UPRN Connected & Passed',
            children: <div style={{ position: 'relative' }}>
                {/* <div className={styles.download}>
                    <CSVButton
                        data={cvsData}
                        filename='EV_per_prem_connected_and_passed.csv'
                        arrOfProps={['project', 'project_name', 'EV_per_prem_connected_and_passed']}
                    />
                </div> */}
                <Chart plotData={UPRNConnectedPassedConfig}
                    legend={{
                        x: 0,
                        y: 1.2,
                    }}
                />
            </div>,
        },
        {
            label: 'EV minus Coupon plus 6Y Gross Profit',
            key: 'EV minus Coupon plus 6Y Gross Profit',
            children: <div style={{ position: 'relative' }}>
                {/* <div className={styles.download}>
                    <CSVButton
                        data={cvsData}
                        filename={'ev_minus_coupon_plus_period_gp_net_EV.csv'}
                        arrOfProps={['project', 'project_name', 'EV_per_prem_connected_and_passed']}
                    />
                </div> */}
                <Chart plotData={ev_minus_coupon_plus_period_gp_net_EVConfig}
                    legend={{
                        x: 0,
                        y: 1.2,
                    }}
                />
            </div>,
        }
    ];

    return <>
        <div className={styles.chartBody}>
            <div style={{ minHeight: '500px' }}>
                <Ranges
                    valueCPPP={minMaxArrCPPP}
                    valueROI={minMaxArrROI}
                    onChangeCPPP={onChangeCPPP}
                    onChangeROI={onChangeROI}
                />
                {
                    isLoadingState && <Skeleton.ChartSkeleton />
                }
                {!isLoadingState &&
                    <Tab
                        config={tabsConfig}
                        defaultValue='CPPP Spreads'
                        smallerTabs />
                }

            </div>
        </div>
    </>;
};