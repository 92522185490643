import { Tag } from 'antd';
import { TableType } from 'components/Charts/types/TableType';
import { numberFormatter, priceFormatter } from 'utils/priceFormatter';

export const getTableConfig = (type: TableType) => {
    const tableConfig = [
        {
            label: 'Name',
            render: (data: { [key: string]: string }) => data.name,
            sortValue: (data: { [key: string]: string }) => data.name,
        },
        {
            label: 'Scope',
            render: (data: { [key: string]: string }) => <Tag color='magenta'>{data.scope}</Tag>,
            sortValue: (data: { [key: string]: string }) => data.scope,
        },
        {
            label: 'Type',
            render: (data: { [key: string]: string }) => data.meta_cost_type === 'opex' ? <Tag color='green'>{data.meta_cost_type}</Tag> : <Tag color='red'>{data.meta_cost_type}</Tag>,
            sortValue: (data: { [key: string]: string }) => data.meta_cost_type,
        },
        {
            label: 'Cost type',
            render: (data: { [key: string]: string }) => data.cost_type === 'opex' ? <Tag color='green'>{data.cost_type}</Tag> : <Tag color='red'>{data.cost_type}</Tag>,
            sortValue: (data: { [key: string]: string }) => data.cost_type,
        },
        {
            label: 'Cost sub type',
            render: (data: { [key: string]: string }) => data.cost_sub_type,
            sortValue: (data: { [key: string]: string }) => data.cost_sub_type,
        },
        {
            label: 'Cost',
            render: (data: { [key: string]: number }) => data.cost ? `£${priceFormatter(data.cost)}` : <p>no data</p>,
            sortValue: (data: { [key: string]: number }) => data.cost,
        },
    ];

    if (type === TableType.LINE) {
        tableConfig.push(
            {
                label: 'Length in meters',
                render: (data: { [key: string]: number }) => data.length_in_metres ? numberFormatter(data.length_in_metres) : <p>no data</p>,
                sortValue: (data: { [key: string]: number }) => data.length_in_metres,
            }
        );

    } else {
        tableConfig.push(
            {
                label: 'Count',
                render: (data: { [key: string]: number }) => data.count ? numberFormatter(data.count) : <p>no data</p>,
                sortValue: (data: { [key: string]: number }) => data.count,
            }
        );
    }

    return tableConfig;
};