import { TableType } from 'components/Charts/types/TableType';
import { Error } from 'components/Error';
import { Table } from 'elements/Table';
import React from 'react';
import { Placeholder } from 'react-bootstrap';
import styles from '../BillOfMaterials.module.scss';
import { getTableConfig } from './tableConfig';

interface Props {
    data: any;
    error: any;
    isLoading?: boolean;
    type: TableType
}

export const DataTable: React.FC<Props> = ({ data, error, isLoading, type }) => {
    return (<div className={styles.tablesSection}>
        <div className={styles.tableSection}>
            <h3 className={styles.subtitle}>{type}</h3>
            {error && <div className={styles.center}>
                <Error text='Something went wrong. Please try again later' />
            </div>}
            {
                !error && <Table
                    config={getTableConfig(type)}
                    data={data}
                />
            }
            {(data.length === 0 && isLoading === false) && <Placeholder />}
        </div>

    </div>);
};