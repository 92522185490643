import { ConfigProvider, Select as SelectAntd } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import { selectToken } from './utils/designToken';
import { tagRender } from './utils/TagRender';

interface Props {
    data: { value: number | string; label: string; }[];
    label?: string;
    value?: any;
    isTags?: boolean;
    onChange?: (param: number | string | any) => void;
    isBlock?: boolean,
    gapBottom?: boolean;
    width?: any;
    isAllowClear?:boolean;
    antdProps?: any;
    children?: any;
    isSearch?:boolean;
}

export const Select: React.FC<Props> = ({ 
    children, gapBottom, data, value, label, isTags, onChange, isBlock, width, isAllowClear, antdProps,
    isSearch = true
}) => {
    const [selectValue, setSelectValue ] = useState(value);

    useEffect(() => {
        setSelectValue(value);
    }, [value]);

    const handleClick = (value: number) => {
        if(onChange){
            onChange(value);
        }
        setSelectValue(value);
    };

    return <div data-testid="SelectAreas" className={classNames(styles.select)}><ConfigProvider theme={selectToken}>
        <div className={classNames(styles.select, {[styles.block]: isBlock, [styles.mb16]: gapBottom})}>
            {label && <label className={styles.selectLabel}>{label}</label>}
            <SelectAntd
                showSearch={isSearch}
                options={data}
                className={styles.select}
                mode={isTags ? 'tags' : undefined}
                style={{ minWidth: '300px', width: '300px',  ...width }}
                placeholder="All"
                onChange={handleClick}
                tagRender={tagRender}
                value={selectValue ?? []}
                allowClear={isAllowClear}
                {...antdProps}
            >
                {children}
            </SelectAntd>
        </div>

    </ConfigProvider>
    </div>;
};