import { Spinner } from 'components/Spinner';
import React from 'react';
import { userAPI } from 'services/User';
import { Body } from './Body';
import { Header } from './Header';

export const SplashPage: React.FC = () => {
    const { isLoading: getCurrentUserIsLoading } = userAPI.useGetCurrentUserQuery();

    if (getCurrentUserIsLoading) {
        return <Spinner />;
    }

    return (<>
        <Header />
        <Body />
    </>);
};