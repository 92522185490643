import { Tab } from 'components/Tab';
import { Chart } from 'elements/Chart';
import { useComparisonRequestData } from 'hooks/useComparisonRequestData';
import { PossibleUrlQuery, useQueryParams } from 'hooks/useQueryParams';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { projectReportAPI } from 'services/Project';
import { projectSummaryReportAPI } from 'services/ProjectSummary';
import { scenarioReportAPI } from 'services/Scenarios';
import { selectDashboard } from 'store/reducers/dashboardSlice';
import { ProjectLevelData, ScenarioLevelData, feasibilityTransformerAllScenarios } from 'utils/feasibilityTransformerAllScenarios/feasibilityTransformerAllScenarios';
import { getPlotsConfig } from 'utils/getPlotsConfig/getPlotsConfig';
import { ChartConfig } from './ChartConfig';

interface Props {
    variables: {
        averageVoucherValue: number;
        avgOpppPa: number;
        revenuePeriod: number;
        cpppAssetValue: number;
        couponRate: number;
        cppcAssetvalue: number;
    };
}
export const CPPP: React.FC<Props> = ({ variables }) => {
    const { data: scenarioReportData = [] } = scenarioReportAPI.useGetScenarioReportQuery();
    const { data: projectReportData = [] } = projectReportAPI.useGetProjectReportQuery();

    const { scenario_uuids, project_uuids } = useSelector(selectDashboard);

    const projectsOptions: { value: string; label: string; }[] = projectReportData.filter((el) => scenario_uuids === el.scenario_uuid).map((element) => {
        return {
            value: element.uuid,
            label: element.name
        };
    });

    // queries for main request - for project level
    const { queries: projectLevelQueries } = useQueryParams([
        PossibleUrlQuery.scenario_uuids,
        PossibleUrlQuery.aggregate_projects
    ], {
        [PossibleUrlQuery.aggregate_projects]: false
    });

    // main request - for project level
    const {
        data: rawProjectSummaryData = [],
        isFetching: isRawProjectSummaryDataLoading
    } = projectSummaryReportAPI.useGetProjectReportQuery({ params: projectLevelQueries }, {
        skip: !projectLevelQueries
    });

    const projectSummaryData = rawProjectSummaryData.map((el) => {
        return {
            ...el,
            project_name: projectReportData.find((item) => item.uuid === el.project_uuid)?.name
        };
    });

    // queries for main request - for scenario level

    const { queries: scenarioLevelQueries } = useQueryParams([
        PossibleUrlQuery.scenario_uuids,
        PossibleUrlQuery.aggregate_projects
    ], {
        [PossibleUrlQuery.aggregate_projects]: true
    });

    // main request - for scenario level
    const {
        data: rawScenarioSummaryData = [],
        isFetching: isRawScenarioSummaryDataLoading,
    } = projectSummaryReportAPI.useGetProjectReportQuery({ params: scenarioLevelQueries }, {
        skip: !scenarioLevelQueries
    });

    const scenarioSummaryData = rawScenarioSummaryData.map((el) => {
        return {
            ...el,
            scenario_uuid: scenario_uuids,
            scenario_name: scenarioReportData.find((item) => item.uuid === scenario_uuids)?.name
        };
    });

    // request for comparison mode - project level
    const {
        comparisonReport: rawComparisonProjectSummaryData,
        isFetching: isRawComparisonProjectSummaryDataLoading,
    } = useComparisonRequestData('/project-summary/on-demand', true, { // must be true for project
        [PossibleUrlQuery.aggregate_projects]: true // must be true for agregation
    });

    const comparisonProjectSummaryData = rawComparisonProjectSummaryData.map((el: any) => {
        const preparedArray = el.response.map((item: any) => ({
            ...item,
            scenario_uuid: el.scenario.value,
            scenario_name: el.scenario.label,
            project_uuid: el.project.value,
            project_name: el.project.label,

        }));
        return preparedArray;
    }).flat(1)
        .map((el: any) => {
            return {
                ...el,
                project_name: projectReportData.find((item) => item.uuid === el.project_uuid)?.name
            };
        });

    // request for comparison mode - scenario level
    const {
        comparisonReport: rawComparisonScenarioSummaryData,
        isFetching: isRawComparisonScenarioSummaryDataLoading
    } = useComparisonRequestData('/project-summary/on-demand', false, { // must be true for project
        [PossibleUrlQuery.aggregate_projects]: true // must be true for agregation
    });

    const comparisonScenarioSummaryData = rawComparisonScenarioSummaryData.map((el: any) => {
        const preparedArray = el.response.map((item: any) => ({
            ...item,
            scenario_uuid: el.scenario.value,
            scenario_name: el.scenario.label,
            project_uuid: el.project.value,
            project_name: el.project.label,

        }));
        return preparedArray;
    }).flat(1)
        .map((el: any) => {
            return {
                ...el,
                project_name: projectReportData.find((item) => item.uuid === el.project_uuid)?.name
            };
        });

    const isLoading = isRawProjectSummaryDataLoading || isRawComparisonScenarioSummaryDataLoading || isRawScenarioSummaryDataLoading || isRawComparisonProjectSummaryDataLoading;

    const defaultValuesForTabsProjectSelect: { [key: string]: null | string; } = {
        cppp_spreads: null,
        ROIYear: null,
        UPRN: null,
        broadband: null,
        total_capex: null,
        cppc_spreads: null,
        revenue_first_year: null,
        gross_profit_pa_year_3: null,
        gross_profit_over_year_period: null,
        EV_per_prem_connected_and_passed: null,
        ev_minus_coupon_plus_period_gp_net_EV: null,
    };

    for (const key in defaultValuesForTabsProjectSelect) {
        defaultValuesForTabsProjectSelect[key] = project_uuids === null ? '' : project_uuids;
    }

    // state for selected project in each tab
    const [selectedProjectUuidFromTab, setSelectedProjectUuidFromTab] = useState<{ [key: string]: null | string; }>(defaultValuesForTabsProjectSelect);

    useEffect(() => {
        if (projectsOptions.length) {
            for (const key in defaultValuesForTabsProjectSelect) {
                defaultValuesForTabsProjectSelect[key] = projectsOptions[0].value;
            }
            setSelectedProjectUuidFromTab(defaultValuesForTabsProjectSelect);

        }
    }, [scenario_uuids]);

    useEffect(() => {
        if (projectsOptions.length) {
            for (const key in defaultValuesForTabsProjectSelect) {
                defaultValuesForTabsProjectSelect[key] = project_uuids;
            }
            setSelectedProjectUuidFromTab(defaultValuesForTabsProjectSelect);

        }
    }, [project_uuids]);

    // calculation of scenario level data

    const scenarioLevelScenarioSummaryData: ScenarioLevelData[] = feasibilityTransformerAllScenarios(scenarioSummaryData, scenarioReportData, variables).scenario_level;
    const scenarioLevelComparisonScenarioSummaryData: ScenarioLevelData[] = feasibilityTransformerAllScenarios(comparisonScenarioSummaryData, scenarioReportData, variables).scenario_level;

    // calculation of project level data
    const projectLevelProjectSummaryData: ProjectLevelData[] = feasibilityTransformerAllScenarios(projectSummaryData, scenarioReportData, variables).project_level;
    const projectLevelComparisonProjectSummaryData: ProjectLevelData[] = feasibilityTransformerAllScenarios(comparisonProjectSummaryData, scenarioReportData, variables).project_level;

    const getDataByProjectId = (selectedData: any) => {
        return projectLevelProjectSummaryData.filter((el) => el.project_uuid === selectedData);
    };

    // config for Average CPPP & CPPC tab
    const scenarioAverageCppp = getPlotsConfig([...scenarioLevelScenarioSummaryData, ...scenarioLevelComparisonScenarioSummaryData], [...getDataByProjectId(selectedProjectUuidFromTab.cppp_spreads), ...projectLevelComparisonProjectSummaryData], 'average_cppp');
    const projectAverageCppp = getPlotsConfig([...scenarioLevelScenarioSummaryData, ...scenarioLevelComparisonScenarioSummaryData], [...getDataByProjectId(selectedProjectUuidFromTab.cppp_spreads), ...projectLevelComparisonProjectSummaryData], 'avg_cppp');

    const averageCPPPTabConfig = [
        {
            label: 'By Scenario',
            key: 'CPPP_Spreads_Scenario',
            children:
                <ChartConfig
                    isLoading={isLoading}
                    plotData={scenarioAverageCppp.scenarioPlotConfig}
                    options={projectsOptions}
                    value={selectedProjectUuidFromTab.cppp_spreads}
                    onChange={(value) => {
                        setSelectedProjectUuidFromTab({
                            ...selectedProjectUuidFromTab,
                            cppp_spreads: value
                        });
                    }}
                    isScenario={true}
                />
        },
        {
            label: 'By Project',
            key: 'CPPP_Spreads_Project',
            children:
                <ChartConfig
                    isLoading={isLoading}
                    plotData={projectAverageCppp.projectPlotConfig}
                    options={projectsOptions}
                    value={selectedProjectUuidFromTab.cppp_spreads}
                    onChange={(value) => {
                        setSelectedProjectUuidFromTab({
                            ...selectedProjectUuidFromTab,
                            cppp_spreads: value
                        });
                    }}
                    isScenario={false}
                />
        },
    ];
    const averageCPPCTabConfig = [
        {
            label: 'By Scenario',
            key: 'CPPC_Spreads_Scenario',
            children:
                <ChartConfig
                    isLoading={isLoading}
                    plotData={scenarioAverageCppp.scenarioPlotConfig}
                    options={projectsOptions}
                    value={selectedProjectUuidFromTab.cppp_spreads}
                    onChange={(value) => {
                        setSelectedProjectUuidFromTab({
                            ...selectedProjectUuidFromTab,
                            cppp_spreads: value
                        });
                    }}
                    isScenario={true}
                />
        },
        {
            label: 'By Project',
            key: 'CPPC_Spreads_Project',
            children:
                <ChartConfig
                    isLoading={isLoading}
                    plotData={projectAverageCppp.projectPlotConfig}
                    options={projectsOptions}
                    value={selectedProjectUuidFromTab.cppp_spreads}
                    onChange={(value) => {
                        setSelectedProjectUuidFromTab({
                            ...selectedProjectUuidFromTab,
                            cppp_spreads: value
                        });
                    }}
                    isScenario={false}
                />
        },
    ];

    // config for ROI
    const configROIPenRateYear = getPlotsConfig([...scenarioLevelScenarioSummaryData, ...scenarioLevelComparisonScenarioSummaryData], [...getDataByProjectId(selectedProjectUuidFromTab.ROIYear), ...projectLevelComparisonProjectSummaryData], 'roi_at_pen_rate_years');

    const ROIPenRateYearTabConfig = [
        {
            label: 'By Scenario',
            key: 'ROI_Years_Scenario',
            children:
                <ChartConfig
                    isLoading={isLoading}
                    plotData={configROIPenRateYear.scenarioPlotConfig}
                    options={projectsOptions}
                    value={selectedProjectUuidFromTab.ROIYear}
                    onChange={(value) => {
                        setSelectedProjectUuidFromTab({
                            ...selectedProjectUuidFromTab,
                            ROIYear: value
                        });
                    }}
                    isScenario={true}
                />
        },
        {
            label: 'By Project',
            key: 'ROI_Years_Project',
            children:
                <ChartConfig
                    isLoading={isLoading}
                    plotData={configROIPenRateYear.projectPlotConfig}
                    options={projectsOptions}
                    value={selectedProjectUuidFromTab.ROIYear}
                    onChange={(value) => {
                        setSelectedProjectUuidFromTab({
                            ...selectedProjectUuidFromTab,
                            ROIYear: value
                        });
                    }}
                    isScenario={false}
                />
        },
    ];

    // config for UPRN 
    const configUPRN = getPlotsConfig([...scenarioLevelScenarioSummaryData, ...scenarioLevelComparisonScenarioSummaryData], [...getDataByProjectId(selectedProjectUuidFromTab.UPRN), ...projectLevelComparisonProjectSummaryData], 'uprn_count'); // ! property === uprn
    const UPRNTabConfig = [
        {
            label: 'By Scenario',
            key: 'UPRN_Scenario',
            children:
                <ChartConfig
                    isLoading={isLoading}
                    plotData={configUPRN.scenarioPlotConfig}
                    options={projectsOptions}
                    value={selectedProjectUuidFromTab.UPRN}
                    onChange={(value) => {
                        setSelectedProjectUuidFromTab({
                            ...selectedProjectUuidFromTab,
                            UPRN: value
                        });
                    }}
                    isScenario={true}
                />
        },
        {
            label: 'By Project',
            key: 'UPRN_Project',
            children:
                <ChartConfig
                    isLoading={isLoading}
                    plotData={configUPRN.projectPlotConfig}
                    options={projectsOptions}
                    value={selectedProjectUuidFromTab.UPRN}
                    onChange={(value) => {
                        setSelectedProjectUuidFromTab({
                            ...selectedProjectUuidFromTab,
                            UPRN: value
                        });
                    }}
                    isScenario={false}
                />
        },
    ];

    // CAPEX 
    const configCapexData = getPlotsConfig([...scenarioLevelScenarioSummaryData, ...scenarioLevelComparisonScenarioSummaryData], [...getDataByProjectId(selectedProjectUuidFromTab.total_capex), ...projectLevelComparisonProjectSummaryData], 'total_capex1');
    const capexTabConfig = [
        {
            label: 'By Scenario',
            key: 'CAPEX_Scenario',
            children:
                <ChartConfig
                    isLoading={isLoading}
                    plotData={configCapexData.scenarioPlotConfig}
                    options={projectsOptions}
                    value={selectedProjectUuidFromTab.total_capex}
                    onChange={(value) => {
                        setSelectedProjectUuidFromTab({
                            ...selectedProjectUuidFromTab,
                            total_capex: value
                        });
                    }}
                    isScenario={true}
                />
        },
        {
            label: 'By Project',
            key: 'CAPEX_Project',
            children:
                <ChartConfig
                    isLoading={isLoading}
                    plotData={configCapexData.projectPlotConfig}
                    options={projectsOptions}
                    value={selectedProjectUuidFromTab.total_capex}
                    onChange={(value) => {
                        setSelectedProjectUuidFromTab({
                            ...selectedProjectUuidFromTab,
                            total_capex: value
                        });
                    }}
                    isScenario={false}
                />
        },
    ];

    // config for unique project
    const configUniqueProperty = getPlotsConfig([...scenarioLevelScenarioSummaryData, ...scenarioLevelComparisonScenarioSummaryData], [...getDataByProjectId([]), ...projectLevelComparisonProjectSummaryData], 'unique_property_count');

    // config for one year revenue 
    const scenarioRevenueFirstYear = getPlotsConfig([...scenarioLevelScenarioSummaryData, ...scenarioLevelComparisonScenarioSummaryData], [...getDataByProjectId(selectedProjectUuidFromTab.revenue_first_year), ...projectLevelComparisonProjectSummaryData], 'revenue_first_year');
    const projectRevenueFirstYear = getPlotsConfig([...scenarioLevelScenarioSummaryData, ...scenarioLevelComparisonScenarioSummaryData], [...getDataByProjectId(selectedProjectUuidFromTab.revenue_first_year), ...projectLevelComparisonProjectSummaryData], 'revenue_d30_r80');

    const revenueFirstYearTabConfig = [
        {
            label: 'By Scenario',
            key: 'FIRST_YEAR_Scenario',
            children:
                <ChartConfig
                    isLoading={isLoading}
                    plotData={scenarioRevenueFirstYear.scenarioPlotConfig}
                    options={projectsOptions}
                    value={selectedProjectUuidFromTab.revenue_first_year}
                    onChange={(value) => {
                        setSelectedProjectUuidFromTab({
                            ...selectedProjectUuidFromTab,
                            revenue_first_year: value
                        });
                    }}
                    isScenario={true}
                />
        },
        {
            label: 'By Project',
            key: 'FIRST_YEAR_Project',
            children:
                <ChartConfig
                    isLoading={isLoading}
                    plotData={projectRevenueFirstYear.projectPlotConfig}
                    options={projectsOptions}
                    value={selectedProjectUuidFromTab.revenue_first_year}
                    onChange={(value) => {
                        setSelectedProjectUuidFromTab({
                            ...selectedProjectUuidFromTab,
                            revenue_first_year: value
                        });
                    }}
                    isScenario={false}
                />
        },
    ];

    // config for  Gross Profit p/a 3Y
    const scenarioGrossProfit = getPlotsConfig([...scenarioLevelScenarioSummaryData, ...scenarioLevelComparisonScenarioSummaryData], [...getDataByProjectId(selectedProjectUuidFromTab.gross_profit_pa_year_3), ...projectLevelComparisonProjectSummaryData], 'gross_profit_pa_year_3');
    const projectGrossProfit = getPlotsConfig([...scenarioLevelScenarioSummaryData, ...scenarioLevelComparisonScenarioSummaryData], [...getDataByProjectId(selectedProjectUuidFromTab.gross_profit_pa_year_3), ...projectLevelComparisonProjectSummaryData], 'gross_profit_pa');

    const grossProfitTabConfig = [
        {
            label: 'By Scenario',
            key: 'Gross_Profit_Scenario',
            children:
                <ChartConfig
                    isLoading={isLoading}
                    plotData={scenarioGrossProfit.scenarioPlotConfig}
                    options={projectsOptions}
                    value={selectedProjectUuidFromTab.gross_profit_pa_year_3}
                    onChange={(value) => {
                        setSelectedProjectUuidFromTab({
                            ...selectedProjectUuidFromTab,
                            gross_profit_pa_year_3: value
                        });
                    }}
                    isScenario={true}
                />
        },
        {
            label: 'By Project',
            key: 'Gross_Profit_Project',
            children:
                <ChartConfig
                    isLoading={isLoading}
                    plotData={projectGrossProfit.projectPlotConfig}
                    options={projectsOptions}
                    value={selectedProjectUuidFromTab.gross_profit_pa_year_3}
                    onChange={(value) => {
                        setSelectedProjectUuidFromTab({
                            ...selectedProjectUuidFromTab,
                            gross_profit_pa_year_3: value
                        });
                    }}
                    isScenario={false}
                />
        },
    ];

    // Gross Profit Over 6Y Period
    const configGrossProfitOverYear = getPlotsConfig([...scenarioLevelScenarioSummaryData, ...scenarioLevelComparisonScenarioSummaryData], [...getDataByProjectId(selectedProjectUuidFromTab.gross_profit_over_year_period), ...projectLevelComparisonProjectSummaryData], 'gross_profit_over_year_period');
    const grossProfitOverYearTabConfig = [
        {
            label: 'By Scenario',
            key: 'Gross_Profit_Over_6Y_Period_Scenario',
            children:
                <ChartConfig
                    isLoading={isLoading}
                    plotData={configGrossProfitOverYear.scenarioPlotConfig}
                    options={projectsOptions}
                    value={selectedProjectUuidFromTab.gross_profit_over_year_period}
                    onChange={(value) => {
                        setSelectedProjectUuidFromTab({
                            ...selectedProjectUuidFromTab,
                            gross_profit_over_year_period: value
                        });
                    }}
                    isScenario={true}
                />
        },
        {
            label: 'By Project',
            key: 'Gross_Profit_Over_6Y_Period_Project',
            children:
                <ChartConfig
                    isLoading={isLoading}
                    plotData={configGrossProfitOverYear.projectPlotConfig}
                    options={projectsOptions}
                    value={selectedProjectUuidFromTab.gross_profit_over_year_period}
                    onChange={(value) => {
                        setSelectedProjectUuidFromTab({
                            ...selectedProjectUuidFromTab,
                            gross_profit_over_year_period: value
                        });
                    }}
                    isScenario={false}
                />
        },
    ];

    // EV by UPRN Connected & Passed
    const configEVPerEm = getPlotsConfig([...scenarioLevelScenarioSummaryData, ...scenarioLevelComparisonScenarioSummaryData], [...getDataByProjectId(selectedProjectUuidFromTab.EV_per_prem_connected_and_passed), ...projectLevelComparisonProjectSummaryData], 'EV_per_prem_connected_and_passed');
    const gevPerEmTabConfig = [
        {
            label: 'By Scenario',
            key: 'EV_by_UPRN_Connected_Scenario',
            children:
                <ChartConfig
                    isLoading={isLoading}
                    plotData={configEVPerEm.scenarioPlotConfig}
                    options={projectsOptions}
                    value={selectedProjectUuidFromTab.EV_per_prem_connected_and_passed}
                    onChange={(value) => {
                        setSelectedProjectUuidFromTab({
                            ...selectedProjectUuidFromTab,
                            EV_per_prem_connected_and_passed: value
                        });
                    }}
                    isScenario={true}
                />
        },
        {
            label: 'By Project',
            key: 'EV_by_UPRN_Connected_Project',
            children:
                <ChartConfig
                    isLoading={isLoading}
                    plotData={configEVPerEm.projectPlotConfig}
                    options={projectsOptions}
                    value={selectedProjectUuidFromTab.EV_per_prem_connected_and_passed}
                    onChange={(value) => {
                        setSelectedProjectUuidFromTab({
                            ...selectedProjectUuidFromTab,
                            EV_per_prem_connected_and_passed: value
                        });
                    }}
                    isScenario={false}
                />
        },
    ];

    // EV minus Coupon plus 6Y Gross Profit
    const configEVMinusCoupon = getPlotsConfig([...scenarioLevelScenarioSummaryData, ...scenarioLevelComparisonScenarioSummaryData], [...getDataByProjectId(selectedProjectUuidFromTab.ev_minus_coupon_plus_period_gp_net_EV), ...projectLevelComparisonProjectSummaryData], 'ev_minus_coupon_plus_period_gp_net_EV');
    const EVMinusCouponTabConfig = [
        {
            label: 'By Scenario',
            key: 'ev_minus_coupon_plus_period_Scenario',
            children:
                <ChartConfig
                    isLoading={isLoading}
                    plotData={configEVMinusCoupon.scenarioPlotConfig}
                    options={projectsOptions}
                    value={selectedProjectUuidFromTab.ev_minus_coupon_plus_period_gp_net_EV}
                    onChange={(value) => {
                        setSelectedProjectUuidFromTab({
                            ...selectedProjectUuidFromTab,
                            ev_minus_coupon_plus_period_gp_net_EV: value
                        });
                    }}
                    isScenario={true}
                />
        },
        {
            label: 'By Project',
            key: 'ev_minus_coupon_plus_period_Project',
            children:
                <ChartConfig
                    isLoading={isLoading}
                    plotData={configEVMinusCoupon.projectPlotConfig}
                    options={projectsOptions}
                    value={selectedProjectUuidFromTab.ev_minus_coupon_plus_period_gp_net_EV}
                    onChange={(value) => {
                        setSelectedProjectUuidFromTab({
                            ...selectedProjectUuidFromTab,
                            ev_minus_coupon_plus_period_gp_net_EV: value
                        });
                    }}
                    isScenario={false}
                />
        },
    ];
    // global tab config
    const tabConfig = [
        // {
        //     label: 'CPPP Buckets',
        //     key: 'CPPP Buckets',
        //     children:
        //         <>
        //             {/* {(propertiesDataLoading || isLoadingPropertyData) && <Spinner className={styles.customSpinner} />}
        //             {(propertiesDataError || errorPropertyData) && <Error />}
        //             {!propertiesDataError && !propertiesDataLoading && !errorPropertyData && <div style={{ position: 'relative' }}>
        //                 <div className={styles.download}>
        //                     <CSVButton
        //                         data={plotlyConfig.map((el: any) => ({
        //                             project: {
        //                                 label: el.name
        //                             },
        //                             response: [
        //                                 ...el.x.map((item: any, i: number) => {
        //                                     return {
        //                                         bucket: item,
        //                                         count: el.y[i] ? el.y[i] : 0
        //                                     };
        //                                 })
        //                             ]
        //                         }))}
        //                         filename="CPPP_buckets_for_scenarios.csv"
        //                         arrOfProps={['bucket', 'count']}
        //                     />
        //                 </div>
        //                 <Chart plotData={plotlyConfig}
        //                     title="CPPP Buckets for Scenarios"
        //                 />
        //             </div>
        //             } */}
        //         </>
        // },
        {
            label: 'CPPP Spreads',
            key: 'CPPP_Spreads',
            children:
                <>
                    <Tab config={averageCPPPTabConfig} defaultValue="CPPP_Spreads_Scenario" />
                </>
        },
        {
            label: 'ROI Years',
            key: 'ROI Years',
            children:
                <>
                    <Tab config={ROIPenRateYearTabConfig} defaultValue="ROI_Years_Scenario" />

                </>
        },
        {
            label: 'UPRN Count',
            key: 'UPRN Count',
            children:
                <>
                    <Tab config={UPRNTabConfig} defaultValue="UPRN_Scenario" />
                </>
        },
        // {
        //     label: 'Broadband Speeds',
        //     key: 'Broadband Speeds',
        //     children:
        //         <>
        //             <Tab config={broadbandTabConfig} defaultValue="Broadband_Scenario" />

        //         </>
        // },
        {
            label: 'Capex',
            key: 'Capex',
            children:
                <>
                    <Tab config={capexTabConfig} defaultValue="CAPEX_Scenario" />

                </>
        },
        {
            label: 'Unique Project/Builds',
            key: 'Unique Project/Builds',
            children:
                <><div style={{ position: 'relative' }}>
                    <Chart plotData={configUniqueProperty.scenarioPlotConfig} // ! check this
                        legend={{
                            x: 0,
                            y: 1.2,
                        }}
                    />
                </div>
                </>
        },
        {
            label: 'Avg. CPPC',
            key: 'Avg. CPPC',
            children:
                <>
                    <Tab config={averageCPPCTabConfig} defaultValue="CPPC_Spreads_Scenario" />
                </>
        },
        {
            label: '1Y Revenue',
            key: '1Y Revenue',
            children:
                <>
                    <Tab config={revenueFirstYearTabConfig} defaultValue="FIRST_YEAR_Scenario" />
                </>
        },
        {
            label: 'Gross Profit p/a 3Y',
            key: 'Gross Profit p/a 3Y',
            children:
                <>
                    <Tab config={grossProfitTabConfig} defaultValue="Gross_Profit_Scenario" />
                </>
        },
        {
            label: 'Gross Profit Over 6Y Period',
            key: 'Gross Profit Over 6Y Period',
            children:
                <>
                    <Tab config={grossProfitOverYearTabConfig} defaultValue="Gross_Profit_Over_6Y_Period_Scenario" />

                </>
        },
        {
            label: 'EV by UPRN Connected & Passed',
            key: 'EV by UPRN Connected & Passed',
            children:
                <>
                    <Tab config={gevPerEmTabConfig} defaultValue="EV_by_UPRN_Connected_Scenario" />

                </>
        },
        {
            label: 'EV minus Coupon plus 6Y Gross Profit',
            key: 'EV minus Coupon plus 6Y Gross Profit',
            children:
                <>
                    <Tab config={EVMinusCouponTabConfig} defaultValue="ev_minus_coupon_plus_period_Scenario" />

                </>
        },
    ];

    return <>
        <Tab config={tabConfig} defaultValue="CPPP_Spreads" isScrolled />
    </>;
};