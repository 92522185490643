import React from 'react';
import styles from './Placeholder.module.scss';
import classNames from 'classnames';

interface Props {
    text?: string;
    center?:boolean
}

export const Placeholder: React.FC<Props> = ({ text = '<No data>', center }) => {
    return <p className={classNames(styles.placeholder, {
        [styles.placeholderCenter]: center
    })}>{text}</p>;
};