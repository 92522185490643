import { Button } from 'components/Button';
import { ROUTES } from 'constants/routes';
import { BodyPage } from 'elements/BodyPage';
import { CardWithHeader } from 'elements/CardWithHeader';
import { RecentCard } from 'elements/RecentCard';
import { Types } from 'elements/Tag/utils/TagTypes';
import { useNavigate } from 'react-router-dom';
import { areaAPI } from 'services/Area';
import { projectReportAPI } from 'services/Project';
import { scenarioReportAPI } from 'services/Scenarios';
import style from './style.module.scss';

type RecentArray = {
    id: string;
    projectName: string;
    pathname: string;
}[];

export const Body = () => {
    const navigate = useNavigate();

    const { data: areas } = areaAPI.useGetAreasQuery();
    const { data: scenarioReportData = [], isLoading: getScenarioReportData } = scenarioReportAPI.useGetScenarioReportQuery();
    const { data: projectReportData, isLoading: getProjectReportData = [] } = projectReportAPI.useGetProjectReportQuery();

    const projectRecent: RecentArray = JSON.parse(localStorage.getItem('recent_projects') || '[]');
    const areaRecent: RecentArray = JSON.parse(localStorage.getItem('recent_areas') || '[]');
    const scenarioRecent: RecentArray = JSON.parse(localStorage.getItem('recent_scenarios') || '[]');

    const cardGenerator = (arrOfData: any, type: Types) => {
        const arrOfCards = arrOfData.map((el: {
            id: string;
            projectName: string;
            pathname: string;
        }) => {
            return <RecentCard
                type={type}
                key={el.id}
                cardName={el.projectName}
                link={el.pathname}
            />;
        });

        return arrOfCards;
    };

    const recentProjectsJSX = cardGenerator(projectRecent, Types.project);
    const recentAreasJSX = cardGenerator(areaRecent, Types.area);
    const recentScenariosJSX = cardGenerator(scenarioRecent, Types.scenario);

    return (<BodyPage>
        <div className={style.bodyGrid}>
            <CardWithHeader
                icon={<svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                    <path d="M1.75 13.2031L5.96875 7.57812L11.5938 10.3906L18.625 1.95312L24.25 7.57812M1.75 23.0469L7.375 14.6094L13 17.4219L18.625 10.3906L24.25 16.0156V23.0469H1.75Z" stroke="#2E3A9A" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                </svg>}
                title='Area'
                headerContent={
                    <Button isOutline isRound onClick={() => navigate(ROUTES.AREAS)}>See all</Button>
                }
            >
                {getScenarioReportData && <>Loading</>}
                <p className={style.data}>{areas?.length}</p>
            </CardWithHeader>
            <CardWithHeader
                icon={<svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                    <path d="M1.75 13.2031L5.96875 7.57812L11.5938 10.3906L18.625 1.95312L24.25 7.57812M1.75 23.0469L7.375 14.6094L13 17.4219L18.625 10.3906L24.25 16.0156V23.0469H1.75Z" stroke="#2E3A9A" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                </svg>}
                title='Scenarios'
                headerContent={
                    <Button isOutline isRound onClick={() => navigate(ROUTES.DASHBOARD)}>See all</Button>
                }
            >
                {getScenarioReportData && <>Loading</>}
                <p className={style.data}>{scenarioReportData?.length}</p>
            </CardWithHeader>
            <CardWithHeader
                icon={<svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                    <path d="M1.75 13.2031L5.96875 7.57812L11.5938 10.3906L18.625 1.95312L24.25 7.57812M1.75 23.0469L7.375 14.6094L13 17.4219L18.625 10.3906L24.25 16.0156V23.0469H1.75Z" stroke="#2E3A9A" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                </svg>}
                title='Projects'
            >
                {getProjectReportData && <>Loading</>}
                <p className={style.data}>{projectReportData?.length}</p>
            </CardWithHeader>

            <CardWithHeader
                icon={<svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                    <path d="M1.75 13.2031L5.96875 7.57812L11.5938 10.3906L18.625 1.95312L24.25 7.57812M1.75 23.0469L7.375 14.6094L13 17.4219L18.625 10.3906L24.25 16.0156V23.0469H1.75Z" stroke="#2E3A9A" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                </svg>}
                title='Area Recent'
                isBodyScrolled
                className={style.cardHeight}
            >
                {recentAreasJSX}
            </CardWithHeader>
            <CardWithHeader
                icon={<svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                    <path d="M1.75 13.2031L5.96875 7.57812L11.5938 10.3906L18.625 1.95312L24.25 7.57812M1.75 23.0469L7.375 14.6094L13 17.4219L18.625 10.3906L24.25 16.0156V23.0469H1.75Z" stroke="#2E3A9A" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                </svg>}
                title='Scenario Recent'
                isBodyScrolled
                className={style.cardHeight}
            >
                {recentScenariosJSX}
            </CardWithHeader>
            <CardWithHeader
                icon={<svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                    <path d="M1.75 13.2031L5.96875 7.57812L11.5938 10.3906L18.625 1.95312L24.25 7.57812M1.75 23.0469L7.375 14.6094L13 17.4219L18.625 10.3906L24.25 16.0156V23.0469H1.75Z" stroke="#2E3A9A" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                </svg>}
                title='Project Recent'
                isBodyScrolled
                className={style.cardHeight}
            >

                {recentProjectsJSX}
            </CardWithHeader>
        </div>

    </BodyPage>);
};