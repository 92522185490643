import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { createBaseQueryWithAuth } from '../createBaseQueryWithAuth';
import { CensusResponse } from 'models/Census';

// Get census demographic data
export const censusAPI = createApi({
    reducerPath: 'censusAPI',
    baseQuery: createBaseQueryWithAuth(`${process.env.REACT_APP_REPORTS_API_URL}/api/v1`),
    endpoints: (builder) => ({
        getDemographicData: builder.query<CensusResponse, {params: string}>({
            query: ({params}) => {

                return {
                    url: `/demographics/census?${params}`,
                    method: 'GET',
                };
            },
        }),
    }),
});