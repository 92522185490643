import React from 'react';
import styles from './ProjectHeadline.module.scss';

interface Props {
    name: string;
}
export const ProjectHeadline:React.FC<Props> = ({name}) => {
    return <div className={styles.labelBlock}>
        <p className={styles.labelText}>Project: <strong>{name}</strong></p>
    </div>;
};