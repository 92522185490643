import { ReactNode } from 'react';
import styles from './style.module.scss';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

interface Props {
    link: string;
    children: ReactNode;
    icon?: any;
}

export const NavItem: React.FC<Props> = ({ link, icon, children, }) => {
    const { pathname } = useLocation();

    return <Link
        to={link}
        className={classNames(styles.navItem, { [styles.active]: pathname === link })}
        data-testid="link"
    >
        {icon && <div className={styles.icon}>
            {icon}
        </div>}
        <div className={styles.childrenContainer}>
            {children}
        </div>
    </Link>;
};
