import { Tag } from 'antd';

export const tagRender = (props: { label: any; value: any; closable: boolean; onClose: any; }) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <Tag
            data-testid="TagRender"
            color={value}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            closeIcon={
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path d="M9 3L3 9M3 3L9 9" stroke="#A3A3A3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            }
            style={{
                marginRight: 3,
                border: '1px solid var(--border)',
                height: '24px',
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                background: 'var(--bg-element)',
                color: 'var(--text-regular)',
                display: 'flex',
                alignItems:'center'
            }}
        >
            {label}
        </Tag>
    );
};