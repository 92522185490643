export const TeamIcon = () => {
    return <>
       <svg xmlns="http://www.w3.org/2000/svg" width="38" height="22" viewBox="0 0 38 22" fill="none">
  <path d="M18.9708 11.102C17.873 11.102 16.7999 10.7765 15.8871 10.1665C14.9744 9.55656 14.263 8.68961 13.8429 7.67529C13.4228 6.66098 13.3129 5.54485 13.527 4.46806C13.7412 3.39127 14.2698 2.40218 15.0461 1.62586C15.8223 0.849533 16.8113 0.320851 17.8879 0.106664C18.9646 -0.107523 20.0806 0.00240518 21.0948 0.422548C22.109 0.842691 22.9758 1.55418 23.5857 2.46704C24.1956 3.3799 24.5211 4.45313 24.5211 5.55101C24.5211 7.02323 23.9363 8.43515 22.8955 9.47617C21.8546 10.5172 20.4428 11.102 18.9708 11.102ZM18.9708 2.22041C18.3121 2.22041 17.6682 2.41574 17.1206 2.78171C16.5729 3.14769 16.1461 3.66786 15.894 4.27644C15.642 4.88503 15.576 5.55471 15.7045 6.20078C15.833 6.84686 16.1502 7.44031 16.6159 7.90611C17.0817 8.3719 17.6751 8.68911 18.3211 8.81762C18.9671 8.94613 19.6367 8.88018 20.2452 8.62809C20.8537 8.37601 21.3738 7.94911 21.7397 7.4014C22.1057 6.85368 22.301 6.20974 22.301 5.55101C22.301 4.66768 21.9501 3.82053 21.3256 3.19592C20.701 2.57131 19.854 2.22041 18.9708 2.22041ZM29.3314 21.4639C29.0382 21.4601 28.7581 21.3419 28.5507 21.1345C28.3434 20.9271 28.2252 20.647 28.2214 20.3537C28.2214 17.4672 26.6525 15.5428 18.9708 15.5428C11.289 15.5428 9.72015 17.4672 9.72015 20.3537C9.72015 20.6482 9.60319 20.9305 9.39501 21.1387C9.18683 21.3469 8.90448 21.4639 8.61007 21.4639C8.31566 21.4639 8.03331 21.3469 7.82513 21.1387C7.61695 20.9305 7.5 20.6482 7.5 20.3537C7.5 13.3224 15.5369 13.3224 18.9708 13.3224C22.4046 13.3224 30.4415 13.3224 30.4415 20.3537C30.4377 20.647 30.3195 20.9271 30.1121 21.1345C29.9048 21.3419 29.6247 21.4601 29.3314 21.4639Z" fill="#B3B9F2"/>
  <path d="M1 16.5V16.5C1.58771 14.1492 3.69993 12.5 6.12311 12.5H10" stroke="#B3B9F2" strokeWidth="2" strokeLinecap="round"/>
  <circle cx="8" cy="6.5" r="2.5" stroke="#B3B9F2" strokeWidth="2"/>
  <path d="M37 16.5V16.5C36.4123 14.1492 34.3001 12.5 31.8769 12.5H28" stroke="#B3B9F2" strokeWidth="2" strokeLinecap="round"/>
  <circle cx="3.5" cy="3.5" r="2.5" transform="matrix(-1 0 0 1 33.5 3)" stroke="#B3B9F2" strokeWidth="2"/>
</svg>
    </>;
};