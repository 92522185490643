import { createApi } from '@reduxjs/toolkit/query/react';
import { CreateUser, CreateUserResponse, DeleteUser, DeleteUserResponse, GetCurrentUserResponse, GetUserResponse, GetUsersResponse, SignUpTokenResponse, SignUpUser, SignUpUserResponse, UpdateUser, UpdateUserResponse } from '../../models/User';
import { createBaseQueryWithAuth } from '../createBaseQueryWithAuth';

export const userAPI = createApi({
    reducerPath: 'userAPI',
    baseQuery: createBaseQueryWithAuth(`${process.env.REACT_APP_CERBERUS_API_URL}/api/v1`), // https://cerberus-staging.infranomics.ai/api/v1/users/current-user
    tagTypes: ['Create', 'Delete', 'Update'],
    endpoints: (build) => ({
        getSignupToken: build.query<SignUpTokenResponse, void>({
            query: () => ({
                url: '/users/signup/token',
                method: 'GET'
            }),
        }),

        signupUser: build.mutation<SignUpUserResponse, SignUpUser>({
            query: (body) => ({
                url: '/users/signup',
                method: 'POST',
                body,
            }),
        }),

        getCurrentUser: build.query<GetCurrentUserResponse, void>({
            query: () => ({
                url: '/users/current-user',
                method: 'GET'
            }),
        }),

        getUser: build.query<GetUserResponse, string>({
            query: (user_uuid) => ({
                url: `/users/${user_uuid}`,
                method: 'GET'
            }),
        }),

        getUsers: build.query<GetUsersResponse, void>({
            query: () => ({
                url: '/users',
                method: 'GET'
            }),
            providesTags: () => ['Create', 'Delete', 'Update']
        }),

        createUser: build.mutation<CreateUserResponse, CreateUser>({
            query: (body) => ({
                url: '/users',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Create']
        }),

        updateUser: build.mutation<UpdateUserResponse, UpdateUser>({
            query: (body) => ({
                url: '/users',
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['Update']
        }),

        deleteUser: build.mutation<DeleteUserResponse, DeleteUser>({
            query: (user_uuid) => ({
                url: `/users/${user_uuid}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Delete']
        }),
    })
});
