import { ConfigProvider } from 'antd';
import classNames from 'classnames';
import { Button } from 'components/Button';
import { sliderToken } from 'components/Charts/utils/sliderToken';
import { Slider } from 'elements/Slider';
import React from 'react';
import { numberFormatter } from 'utils/priceFormatter';
import { RangesValues } from '..';
import styles from '../../style.module.scss';

interface Props {
    valueCPPP: number[];
    valueROI: number[];
    onChangeCPPP: any;
    onChangeROI: any;
}

export const Ranges: React.FC<Props> = ({ valueCPPP, valueROI, onChangeCPPP, onChangeROI }) => {

    return <ConfigProvider
        theme={{
            components: {
                Slider: { ...sliderToken }
            }
            ,
            token: {
                colorPrimary: '#EA347E',
                borderRadius: 2,
                colorBgContainer: '#EA347E',
            },
        }}
    >
        <div className={classNames(styles.sliderBlock, styles.variableBlock)}>
            <p className={styles.sliderBlockTitle}>CPPP Range Filter</p>
            <div className={styles.sliderContainer}>
                <Slider
                    isRange
                    min={RangesValues.minCPPP}
                    max={RangesValues.maxCPPP}
                    value={valueCPPP}
                    step={100}
                    onChange={onChangeCPPP}
                />
                <p className={styles.sliderData}>{numberFormatter(valueCPPP[0])} - {numberFormatter(valueCPPP[1])}</p>
            </div>

            {(valueCPPP[0] !== 0
                || valueCPPP[1] !== 5000
                || valueROI[0] !== 0
                || valueROI[1] !== 100) &&
                <div className={styles.resetButton}>
                    <Button onClick={() => {
                        // setMinMaxArrCPPP([RangesValues.minCPPP, RangesValues.maxCPPP]);
                        // setMinMaxArrROI([RangesValues.minROI, RangesValues.maxROI]);

                        onChangeCPPP([RangesValues.minCPPP, RangesValues.maxCPPP]);
                        onChangeROI([RangesValues.minROI, RangesValues.maxROI]);
                    }}>
                        Reset
                    </Button>
                </div>}

        </div>
        <div className={classNames(styles.sliderBlock, styles.variableBlock)}>
            <p className={styles.sliderBlockTitle}>ROI Range Filter</p>
            <div className={styles.sliderContainer}>
                <Slider
                    isRange
                    min={RangesValues.minROI}
                    max={RangesValues.maxROI}
                    value={valueROI}
                    onChange={onChangeROI}
                />
                <p className={styles.sliderData}>{numberFormatter(valueROI[0])} - {numberFormatter(valueROI[1])}</p>
            </div>

        </div>

    </ConfigProvider >;
};