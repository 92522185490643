import React from 'react';
import style from './style.module.scss';
import Logo3D from './img/infranomics_icon.png';

interface Props {
    children: any;
    imgUrl?: string;
}

export const StartFormLayout:React.FC<Props> = ({imgUrl = '', children}) => {
    return (<>
        <main className={style.layout}>
            <div className={style.imageBlock}>
                {/* @ts-ignore */}
                <img src={!imgUrl && Logo3D} className={style.image} alt="" />
            </div>
            <div className={style.formBlock}>
                <div>
                    {children}
                </div>
            </div>
        </main>
    </>);
};