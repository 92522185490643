import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectComparisonData } from 'store/reducers/comparisonSlice';
import { clearToken } from 'store/reducers/tokenSlice';
import { resolvePromisesSeq } from 'utils/resolvePromisesSeq';
import { urlFormat } from 'utils/urlFormat';
import { useAuth } from './useAuth';
import { PossibleUrlQuery } from './useQueryParams';

export const useComparisonRequestData = (baseUrl = '/', isScenario = true, params?: { [key: string]: any }) => {
    const { token } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [comparisonReport, setComparisonReport] = useState<any>([]);
    const dispatch = useDispatch();

    const comparisonData = useSelector(selectComparisonData);

    useEffect(() => {
        if (comparisonData.length) {
            setIsLoading(true);
            setError(false);

            const filterUrl = isScenario
                ? comparisonData.filter((el: any) => el.scenario.value)
                : comparisonData.filter((el: any) => el.projects.value);

            if (!filterUrl.length) {
                setIsLoading(false);
                setComparisonReport([]);
                return;
            }

            const filterUrlWParams = filterUrl.map((el: any) => {
                const paramObj = {
                    ...el,
                };

                if (params) {
                    for (const key in params) {
                        paramObj[key] = params[key];
                    }
                }

                return paramObj;
            });

            const mapArr = filterUrlWParams
                .map((scenario: any) => {
                    const urlParameters: { [key: string]: any } = isScenario
                        ? { [PossibleUrlQuery.scenario_uuids]: scenario.scenario.value }
                        : { [PossibleUrlQuery.project_uuids]: scenario.projects.value };

                    if (PossibleUrlQuery.aggregate_projects in scenario) {
                        urlParameters[PossibleUrlQuery.aggregate_projects] =
                            scenario[PossibleUrlQuery.aggregate_projects];
                    }

                    if (PossibleUrlQuery.geom_type in scenario) {
                        urlParameters[PossibleUrlQuery.geom_type] = scenario[PossibleUrlQuery.geom_type];
                    }

                    return fetch(
                        `${process.env.REACT_APP_REPORTS_API_URL}/api/v1${baseUrl}?${urlFormat(urlParameters)}`,
                        {
                            method: 'GET',
                            headers: {
                                Authorization: `Bearer ${token}`,
                                // 'X-API-Key': '6T6RfnBFZ2ryeQC38EKYSVU8KR979KYp',
                                'Content-Type': 'application/json',
                            },
                        },
                    )
                        .then(response => {
                            if (response.status === 401) {
                                // If a 401 Unauthorized response is received, dispatch the clearToken action
                                dispatch(clearToken());
                            }
                            return response.json();
                        })
                        .then(response => {
                            return {
                                id: scenario.id,
                                scenario: scenario.scenario,
                                project: scenario.projects,
                                response,
                            };
                        });
                })
                .filter((el: any) => el);

            (async () => {
                try {
                    const users: any = (await resolvePromisesSeq(mapArr)).filter(el => el);

                    setComparisonReport(users);
                } catch {
                    setError(true);
                } finally {
                    setIsLoading(false);
                }
            })();
        } else {
            setComparisonReport([]);
        }
    }, [comparisonData]);

    return {
        comparisonReport,
        isFetching: isLoading,
        error,
    };
};
