import { Chart } from 'elements/Chart';
import React from 'react';
interface Props {
    data: any;
    title: string;
}

export const PieChart: React.FC<Props> = ({ data, title }) => {
    const plotConfig = [
        {
            'name': 'values',
            'labels': data.labels,
            'values': data.values,
            'type': 'pie',
            'automargin': true,
            'textinfo': 'label',
            'showlegend': false,
            'layout': {
                'legend': {
                    'xanchor': 'left',
                    'orientation': 'v'
                }
            },
            'marker': {
                'colors':
                    (data.labels).map((v: any) => {
                        const index = Array.from(new Set(data.labels)).indexOf(v);

                        return ['#033663', '#247BC7', '#55A1E3', '#DAECFC', '#EECA86', '#E9AB11', '#D47E2F', '#C15627'][index];
                    }
                    )
            }
        }
    ];
    return <>
        <Chart
            title={title} plotData={plotConfig}
            style={{
                minHeight: '550px',
            }}

            layout={{
                font: {
                    color: '#020632'
                }
            }}
        />
    </>;
};