import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';

import shapesSlice from './reducers/shapesSlice';
import tokenSlice from './reducers/tokenSlice';
import dashboardSlice from './reducers/dashboardSlice';
import comparisonSlice from './reducers/comparisonSlice';
import geojsonSlice from './reducers/geojsonSlice';

import { mapboxCpppHeatmapAPI } from 'services/Plots/MapboxCpppService';
import { broadbandAPI, censusAPI, productCountsAPI } from 'services/Demographics';
import { capexAPI, opexAPI } from 'services/Costs';
import { cashflowAPI } from 'services/Cashflows';
import { propertiesAPI } from 'services/Properties';
import { nodeCompletionAPI } from 'services/JobSequencing';
import { cmpPlotAPI } from 'services/Plots';
import { pruningAPI } from 'services/PruneResults';
import { materialsAPI } from 'services/Materials';
import { tasksAPI } from 'services/Tasks';
import { workforceAPI } from 'services/WorkForce';
import { projectSummaryReportAPI } from 'services/ProjectSummary';
import { projectAPI, projectReportAPI } from 'services/Project';
import { scenarioAPI, scenarioReportAPI } from 'services/Scenarios';
import { areaAPI } from 'services/Area';
import { mapAPI } from 'services/Map';
import { userAPI } from 'services/User';
import { tokenAPI } from 'services/Token';
import { stylesAPI } from 'services/Styles';
import { polygonAPI } from 'services/Polygon';

const rootReducer = combineReducers({
    [tokenAPI.reducerPath]: tokenAPI.reducer,
    [userAPI.reducerPath]: userAPI.reducer,
    [areaAPI.reducerPath]: areaAPI.reducer,
    [scenarioAPI.reducerPath]: scenarioAPI.reducer,
    [projectAPI.reducerPath]: projectAPI.reducer,
    [mapAPI.reducerPath]: mapAPI.reducer,
    [scenarioReportAPI.reducerPath]: scenarioReportAPI.reducer,
    [projectReportAPI.reducerPath]: projectReportAPI.reducer,
    [projectSummaryReportAPI.reducerPath]: projectSummaryReportAPI.reducer,
    [cashflowAPI.reducerPath]: cashflowAPI.reducer,
    [workforceAPI.reducerPath]: workforceAPI.reducer,
    [materialsAPI.reducerPath]: materialsAPI.reducer,
    [tasksAPI.reducerPath]: tasksAPI.reducer,
    [stylesAPI.reducerPath]: stylesAPI.reducer,
    [cmpPlotAPI.reducerPath]: cmpPlotAPI.reducer,
    [pruningAPI.reducerPath]: pruningAPI.reducer,
    [broadbandAPI.reducerPath]: broadbandAPI.reducer,
    [propertiesAPI.reducerPath]: propertiesAPI.reducer,
    [nodeCompletionAPI.reducerPath]: nodeCompletionAPI.reducer,
    [mapboxCpppHeatmapAPI.reducerPath]: mapboxCpppHeatmapAPI.reducer,
    [opexAPI.reducerPath]: opexAPI.reducer,
    [capexAPI.reducerPath]: capexAPI.reducer,
    [productCountsAPI.reducerPath]: productCountsAPI.reducer,
    [censusAPI.reducerPath]: censusAPI.reducer,
    [polygonAPI.reducerPath]: polygonAPI.reducer,
    shapes: shapesSlice,
    geojson: geojsonSlice,
    token: tokenSlice,
    dashboard: dashboardSlice,
    comparison: comparisonSlice,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware().concat(
                tokenAPI.middleware,
                userAPI.middleware,
                areaAPI.middleware,
                scenarioAPI.middleware,
                projectAPI.middleware,
                mapAPI.middleware,
                scenarioReportAPI.middleware,
                projectReportAPI.middleware,
                projectSummaryReportAPI.middleware,
                cashflowAPI.middleware,
                workforceAPI.middleware,
                materialsAPI.middleware,
                tasksAPI.middleware,
                stylesAPI.middleware,
                cmpPlotAPI.middleware,
                pruningAPI.middleware,
                broadbandAPI.middleware,
                propertiesAPI.middleware,
                nodeCompletionAPI.middleware,
                mapboxCpppHeatmapAPI.middleware,
                opexAPI.middleware,
                capexAPI.middleware,
                productCountsAPI.middleware,
                censusAPI.middleware,
                polygonAPI.middleware,
            ),
    });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
