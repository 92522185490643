import styles from './CreateUser.module.scss';
import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ROUTES } from 'constants/routes';
import { Spinner } from 'components/Spinner';
import { userAPI } from 'services/User';
import { Button } from 'components/Button';
import { HeaderPage } from 'elements/HeaderPage';
import { ThemeSwitcher } from 'elements/ThemeSwitcher';
import { useAuth } from 'hooks/useAuth';
import { BodyPage } from 'elements/BodyPage';
import { Tag } from 'antd';
import { CreateUserPopup } from './CreateUserPopup';
import { Table } from 'elements/Table';
import { format } from 'date-fns';

const CreateUser: React.FC = () => {
  const { data: users, isLoading: getUsersIsLoading } = userAPI.useGetUsersQuery();
  const { data: signupToken } = userAPI.useGetSignupTokenQuery();
  const { setToken } = useAuth();

  const [showPopup, setPopupShow] = useState(false);

  // const [createRow, setCreateRow] = useState(false);
  const [tokenLink, setTokenLink] = useState('');
  const [copied, setCopied] = useState(false);
  const [showCopiedText, setShowCopiedText] = useState(false);
  const [deleteUser, { error: deleteUserError }] = userAPI.useDeleteUserMutation();

  const generateToken = () => signupToken && setTokenLink(`${process.env.REACT_APP_URL}${ROUTES.SIGN_UP}/${signupToken.token}`);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;

    if (copied) {
      setShowCopiedText(true);
      timeoutId = setTimeout(() => {
        setShowCopiedText(false);
        setCopied(false);
      }, 1500);
    }

    return () => clearTimeout(timeoutId);
  }, [copied]);

  if (getUsersIsLoading) {
    return <Spinner className={styles.customSpinner} />;
  }

  const data = users?.map((el) => ({
    ...el,
    key: el.uuid
  }));

  const config = [
    {
      label: 'Name',
      render: (data: { [key: string]: any }) => data.username,
      sortValue: (data: { [key: string]: any }) => data.username,
    },
    {
      label: 'Email',
      render: (data: { [key: string]: any }) => data.email,
      sortValue: (data: { [key: string]: any }) => data.email,
    },
    {
      label: 'Active',
      render: (data: { [key: string]: any }) => data.is_active ? <Tag color="green">Active</Tag> : <Tag color="red">In active</Tag>,
      sortValue: (data: { [key: string]: any }) => data.is_active,
    },
    {
      label: 'Superuser',
      render: (data: { [key: string]: any }) => data.is_superuser && <Tag color="green">Superuser</Tag>,
      sortValue: (data: { [key: string]: any }) => data.is_superuser,
    },
    {
      label: 'Created',
      render: (data: { [key: string]: any }) => format(new Date(data.created_at), 'dd/MM/yy hh:mm'),
      sortValue: (data: { [key: string]: any }) => data.created_at,
    },
    {
      label: 'Updated',
      render: (data: { [key: string]: any }) => format(new Date(data.updated_at), 'dd/MM/yy hh:mm'),
      sortValue: (data: { [key: string]: any }) => data.updated_at,
    },
    {
      label: 'Action',
      render: (uuid: any) => {

        const isSuperuser = users?.filter((el) => el.is_superuser && el.uuid === uuid);

        const handleDelete = async () => {
          try {
            await deleteUser(uuid);
            // alert('Are you sure?');
          } catch (error) {
            console.error('Error deleting user:', error, deleteUserError);
          }
        };
        if (isSuperuser?.length) return;

        return (
          <>
            <button onClick={handleDelete}>
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28 13C28.2652 13 28.5196 13.1054 28.7071 13.2929C28.8946 13.4804 29 13.7348 29 14C29 14.2652 28.8946 14.5196 28.7071 14.7071C28.5196 14.8946 28.2652 15 28 15H27L26.997 15.071L26.064 28.142C26.0281 28.6466 25.8023 29.1188 25.4321 29.4636C25.0619 29.8083 24.5749 30 24.069 30H15.93C15.4241 30 14.9371 29.8083 14.5669 29.4636C14.1967 29.1188 13.9709 28.6466 13.935 28.142L13.002 15.072C13.0005 15.048 12.9998 15.024 13 15H12C11.7348 15 11.4804 14.8946 11.2929 14.7071C11.1054 14.5196 11 14.2652 11 14C11 13.7348 11.1054 13.4804 11.2929 13.2929C11.4804 13.1054 11.7348 13 12 13H28ZM24.997 15H15.003L15.931 28H24.069L24.997 15ZM22 10C22.2652 10 22.5196 10.1054 22.7071 10.2929C22.8946 10.4804 23 10.7348 23 11C23 11.2652 22.8946 11.5196 22.7071 11.7071C22.5196 11.8946 22.2652 12 22 12H18C17.7348 12 17.4804 11.8946 17.2929 11.7071C17.1054 11.5196 17 11.2652 17 11C17 10.7348 17.1054 10.4804 17.2929 10.2929C17.4804 10.1054 17.7348 10 18 10H22Z" fill="var(--table-icon-color)" />
              </svg>
            </button>
            <button>
              <svg width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M31 15.24C31.0008 15.1084 30.9756 14.9779 30.9258 14.8561C30.876 14.7343 30.8027 14.6235 30.71 14.53L26.47 10.29C26.3766 10.1973 26.2658 10.124 26.1439 10.0742C26.0221 10.0245 25.8916 9.99926 25.76 10C25.6284 9.99926 25.4979 10.0245 25.3761 10.0742C25.2543 10.124 25.1435 10.1973 25.05 10.29L22.22 13.12L11.29 24.05C11.1973 24.1435 11.124 24.2543 11.0742 24.3761C11.0245 24.4979 10.9993 24.6284 11 24.76V29C11 29.2652 11.1054 29.5196 11.2929 29.7071C11.4804 29.8947 11.7348 30 12 30H16.24C16.3799 30.0076 16.5199 29.9857 16.6508 29.9358C16.7818 29.8858 16.9007 29.8089 17 29.71L27.87 18.78L30.71 16C30.8013 15.9031 30.8757 15.7915 30.93 15.67C30.9397 15.5903 30.9397 15.5097 30.93 15.43C30.9347 15.3835 30.9347 15.3366 30.93 15.29L31 15.24ZM15.83 28H13V25.17L22.93 15.24L25.76 18.07L15.83 28ZM27.17 16.66L24.34 13.83L25.76 12.42L28.58 15.24L27.17 16.66Z" fill="var(--table-icon-color)" />
              </svg>

            </button>
          </>
        );
      },
    }
  ];

  return (<>
    <HeaderPage titleText="Team">
      <div style={{ display: 'flex', gap: 28 }}>
        <ThemeSwitcher />
        <button onClick={() => setToken!('')}>
          <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
            <path d="M52 25.997C52.0085 31.1594 50.4733 36.2064 47.5915 40.4893C45.215 44.0343 42.0017 46.939 38.2358 48.9466C34.47 50.9542 30.2675 52.0029 26 52C21.7325 52.0029 17.53 50.9542 13.7641 48.9466C9.99824 46.939 6.78497 44.0343 4.40844 40.4893C2.14566 37.1159 0.704607 33.2592 0.200795 29.2284C-0.303017 25.1976 0.144431 21.1048 1.50727 17.2781C2.87011 13.4514 5.11043 9.99731 8.04863 7.19263C10.9868 4.38795 14.5412 2.31075 18.4268 1.12753C22.3124 -0.0556931 26.4211 -0.312011 30.4236 0.37912C34.4261 1.07025 38.211 2.68961 41.4749 5.10737C44.7388 7.52514 47.391 10.6741 49.2187 14.3017C51.0465 17.9293 51.999 21.9348 52 25.997Z" fill="#0E1665" />
            <path d="M26.5206 26.102C25.4228 26.102 24.3497 25.7765 23.437 25.1665C22.5242 24.5566 21.8128 23.6896 21.3927 22.6753C20.9726 21.661 20.8627 20.5449 21.0769 19.4681C21.291 18.3913 21.8197 17.4022 22.5959 16.6259C23.3721 15.8495 24.3611 15.3209 25.4378 15.1067C26.5144 14.8925 27.6304 15.0024 28.6446 15.4225C29.6588 15.8427 30.5257 16.5542 31.1355 17.467C31.7454 18.3799 32.071 19.4531 32.071 20.551C32.071 22.0232 31.4862 23.4352 30.4453 24.4762C29.4044 25.5172 27.9926 26.102 26.5206 26.102ZM26.5206 17.2204C25.8619 17.2204 25.2181 17.4157 24.6704 17.7817C24.1228 18.1477 23.6959 18.6679 23.4439 19.2764C23.1918 19.885 23.1259 20.5547 23.2544 21.2008C23.3829 21.8469 23.7 22.4403 24.1658 22.9061C24.6315 23.3719 25.2249 23.6891 25.8709 23.8176C26.5169 23.9461 27.1865 23.8802 27.795 23.6281C28.4035 23.376 28.9236 22.9491 29.2896 22.4014C29.6555 21.8537 29.8508 21.2097 29.8508 20.551C29.8508 19.6677 29.4999 18.8205 28.8754 18.1959C28.2509 17.5713 27.4038 17.2204 26.5206 17.2204ZM36.8813 36.4639C36.5881 36.4601 36.3079 36.3419 36.1006 36.1345C35.8932 35.9271 35.775 35.647 35.7712 35.3537C35.7712 32.4672 34.2023 30.5428 26.5206 30.5428C18.8389 30.5428 17.27 32.4672 17.27 35.3537C17.27 35.6482 17.153 35.9305 16.9448 36.1387C16.7367 36.3469 16.4543 36.4639 16.1599 36.4639C15.8655 36.4639 15.5831 36.3469 15.375 36.1387C15.1668 35.9305 15.0498 35.6482 15.0498 35.3537C15.0498 28.3224 23.0868 28.3224 26.5206 28.3224C29.9544 28.3224 37.9913 28.3224 37.9913 35.3537C37.9875 35.647 37.8693 35.9271 37.662 36.1345C37.4546 36.3419 37.1745 36.4601 36.8813 36.4639Z" fill="white" />
          </svg>
        </button>
      </div>
    </HeaderPage>
    <BodyPage>
      <div className={styles.buttons}>
        {/* <button disabled={createRow} className={styles.button} onClick={() => setCreateRow(true)}>Add user</button> */}
        <Button onClick={() => setPopupShow(true)}
          style={{
            whiteSpace: 'nowrap',
            fontSize: '16px',
            height: '46px'
          }}
        >
          Add user
        </Button>
        <div className={styles.clipBoardContainer}>
          {
            tokenLink ? <CopyToClipboard text={tokenLink} onCopy={() => setCopied(true)}>
              <div className={styles.clipBoard}>Copy link</div>
            </CopyToClipboard>
              :
              // <button className={styles.button} onClick={generateToken}>Generate invite token</button>
              <Button onClick={generateToken}
                style={{
                  display: 'block',
                  width: 'auto',
                  whiteSpace: 'nowrap',
                  fontSize: '16px',
                  height: '46px'
                }}
              >
                Generate invite token
              </Button>
          }
          {showCopiedText && <div className={styles.clipBoardText}>Copied</div>}
        </div>
      </div>
      {/* <div className={styles.labels}>
        <div className={styles.label}>User name*</div>
        <div className={styles.label}>User email*</div>
        <div className={styles.label}>User password</div>
      </div>
      {users?.map((user) => <UserRow key={user.uuid} user={user} />)}
      {
        createRow && <UserRow createRow={true} setCreateRow={setCreateRow} />
      } */}

      {/* <Table
        isClickable={false}
        data={users}
        config={config} pageSize={10}     
        /> */}
      {/* <ConfigProvider
        theme={{
          components: {
            Table: {
              colorBgContainer: 'transparent',
              bodySortBg: 'transparent',
              headerBg: 'transparent',
              borderColor: 'var(--table-border)',
              cellFontSize: 16,
              rowHoverBg: 'var(--table-row-hover)',
              rowSelectedBg: 'var(--table-row-selected)',
              headerColor: 'var(--table-color-text)',
              colorText: 'var(--table-color-text)',
              rowSelectedHoverBg: 'var(--table-row-selected)',
            },
          },
        }}
      >
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          columns={config}
          dataSource={data}
          pagination={false}
        />
      </ConfigProvider> */}

      <Table
        config={config}
        data={data}
      />
    </BodyPage>

    {showPopup && <CreateUserPopup onClose={() => setPopupShow(false)} />}
  </>);

};

export default CreateUser;