import { BreadCrumbs } from './BreadCrumbs';
import styles from './ProjectsToolbar.module.scss';
import { Outlet } from 'react-router-dom';
import { Sort } from './Sort';
import { HeaderPage } from 'elements/HeaderPage';
import { BodyPage } from 'elements/BodyPage';

interface Props {
}

const ProjectsToolbar: React.FC<Props> = () => {
  return (<>
    <HeaderPage>
      <div className={styles.content}>
        <BreadCrumbs />
        <Sort />
      </div>
    </HeaderPage>
    <BodyPage>
      <Outlet />
    </BodyPage>
  </>);
};

export default ProjectsToolbar;