import { nanoid } from '@reduxjs/toolkit';
import { Error } from 'components/Error';
import { Spinner } from 'components/Spinner';
import { useChartDataParams } from 'hooks/useChartDataParams';
import { useComparisonRequestData } from 'hooks/useComparisonRequestData';
import { PossibleUrlQuery, useQueryParams } from 'hooks/useQueryParams';
import { ProductCounts } from 'models/OpenReachProductCounts';
import React, { useState } from 'react';
import { productCountsAPI } from 'services/Demographics';
import styles from '../style.module.scss';
import classNames from 'classnames';
import { Placeholder } from 'components/Placeholder';
import { Select } from 'elements/Select';
import { Table } from 'elements/Table';
import { useSelector } from 'react-redux';
import { projectReportAPI } from 'services/Project';
import { selectDashboard } from 'store/reducers/dashboardSlice';
import { numberFormatter } from 'utils/priceFormatter';

interface Props {
    isScenario?: boolean;
}

export const OpenReachLineSpeeds: React.FC<Props> = ({ isScenario = true }) => {
    const { data: projectReportData = [] } = projectReportAPI.useGetProjectReportQuery();

    const { scenario_uuids, project_uuids } = useSelector(selectDashboard);

    const [selectedData, setSelectedData] = useState<string>(project_uuids === null ? '' : project_uuids);

    const projectsOptions: { value: string; label: string; }[] = projectReportData.filter((el) => scenario_uuids === el.scenario_uuid).map((element) => {
        return {
            value: element.uuid,
            label: element.name
        };
    });

    const listOfQueries = isScenario ? [PossibleUrlQuery.scenario_uuids] : [PossibleUrlQuery.project_uuids];
    const objWithParams = isScenario ? {} : { [PossibleUrlQuery.project_uuids]: selectedData };

    // queries for main request
    const { queries } = useQueryParams(listOfQueries, objWithParams);

    // main request
    const {
        data: productCountsData = [],
        isFetching: productCountsDataLoading,
        error: productCountsError
    } = productCountsAPI.useGetProductCountsQuery({ params: queries }, {
        skip: !queries
    });

    // request for comparison mode
    const {
        comparisonReport: comparisonProductCountsData,
        isFetching: comparisonProductCountsDataLoading,
        error: comparisonProductCountsError
    } = useComparisonRequestData('/demographics/openreach/product-counts', isScenario, objWithParams);

    const { data: chartDataParams } = useChartDataParams(isScenario, selectedData);

    const isLoadingState = productCountsDataLoading || comparisonProductCountsDataLoading;
    const isErrorState = productCountsError || comparisonProductCountsError;

    if (isErrorState) {
        return <div className={styles.center}>
            <Error text='Something went wrong. Please try again later or click the button.' />
        </div>;
    }

    const sortedData = [...productCountsData].sort((a: { product_count: number }, b: { product_count: number }) => { return b.product_count - a.product_count; });

    const chartData: {
        id: string,
        response: ProductCounts[],
        scenario: { value: number, label: string } | null,
        project: { value: number, label: string } | null,
    }[] = [
            {
                id: nanoid(),
                ...chartDataParams,
                response: sortedData,
            },
            ...comparisonProductCountsData
        ];

    const preparedTableData = chartData.map((el: any) => {
        return el.response.map((item: any) => {
            if (isScenario) {
                return { ...item, scenario: el.scenario.label };

            } else return { ...item, project: el.project.label };
        });
    }).flat(1);

    const tableConfig = [
        {
            label: isScenario ? 'Scenario Name' : 'Project Name',
            render: (data: { [key: string]: string }) => isScenario ? data.scenario : data.project,
            sortValue: (data: { [key: string]: string }) => isScenario ? data.scenario : data.project,
        },
        {
            label: 'Product name',
            render: (data: { [key: string]: string }) => data.product_name || <Placeholder />,
            sortValue: (data: { [key: string]: string }) => data.product_name,
        },
        {
            label: 'Product count',
            render: (data: { [key: string]: number }) => data.product_count ? numberFormatter(data.product_count) : <Placeholder />,
            sortValue: (data: { [key: string]: number }) => data.product_count,
        },
    ];

    return <>
        {isLoadingState && <Spinner className={styles.customSpinner} />}
        {!isLoadingState && <div className={styles.openreachTables}>
            {/* <TableHeader>
            <CSVButton
                data={[]}
                preparedData={cvsData}
                filename="data.csv"
            />
        </TableHeader> */}

            {!isScenario && <div className={classNames(styles.sliderBlock, styles.variableBlock)}>
                <p className={styles.sliderBlockTitle}>Select project: </p>
                <div className={classNames(styles.inputWrapper, styles.inputWrapperTransparent)}>
                    <Select
                        data={projectsOptions}
                        value={selectedData}
                        onChange={setSelectedData}
                        width={{ width: '600px' }}
                    />
                </div>
            </div>}
            <Table
                data={preparedTableData}
                config={tableConfig}

            />
        </div>}

    </>;
};