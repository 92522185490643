import { CardWithHeader } from 'elements/CardWithHeader';
import styles from '../styles.module.scss';
import { Button } from 'components/Button';

export const CardsWithHeader = () => {
    return (
        <table className={styles.table}>
            <tr>
                <td>CardWithHeader with content</td>
                <td>
                    <CardWithHeader
                        title='Area'
                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                            <path d="M1.75 13.2031L5.96875 7.57812L11.5938 10.3906L18.625 1.95312L24.25 7.57812M1.75 23.0469L7.375 14.6094L13 17.4219L18.625 10.3906L24.25 16.0156V23.0469H1.75Z" stroke="var(--card-with-header-icon-color)" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>}
                    >
                        <p style={{
                            color: '#FFF',
                            fontFamily: 'Vollkorn',
                            fontSize: '60px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: 'normal'
                        }}>16</p>
                    </CardWithHeader>
                </td>
            </tr>
            <tr>
                <td>CardWithHeader with additional data in header</td>
                <td>
                    <CardWithHeader
                        title='Area'
                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                            <path d="M1.75 13.2031L5.96875 7.57812L11.5938 10.3906L18.625 1.95312L24.25 7.57812M1.75 23.0469L7.375 14.6094L13 17.4219L18.625 10.3906L24.25 16.0156V23.0469H1.75Z" stroke="var(--card-with-header-icon-color)" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>}
                        headerContent={
                            <Button>Data</Button>
                        }
                    >
                        <p style={{
                            color: '#FFF',
                            fontFamily: 'Vollkorn',
                            fontSize: '60px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: 'normal'
                        }}>16</p>
                    </CardWithHeader>
                </td>
            </tr>
            <tr>
                <td>CardWithHeader with scrolled body</td>
                <td>
                    <CardWithHeader
                        title='Area'
                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                            <path d="M1.75 13.2031L5.96875 7.57812L11.5938 10.3906L18.625 1.95312L24.25 7.57812M1.75 23.0469L7.375 14.6094L13 17.4219L18.625 10.3906L24.25 16.0156V23.0469H1.75Z" stroke="var(--card-with-header-icon-color)" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>}
                        isBodyScrolled
                        className={styles.cardHeight}
                    >
                        <div style={{
                            background: '#FFF',
                            height: '500px'
                        }}> height: 500px</div>
                    </CardWithHeader>
                </td>
            </tr>
        </table>);
};