/* eslint-disable @typescript-eslint/no-unused-vars */
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { useMap } from 'app/providers/MapProvider';
import { Spinner } from 'components/Spinner';
import { useAppDispatch } from 'hooks/redux';
import { useAuth } from 'hooks/useAuth';
import { useMapStyles } from 'hooks/useMapStyles';
import mapboxGl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { mapAPI } from 'services/Map/MapService';
import { projectAPI } from 'services/Project/ProjectService';
import { stylesAPI } from 'services/Styles/StylesService';
import { addArrayOfShapes } from 'store/reducers/shapesSlice';
import styles from './Map.module.scss';
import { useMapHover } from './hooks/useMapHover/useMapHover';
import { useRefreshDataLayers } from './hooks/useRefreshDataLayers/useRefreshDataLayers';
import { useTrackLastPosition } from './hooks/useTrackLastPosition/useTrackLastPosition';
import { Polygon } from './ui/Polygon';
import { ZoomControl } from './ui/ZoomControl';
import { addSourcesAndLayers } from './utils/addSourcesAndLayers';
import { getLastPosition } from './utils/getLastPosition';
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import { useMapSelect } from './hooks/useMapSelect';
import { baseLayers } from './resources/layers';
import { baseSources } from './resources/sources';
import { applyStyles } from './styles/applyStyles';
import { Shape } from './styles/shapes';
import LayersPanel from './ui/LayersPanel/LayersPanel';
import { ProjectHeadline } from './ui/ProjectHeadline';
import { PropertiesModal } from './ui/PropertiesModal';
import { fitBounds } from './utils/fitBounds';
(mapboxGl as any).workerClass = MapboxWorker;

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
mapboxGl.accessToken = process.env.REACT_APP_MAP_TOKEN!;

interface Props {
  polygon?: boolean;
  isMapPage?: boolean;
  properties?: boolean;
  layersPanel?: boolean;
  geocoder?: boolean;
  isAtlasPage?: boolean;
}

const Map: React.FC<Props> = ({ polygon, properties, layersPanel, isMapPage, geocoder, isAtlasPage }) => {
  const { setDraw, setMap, mapLoading, startLoading, endLoading, selectedFeature } = useMap();
  const dispatch = useAppDispatch();
  const params = useParams();
  const { token } = useAuth();
  const mapRef = useRef<HTMLDivElement>(null);
  const { data: projects, error: getProjectsError, isLoading: getProjectsIsLoading } = projectAPI.useGetProjectsQuery({ scenario_uuid: params.param2 || '' }, {
    skip: !params.param2
  });

  const selectedProject = projects?.find((el) => el.uuid === params.param3);

  const [uuidPoint, setUuidPoint] = useState('');
  const [showHierarchPopup, setShowHierarchPopup] = useState<boolean>(false);

  // Additional Map features (update, hover, select) described as custom hooks
  useRefreshDataLayers();
  useMapHover(); // ?
  useMapSelect(setUuidPoint, setShowHierarchPopup); // node sequence popup
  useTrackLastPosition(polygon);

  const { data: layers, error: getLayersError, isLoading: getLayersIsLoading } = mapAPI.useGetLayersQuery(
    { project_uuid: params.param3 || '' },
    { skip: !params.param3 }
  );

  const { data: stylesData = [], error: getStylesError, isLoading: getStylesIsLoading } = stylesAPI.useGetStylesQuery();
  const { stylesData: stylesWithDefault } = useMapStyles();

  useEffect(() => {
    localStorage.removeItem('basemap');
  }, []);

  useEffect(() => {
    getStylesIsLoading ? startLoading() : endLoading();

    const store: Shape[] = [];
    stylesData.forEach((item) => {
      const { style } = item;

      if (style.type === 'Point') {
        if (!store.some((el) => el.name === style.shapeName)) {
          store.push({
            name: style.shapeName,
            svg: style.shapeForm,
          });
        }

        style.props.forEach((el: any) => {
          if (
            !store.some((elem) => elem.name === el.shapeName) &&
            el.shapeName &&
            el.shapeForm
          ) {
            store.push({
              name: el.shapeName,
              svg: el.shapeForm,
            });
          }
        });
      }
    });

    dispatch(addArrayOfShapes(store));
  }, [getStylesIsLoading]);

  useEffect(() => {
    if (!mapRef.current) return;

    // just because in first render layers was undefined, map creates 2 times 
    // and we have strange behavior with map background
    if (!layers?.length && !polygon) return;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const lastPosition = getLastPosition(params.param3!);

    const map = new mapboxGl.Map({
      container: mapRef.current,
      style: {
        version: 8,
        sources: baseSources,
        layers: baseLayers,
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
      },
      center: [
        lastPosition?.x ?? -2.1797787555771606,
        lastPosition?.y ?? 52.06050352500296
      ],

      maxBounds: [-10.731977871337193, 49.59411301175666, 1.9010451201391163, 61.32814923895637],
      zoom: lastPosition?.zoom ?? 10,
      minZoom: 5.5 // zoom < 5.5 caused 404s. 
    });

    if (!map) return;

    if (isAtlasPage) {
      map.setCenter([-2.1797787555771606, 52.06050352500296]);
    }

    map.on('style.load', () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      layers && addSourcesAndLayers(layers, map, params.param2!, params.param3!);

      setMap(map);

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      (layers && !lastPosition) && fitBounds(layers, params.param2!, params.param3!, token, map, dispatch);
      layers && !getStylesIsLoading && applyStyles(map, layers, stylesWithDefault);

      // !! Do not delete this code! 
      // !! This code can help test layout styling if server doesn't work correctly
      // const serverResponse = localStorage.getItem('TEST_SAVE_LAYERS') ? JSON.parse(localStorage.getItem('TEST_SAVE_LAYERS')!) : null;
      // layers && applyStyles(map, layers, serverResponse || defaultStyles);
    });

    return () => {
      setDraw(null);
      setMap(null);
    };
  }, [layers, polygon, geocoder, isAtlasPage, getStylesIsLoading]);

  return (
    <div className={styles.mapContainer} data-testid="Map">
      <div className={styles.map} id="map" ref={mapRef} />
      <>
        {selectedProject && <ProjectHeadline name={selectedProject.name || ''} />}
        {polygon && <Polygon />}
        {(properties && selectedFeature) && <PropertiesModal />}
        {layersPanel && <LayersPanel shareView />}
        {/* Node sequence popup */}
        {/* {showHierarchPopup && <HierarchyModal pointUuid={uuidPoint} setShowHierarchPopup={setShowHierarchPopup} />} */}

        <ZoomControl />

        {/* {geocoder && <GeoDecoder project={params.param3} />} */}
      </>
      {(mapLoading && isMapPage) && <Spinner className={styles.customSpinner} />}
    </div>
  );
};

export default Map;
