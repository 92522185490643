import { Tooltip } from 'antd';
import { Error } from 'components/Error';
import { Tab } from 'components/Tab';
import { Table } from 'elements/Table';
import { PossibleUrlQuery, useQueryParams } from 'hooks/useQueryParams';
import React from 'react';
import { useSelector } from 'react-redux';
import { projectReportAPI } from 'services/Project';
import { projectSummaryReportAPI } from 'services/ProjectSummary';
import { scenarioReportAPI } from 'services/Scenarios';
import { selectDashboard } from 'store/reducers/dashboardSlice';
import { feasibilityTransformerAllScenarios } from 'utils/feasibilityTransformerAllScenarios/feasibilityTransformerAllScenarios';
import { numberFormatter, priceFormatter } from 'utils/priceFormatter';
import styles from '../style.module.scss';

interface Props {
    variables: {
        averageVoucherValue: number;
        avgOpppPa: number;
        revenuePeriod: number;
        cpppAssetValue: number;
        couponRate: number;
        cppcAssetvalue: number;
    };
}

export const BestProjectByROI: React.FC<Props> = ({ variables }) => {
    const dashboardSelectData = useSelector(selectDashboard);

    // queries for main request
    const { queries } = useQueryParams([
        PossibleUrlQuery.area_uuids,
        PossibleUrlQuery.aggregate_projects
    ], {
        [PossibleUrlQuery.aggregate_projects]: false
    });

    // main request
    const {
        data: projectSummaryData = [],
        error: projectSummaryError
    } = projectSummaryReportAPI.useGetProjectReportQuery({ params: queries }, {
        skip: !queries
    });
    const { data: scenariosData = [] } = scenarioReportAPI.useGetScenarioReportQuery();
    const { data: projectsData = [] } = projectReportAPI.useGetProjectReportQuery();

    const isErrorState = projectSummaryError;

    if (isErrorState) {
        return <div className={styles.center}>
            <Error text='Something went wrong. Please try again later or click the button.' />
        </div>;
    }
    // added scenario_name and scenario_uuid
    const projectSummaryDataWithScenario = projectSummaryData.map((el) => {
        const scenario_uuid = projectsData.find((item) => item.uuid === el.project_uuid)?.scenario_uuid;
        const project_name = projectsData.find((item) => item.uuid === el.project_uuid)?.name;
        const scenario_name = scenariosData.find((item) => item.uuid === scenario_uuid)?.name;

        return {
            scenario_uuid,
            scenario_name,
            project_name,
            ...el
        };
    });

    const precalculatedSummaryData = feasibilityTransformerAllScenarios(projectSummaryDataWithScenario, scenariosData, variables).project_level;
    const selectedProjectData = precalculatedSummaryData.filter((el: any) => dashboardSelectData.scenario_uuids === el.scenario_uuid);

    const config = [
        {
            label: 'Te Name',
            render: (data: { project_name: string }) => <Tooltip title={data.project_name} placement='topLeft'>{data.project_name?.split('_').map((el: string) => el[0]?.toUpperCase() + el?.slice(1))?.join(' ')}</Tooltip>,
            sortValue: (data: { project_name: string }) => data.project_name,
        },
        {
            label: 'Scenario Name',
            render: (data: { scenario_name: string }) => <Tooltip title={data.scenario_name} placement='topLeft'>{data.scenario_name}</Tooltip>,
            sortValue: (data: { scenario_name: string }) => data.scenario_name,
        },
        {
            label: 'ROI Years @ Pen. Rate',
            render: (data: { roi_at_pen_rate_years: number }) => data.roi_at_pen_rate_years.toFixed(3),
            sortValue: (data: { roi_at_pen_rate_years: number }) => data.roi_at_pen_rate_years,
        },
        {
            label: 'Uprn Count',
            render: (data: { property_count: number }) => numberFormatter(data.property_count),
            sortValue: (data: { property_count: number }) => data.property_count,
        },
        {
            label: 'Prems connected',
            render: (data: { prems_connected: number }) => data.prems_connected.toFixed(3),
            sortValue: (data: { prems_connected: number }) => data.prems_connected,
        },
        {
            label: 'Total capex',
            render: (data: { total_capex1: number }) => `£${priceFormatter(data.total_capex1)}`,
            sortValue: (data: { total_capex1: number }) => data.total_capex1,
        },
        {
            label: 'Total build capex',
            render: (data: { total_capex: number }) => `£${priceFormatter(data.total_capex)}`,
            sortValue: (data: { total_capex: number }) => data.total_capex,
        },
        {
            label: 'Total leadin capex',
            render: (data: { total_leadin_capex: number }) => `£${priceFormatter(data.total_leadin_capex)}`,
            sortValue: (data: { total_leadin_capex: number }) => data.total_leadin_capex,
        },
        {
            label: 'Avg cppp',
            render: (data: { avg_cppp: number }) => `£${priceFormatter(data.avg_cppp)}`,
            sortValue: (data: { avg_cppp: number }) => data.avg_cppp,
        },
        {
            label: 'Total opex per annum',
            render: (data: { total_opex_per_annum: number }) => `£${priceFormatter(data.total_opex_per_annum)}`,
            sortValue: (data: { total_opex_per_annum: number }) => data.total_opex_per_annum,
        },
        {
            label: 'Voucher subsidy',
            render: (data: { voucher_subsidy: number }) => `£${priceFormatter(data.voucher_subsidy)}`,
            sortValue: (data: { voucher_subsidy: number }) => data.voucher_subsidy,
        },
        {
            label: 'Revenue D30 R80',
            render: (data: { revenue_d30_r80: number }) => `£${priceFormatter(data.revenue_d30_r80)}`,
            sortValue: (data: { revenue_d30_r80: number }) => data.revenue_d30_r80,
        },
        {
            label: 'GP in 1Y',
            render: (data: { gross_profit_pa: number }) => `£${priceFormatter(data.gross_profit_pa)}`,
            sortValue: (data: { gross_profit_pa: number }) => data.gross_profit_pa,
        },
        {
            label: 'GP Over 6Y Period',
            render: (data: { gross_profit_over_year_period: number }) => `£${priceFormatter(data.gross_profit_over_year_period)}`,
            sortValue: (data: { gross_profit_over_year_period: number }) => data.gross_profit_over_year_period,
        },
    ];

    const tabConfig = [
        {
            label: 'All Scenarios',
            key: 'All_Scenario',
            children:
                <>
                    {/* <TableHeader>
                        <CSVButton
                            data={[]}
                            preparedData={cvs}
                            filename='roi.csv'
                        />
                    </TableHeader> */}

                    <Table
                        config={config}
                        data={precalculatedSummaryData.sort((a: { roi_at_pen_rate_years: number; }, b: { roi_at_pen_rate_years: number; }) => a.roi_at_pen_rate_years - b.roi_at_pen_rate_years)}

                    />
                </>
        },
        {
            label: 'Selected Scenarios',
            key: 'Selected_Scenario',
            children:
                <>
                    {/* <TableHeader>
                        <CSVButton
                            data={[]}
                            preparedData={cvsProjects}
                            filename='roi.csv'
                        />
                    </TableHeader> */}
                    <Table
                        config={config}
                        data={selectedProjectData.sort((a: { roi_at_pen_rate_years: number; }, b: { roi_at_pen_rate_years: number; }) => a.roi_at_pen_rate_years - b.roi_at_pen_rate_years)}

                    />
                </>
        },
    ];

    return <>
        <div className={styles.chartBody}>
            <Tab config={tabConfig} defaultValue='All_Scenario' />
        </div>
    </>;
};