import { Input } from 'antd';
import { Button } from 'components/Button';
import React, { useState } from 'react';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { userAPI } from 'services/User';
import { Modal } from 'components/Modal';
import style from './style.module.scss';

interface Props {
    onClose: (props?: any) => void
}

export const CreateUserPopup: React.FC<Props> = ({ onClose }) => {
    const [createUser, { error: createUserError }] = userAPI.useCreateUserMutation();

    const [userData, setUserData] = useState<{
        username: string,
        email: string,
        password: string
    }>({
        username: '',
        email: '',
        password: '',
    });

    const handleChange = (fieldName: string, data: any) => {
        setUserData((prev) => ({
            ...prev,
            [fieldName]: data
        }));
    };

    const handleSaveCreate = async () => {
        try {
            await createUser({
                username: userData.username,
                email: userData.email,
                password: userData.password,
            });

            onClose(false);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error creating user:', error, createUserError);
        }
    };

    return (<Modal onClose={onClose} className={style.modal}>
        <h1
            className={style.title}>Add user</h1>

        <Input
            size="large"
            className={style.input}
            placeholder="Username"
            onChange={(e) => {
                handleChange('username', e.target.value);
            }} />
        <Input
            size="large"
            className={style.input}
            placeholder="Email"
            onChange={(e) => {
                handleChange('email', e.target.value);
            }} />
        <Input.Password
            size="large"
            className={style.input}
            placeholder="input password"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            onChange={(e) => {
                handleChange('password', e.target.value);
            }}
        />
        <Button onClick={handleSaveCreate} isWide
            style={{
                marginBottom: '15px'
            }}> Add new user</Button>
        <Button isWide
            onClick={() => {
                onClose(false);
                setUserData({
                    username: '',
                    email: '',
                    password: '',
                });
            }}> Cancel </Button>
    </Modal>);
};