import { Task } from 'models/Task';

export const getSunburstChart = (originalData: Task[]): {
    type: 'sunburst',
    branchvalues: 'total',
    labels: string[],
    parents: string[],
    values: number[],
    marker: any
}[] | void => {

    const groupBy = (items: any[], key: string | number) =>
        items.reduce(
            (result, item) => ({
                ...result,
                [item[key]]: [...(result[item[key]] || []), item],
            }),
            {}
        );
    const sumBy = (items: any, key: string | number) => items.reduce((a: any, b: any) => a + (b[key] || 0), 0);

    const data = originalData.map((v: any) => {
        const roleData = v.role_task.split('_')[0] === v.role_task.split('_').slice(1).join('_') ? `${v.role_task.split('_')[0]}-role` : v.role_task.split('_')[0];
        return {
            role: roleData,
            task: v.role_task.split('_').slice(1).join('_'),
            role_task: v.role_task,
            work_days: Math.round(v.work_days),
        };
    });

    const labels = [];
    const parents = [];
    const plotValues = [];

    const groupedRoles = groupBy(data, 'role');
    for (const [role, values] of Object.entries(groupedRoles)) {
        labels.push(role);
        parents.push('');
        plotValues.push(Math.round(sumBy(values, 'work_days')));
    }

    data.forEach((v: any) => {
        if (v.role !== 'reinstatement') {
            labels.push(v.task);
            parents.push(v.role);
            plotValues.push(Math.round(v.work_days));
        }
    });

    return [
        {
            type: 'sunburst',
            branchvalues: 'total',
            labels,
            parents,
            values: plotValues,
            marker: {
                line:
                {
                    width: 1,
                    color: '#000'
                }
            },
        }
    ];
};