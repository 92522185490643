import { Breadcrumb } from 'elements/Breadcrumb';
import styles from '../styles.module.scss';

export const Breadcrumbs = () => {

    return (
        <table className={styles.table}>
            <tr>
                <td>Breadcrumb - simple list</td>
                <td>
                    <Breadcrumb items={[
                        {
                            title: 'Home',
                        },
                        {
                            title: <a href="#">Application Center</a>,
                        },
                        {
                            title: <a href="#">Application List</a>,
                        },
                        {
                            title: 'An Application',
                        },
                        ]}
                    />
                </td>
            </tr>
            <tr>
                <td>Breadcrumb - with custom separator
                    <br />
                    <code>
                        separator= `{'>'}`
                    </code>
                </td>
                <td>
                    <Breadcrumb 
                        separator='>'
                        items={[
                            {
                                title: 'Home',
                            },
                            {
                                title: <a href="#">Application Center</a>,
                            },
                            {
                                title: <a href="#">Application List</a>,
                            },
                            {
                                title: 'An Application',
                            },
                        ]}
                    />
                </td>
            </tr>
            <tr>
                <td>Breadcrumb - with custom separator
                    <br />
                    <code>
                        separator= `{'<svg>...</svg>'}`
                    </code>
                </td>
                <td>
                    <Breadcrumb 
                    separator={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M10 4.16699V15.8337M4.16667 10.0003H15.8333" stroke="black" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>}
                    items={[
                        {
                            title: 'Home',
                        },
                        {
                            title: <a href="#">Application Center</a>,
                        },
                        {
                            title: <a href="#">Application List</a>,
                        },
                        {
                            title: 'An Application',
                        },
                        ]}
                    />
                </td>
            </tr>
        
        </table>);
};