import { ThemeSwitcher } from 'elements/ThemeSwitcher';
import { userAPI } from 'services/User';
import { useAuth } from 'hooks/useAuth';
import { HeaderPage } from 'elements/HeaderPage';
import { Skeleton } from 'elements/Skeleton';

export const Header = () => {
    const { setToken } = useAuth();
    const { data: currentUser, isLoading: getCurrentUserIsLoading } = userAPI.useGetCurrentUserQuery();

    if (getCurrentUserIsLoading) return <Skeleton />;

    return (<>
        <HeaderPage titleText={`Hello, ${currentUser?.username}!`}>
            <div style={{ display: 'flex', gap: 28 }}>
                <ThemeSwitcher />
                <button onClick={() => setToken!('')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
                        <path d="M52 25.997C52.0085 31.1594 50.4733 36.2064 47.5915 40.4893C45.215 44.0343 42.0017 46.939 38.2358 48.9466C34.47 50.9542 30.2675 52.0029 26 52C21.7325 52.0029 17.53 50.9542 13.7641 48.9466C9.99824 46.939 6.78497 44.0343 4.40844 40.4893C2.14566 37.1159 0.704607 33.2592 0.200795 29.2284C-0.303017 25.1976 0.144431 21.1048 1.50727 17.2781C2.87011 13.4514 5.11043 9.99731 8.04863 7.19263C10.9868 4.38795 14.5412 2.31075 18.4268 1.12753C22.3124 -0.0556931 26.4211 -0.312011 30.4236 0.37912C34.4261 1.07025 38.211 2.68961 41.4749 5.10737C44.7388 7.52514 47.391 10.6741 49.2187 14.3017C51.0465 17.9293 51.999 21.9348 52 25.997Z" fill="#0E1665" />
                        <path d="M26.5206 26.102C25.4228 26.102 24.3497 25.7765 23.437 25.1665C22.5242 24.5566 21.8128 23.6896 21.3927 22.6753C20.9726 21.661 20.8627 20.5449 21.0769 19.4681C21.291 18.3913 21.8197 17.4022 22.5959 16.6259C23.3721 15.8495 24.3611 15.3209 25.4378 15.1067C26.5144 14.8925 27.6304 15.0024 28.6446 15.4225C29.6588 15.8427 30.5257 16.5542 31.1355 17.467C31.7454 18.3799 32.071 19.4531 32.071 20.551C32.071 22.0232 31.4862 23.4352 30.4453 24.4762C29.4044 25.5172 27.9926 26.102 26.5206 26.102ZM26.5206 17.2204C25.8619 17.2204 25.2181 17.4157 24.6704 17.7817C24.1228 18.1477 23.6959 18.6679 23.4439 19.2764C23.1918 19.885 23.1259 20.5547 23.2544 21.2008C23.3829 21.8469 23.7 22.4403 24.1658 22.9061C24.6315 23.3719 25.2249 23.6891 25.8709 23.8176C26.5169 23.9461 27.1865 23.8802 27.795 23.6281C28.4035 23.376 28.9236 22.9491 29.2896 22.4014C29.6555 21.8537 29.8508 21.2097 29.8508 20.551C29.8508 19.6677 29.4999 18.8205 28.8754 18.1959C28.2509 17.5713 27.4038 17.2204 26.5206 17.2204ZM36.8813 36.4639C36.5881 36.4601 36.3079 36.3419 36.1006 36.1345C35.8932 35.9271 35.775 35.647 35.7712 35.3537C35.7712 32.4672 34.2023 30.5428 26.5206 30.5428C18.8389 30.5428 17.27 32.4672 17.27 35.3537C17.27 35.6482 17.153 35.9305 16.9448 36.1387C16.7367 36.3469 16.4543 36.4639 16.1599 36.4639C15.8655 36.4639 15.5831 36.3469 15.375 36.1387C15.1668 35.9305 15.0498 35.6482 15.0498 35.3537C15.0498 28.3224 23.0868 28.3224 26.5206 28.3224C29.9544 28.3224 37.9913 28.3224 37.9913 35.3537C37.9875 35.647 37.8693 35.9271 37.662 36.1345C37.4546 36.3419 37.1745 36.4601 36.8813 36.4639Z" fill="white" />
                    </svg>
                </button>
            </div>
        </HeaderPage>
    </>);
};