import { Placeholder } from 'components/Placeholder';
import { Tab } from 'components/Tab';
import { TableHeader } from 'components/TableHeader';
import { SearchBox } from 'elements/SearchBox';
import { Table } from 'elements/Table';
import { PossibleUrlQuery, useQueryParams } from 'hooks/useQueryParams';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { projectReportAPI } from 'services/Project';
import { projectSummaryReportAPI } from 'services/ProjectSummary';
import { scenarioReportAPI } from 'services/Scenarios';
import { addAreaId, addProjectsId, addScenarioId } from 'store/reducers/dashboardSlice';
import { numberFormatter, priceFormatter } from 'utils/priceFormatter';
import { Charts } from './Charts';
import styles from './ProjectsTable.module.scss';

export const ProjectsTable: React.FC = () => {
    const params = useParams();

    const dispatch = useDispatch();

    // queries for main request
    const { queries } = useQueryParams([
        PossibleUrlQuery.scenario_uuids,
        PossibleUrlQuery.aggregate_projects
    ], {
        [PossibleUrlQuery.aggregate_projects]: false
    });

    // main request
    const {
        data: projectSummaryData = []
    } = projectSummaryReportAPI.useGetProjectReportQuery({ params: queries }, {
        skip: !queries
    });

    const { data: projectReportData = [] } = projectReportAPI.useGetProjectReportQuery();
    const { data: scenarioReportData = [], isLoading: isScenarioReportDataLoading } = scenarioReportAPI.useGetScenarioReportQuery();

    const [searchValue, setSearchValue] = useState<string>();

    useEffect(() => {
        const scenario_uuid = params.param1;
        if (scenarioReportData.length) {
            const area_uuid = scenarioReportData.find((el) => el.uuid === scenario_uuid)?.area_uuid;
            dispatch(addAreaId(area_uuid));
        }
        dispatch(addScenarioId(scenario_uuid));

    }, [isScenarioReportDataLoading]);

    const [controlledActive, setControlledActive] = useState<null | string>(null);
    // config for table
    const config = [
        // {
        //     label: 'Project Name',
        //     render: (data: { [key: string]: string | number }) => data.project_uuid || <Placeholder />,
        //     sortValue: (data: { [key: string]: string | number }) => data.project_uuid,
        // },
        {
            label: 'Project name',
            render: (data: { [key: string]: string | number }) => data.project_name,
            sortValue: (data: { [key: string]: string | number }) => data.project_name,
        },
        {
            label: 'Total CAPEX',
            render: (data: { [key: string]: number }) => data.total_capex ? `£${priceFormatter(data.total_capex)}` : <Placeholder />,
            sortValue: (data: { [key: string]: number }) => data.total_capex,
        },
        {
            label: 'Total OPEX',
            render: (data: { [key: string]: number }) => data.total_opex_per_annum ? `£${priceFormatter(data.total_opex_per_annum)}` : <Placeholder />,
            sortValue: (data: { [key: string]: number }) => data.total_opex_per_annum,
        },
        {
            label: 'UPRN',
            render: (data: { [key: string]: number }) => numberFormatter(data.property_count) || <Placeholder />,
            sortValue: (data: { [key: string]: string | number }) => data.property_count,
        },
    ];

    const projectSummaryWithNames = projectSummaryData.map((el) => ({
        ...el,
        id: el.project_uuid,
        project_name: projectReportData.find((item) => item.uuid === el.project_uuid)!.name
    }));

    // config for tabs
    const tabsConfig = [
        {
            label: {
                icon: <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
                    <path d='M2.5 7.5L17.5 7.5M7.5 2.5L7.5 17.5M6.5 2.5H13.5C14.9001 2.5 15.6002 2.5 16.135 2.77248C16.6054 3.01217 16.9878 3.39462 17.2275 3.86502C17.5 4.3998 17.5 5.09987 17.5 6.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V6.5C2.5 5.09987 2.5 4.3998 2.77248 3.86502C3.01217 3.39462 3.39462 3.01217 3.86502 2.77248C4.3998 2.5 5.09987 2.5 6.5 2.5Z' stroke='#FAFAFA' strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
                </svg>,
                text: 'Table'
            },
            key: '1',
            onClick: () => {
                localStorage.removeItem('chart');
                const customEvent = new CustomEvent('DROPDOWN', {
                    detail: ''
                });
                window.dispatchEvent(customEvent);
                setControlledActive(null);
            },
            children: <>
                <TableHeader
                    title='Helicopter View'
                    subtitle='This is an overview of the various scenarios. Please click on a specific scenario or metric to delve deeper into their differences.'>

                    <SearchBox onChange={(e: any) => setSearchValue(e.target.value)} value={searchValue} />
                    {/* <CSVLink data={cvs || 'No Data'} filename='helicopter-view-data.csv'>
                        <Button
                            icon={<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
                                <path d='M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5' stroke='#F5F5F5' strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
                            </svg>}>
                            <>
                                Download
                            </>
                        </Button>
                    </CSVLink> */}
                </TableHeader>
                <Table
                    data={projectSummaryWithNames}
                    config={config}
                    rowClickHandler={(id: string) => {
                        dispatch(addProjectsId(id));
                        setControlledActive('2');
                        localStorage.setItem('chart', '1');
                        const customEvent = new CustomEvent('DROPDOWN', {
                            detail: '1'
                        });
                        window.dispatchEvent(customEvent);
                    }}
                />
            </>,
        },
        {
            label: {
                icon: <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
                    <path d='M17.5 7.5L12.1262 11.3384C11.963 11.455 11.8813 11.5133 11.7939 11.5309C11.7168 11.5464 11.637 11.5398 11.5635 11.5119C11.4801 11.4801 11.4092 11.4092 11.2673 11.2673L8.73267 8.73267C8.5908 8.5908 8.51986 8.51986 8.43652 8.48814C8.36305 8.46017 8.28317 8.45356 8.2061 8.46907C8.11868 8.48666 8.03704 8.54497 7.87378 8.66159L2.5 12.5M6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V6.5C17.5 5.09987 17.5 4.3998 17.2275 3.86502C16.9878 3.39462 16.6054 3.01217 16.135 2.77248C15.6002 2.5 14.9001 2.5 13.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5Z' stroke='#D6D6D6' strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
                </svg>,
                text: 'Chart'
            },
            key: '2',
            onClick: () => {
                localStorage.setItem('chart', '1');
                const customEvent = new CustomEvent('DROPDOWN', {
                    detail: '1'
                });
                window.dispatchEvent(customEvent);
            },
            children: <>
                <Charts />
            </>,
        },
    ];

    return <div data-testid='HelicopterView' className={styles.projects}>
        <div className={styles.projectsContainer}>
            <Tab config={tabsConfig} defaultValue='1' controlledActive={controlledActive} />
        </div>
    </div>;
};