import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { projectReportAPI } from 'services/Project';
import { scenarioReportAPI } from 'services/Scenarios';
import { selectDashboard } from 'store/reducers/dashboardSlice';

export const useChartDataParams = (isScenario: boolean, selectedProjectId?: string) => {
    const { data: scenarioReportData = [], isFetching: scenarioReportLoading } =
        scenarioReportAPI.useGetScenarioReportQuery();
    const { data: projectReportData = [], isFetching: projectReportLoading } =
        projectReportAPI.useGetProjectReportQuery();

    const [data, setData] = useState<any>();
    const dashboardSelectData = useSelector(selectDashboard);

    useEffect(() => {
        if (scenarioReportData.length && projectReportData.length && dashboardSelectData.scenario_uuids) {
            const data: {
                scenario: {
                    value: string;
                    label: string;
                } | null;
                project: {
                    value: string;
                    label: string;
                } | null;
            } = {
                scenario: isScenario
                    ? {
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          value: scenarioReportData.find(el => el.uuid === dashboardSelectData?.scenario_uuids)!.uuid,
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          label: scenarioReportData.find(el => el.uuid === dashboardSelectData?.scenario_uuids)!.name,
                      }
                    : null,
                project: null,
            };

            if (selectedProjectId) {
                data.project = {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    value: projectReportData.find(el => el.uuid === selectedProjectId)!.uuid,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    label: projectReportData.find(el => el.uuid === selectedProjectId)!.name,
                };
            }

            if (!isScenario && dashboardSelectData?.project_uuids) {
                data.project = {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    value: projectReportData.find(el => el.uuid === dashboardSelectData?.project_uuids)!.uuid,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    label: projectReportData.find(el => el.uuid === dashboardSelectData?.project_uuids)!.name,
                };
            }

            setData(data);
        }
    }, [scenarioReportLoading, projectReportLoading, dashboardSelectData, selectedProjectId]);

    return {
        data,
    };
};
