import { useTheme } from 'app/providers/ThemeProvider';
import classNames from 'classnames';
import { Navigation } from 'components/Navigation';
import { Outlet } from 'react-router-dom';

export const GlobalLayout = () => {
    const { theme } = useTheme();

    return (
        <div
            data-testid='App'
            className={classNames('app-layout', theme,)}
        >
            <Navigation />
            <main className={classNames('pageContent')}>
                <Outlet />
            </main>
        </div>
    );
};