import { createSlice } from '@reduxjs/toolkit';

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState:{
        area_uuids: null,
        scenario_uuids: null,
        project_uuids: null,
    },
    reducers: {
        addAreaId: (state, action) => {
            state.area_uuids = action.payload;
            state.scenario_uuids = null;
            state.project_uuids = null;
        },
        addScenarioId: (state, action) => {
            state.scenario_uuids = action.payload;
            state.project_uuids = null;
        },
        addProjectsId: (state, action) => {
            state.project_uuids = action.payload;
        },
    }
});

export const selectDashboard = (state: any) => state.dashboard;

export const { addAreaId, addScenarioId, addProjectsId } = dashboardSlice.actions;

export default dashboardSlice.reducer;
