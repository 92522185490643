import { nanoid } from '@reduxjs/toolkit';
import { TableType } from 'components/Charts/types/TableType';
import { Error } from 'components/Error';
import { useChartDataParams } from 'hooks/useChartDataParams';
import { useComparisonRequestData } from 'hooks/useComparisonRequestData';
import { PossibleUrlQuery, useQueryParams } from 'hooks/useQueryParams';
import { Capex } from 'models/CapexOpex';
import { capexAPI } from 'services/Costs';
import styles from '../BillOfMaterials.module.scss';
import { DataTable } from '../DataTable';

interface Props {
    isScenario?: boolean;
    selectedProject: string
}

export const CapexPoint: React.FC<Props> = ({ isScenario = true, selectedProject }) => {

    const listOfQueries = isScenario
        ? [PossibleUrlQuery.scenario_uuids, PossibleUrlQuery.geom_type]
        : [PossibleUrlQuery.project_uuids, PossibleUrlQuery.geom_type];
    const objWithParams = isScenario ? {
        [PossibleUrlQuery.geom_type]: [TableType.POINT]
    } : {
        [PossibleUrlQuery.project_uuids]: selectedProject,
        [PossibleUrlQuery.geom_type]: [TableType.POINT]
    };

    // queries for main request
    const { queries } = useQueryParams(listOfQueries, objWithParams);

    // main request
    const {
        data: capexPointData = [],
        isFetching: capexPointDataLoading,
        error: capexPointError
    } = capexAPI.useGetCapexQuery({ params: queries }, {
        skip: !queries
    });

    // request for comparison mode
    const {
        comparisonReport: comparisoncapexPointData,
        isFetching: comparisoncapexPointDataLoading,
        error: comparisoncapexPointError
    } = useComparisonRequestData('/costs/capex', isScenario, objWithParams);

    const { data: chartDataParams } = useChartDataParams(isScenario, selectedProject);

    const isLoadingState = capexPointDataLoading || comparisoncapexPointDataLoading;
    const isErrorState = capexPointError || comparisoncapexPointError;

    if (isErrorState) {
        return <div className={styles.center}>
            <Error text='Something went wrong. Please try again later or click the button.' />
        </div>;
    }

    // main data object structure 
    // need this for possibility of compare data
    const chartData: {
        id: string,
        response: Capex[],
        scenario: { value: string, label: string } | null,
        project: { value: string, label: string } | null,
    }[] = [
            {
                id: nanoid(),
                ...chartDataParams,
                response: capexPointData,
            },
            ...comparisoncapexPointData
        ];

    const preparedTableData = chartData.map((el: any) => {
        return el.response.map((item: any) => {
            if (isScenario) {
                return { ...item, name: el.scenario.label };

            } else return { ...item, name: el.project.label };
        });
    }).flat(1);

    return (<>
        <DataTable
            data={preparedTableData}
            isLoading={isLoadingState}
            error={isErrorState}
            type={TableType.POINT}
        />
    </>);
};