import { defaultStyles } from 'components/Map/styles/defaultStyles';
import { stylesAPI } from 'services/Styles';

export const useMapStyles = () => {
    const {
        data: stylesData = [],
        error: getStylesError,
        isLoading: getStylesIsLoading,
    } = stylesAPI.useGetStylesQuery();

    if (getStylesError) {
        return {
            stylesData: defaultStyles,
            error: true,
            isLoading: false,
        };
    }

    const styledObj = [];

    if (stylesData.length < 8) {
        for (let i = 0; i < defaultStyles.length; i++) {
            if (!stylesData.find(el => defaultStyles[i].layer === el.layer)) {
                styledObj.push(defaultStyles[i]);
            }
        }
        styledObj.push(...stylesData.map(el => el));
    } else {
        styledObj.push(...stylesData.map(el => el));
    }

    return {
        stylesData: styledObj,
        error: getStylesError,
        isLoading: getStylesIsLoading,
    };
};
