import mapboxgl from 'mapbox-gl';

export const unselectLine = (map: mapboxgl.Map) => {
    if (map.getSource('selectedLine')) {
        map.removeLayer('selectedLineLayer');
        map.removeSource('selectedLine');
    }

    if (map.getSource('selectedLineParent')) {
        map.removeLayer('selectedLineLayerParent');
        map.removeSource('selectedLineParent');
    }
};