export const generateHeatmapArray = () => {
    const numSteps = 20;
    const stepSize = 5000 / numSteps;
    const heatmapArray = [];

    for (let i = 0; i < numSteps; i++) {
        const value = (i * stepSize);
        let red, green;
        if (i < numSteps / 2) {
            red = Math.floor((255 * i) / (numSteps / 2));
            green = 255;
        } else {
            red = 255;
            green = 255 - Math.floor((255 * (i - (numSteps / 2))) / (numSteps / 2));
        }
        const color = `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}00`;
        heatmapArray.push([value, color]);
    }

    return heatmapArray;
};