import mapboxgl from 'mapbox-gl';
import { dimPoint } from './dimPoint';

export const highlightPoint = (map: mapboxgl.Map, pointFeatures: mapboxgl.MapboxGeoJSONFeature[], parentPointFeature?: mapboxgl.MapboxGeoJSONFeature[]) => {
    dimPoint(map);

    map.addSource('hoveredPoint', {
        'type': 'geojson',
        'data': {
            'type': 'FeatureCollection',
            'features': pointFeatures
        }
    });

    map.addLayer(
        {
            'id': 'higlightedPointLayer',
            'type': 'circle',
            'source': 'hoveredPoint',
            'paint': {
                'circle-radius': 18,
                'circle-opacity': 0,
                'circle-stroke-width': 3,
                'circle-stroke-color': 'yellow'
            }
        },
    );
    if (parentPointFeature && parentPointFeature.length !== 0) {
        map.addSource('hoveredPointParent', {
            'type': 'geojson',
            'data': {
                'type': 'FeatureCollection',
                'features': parentPointFeature
            }
        });

        map.addLayer(
            {
                'id': 'higlightedPointLayerParent',
                'type': 'circle',
                'source': 'hoveredPointParent',
                'paint': {
                    'circle-radius': 25,
                    'circle-opacity': 0,
                    'circle-stroke-width': 3,
                    'circle-stroke-color': 'green'
                }
            },
        );
    }
};