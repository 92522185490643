export const getArrayOfUniqueValues = (value: any[]) => {
    return Array.from(new Set(value));
};

export const getArrayOfUniqueObjectsById = (value: any[]) => {
    const array: any = [];

    for (const obj of value) {
        const find = array.find((el: any) => {
            return el.id === obj.id;
        });

        if (!find) {
            array.push(obj);
        }
    }

    return array;
};