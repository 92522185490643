import { nanoid } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { Error } from 'components/Error';
import { Chart } from 'elements/Chart';
import { Select } from 'elements/Select';
import { Skeleton } from 'elements/Skeleton';
import { useChartDataParams } from 'hooks/useChartDataParams';
import { useComparisonRequestData } from 'hooks/useComparisonRequestData';
import { PossibleUrlQuery, useQueryParams } from 'hooks/useQueryParams';
import { Materials as MaterialsType } from 'models/Materials';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { materialsAPI } from 'services/Materials';
import { projectReportAPI } from 'services/Project';
import { selectDashboard } from 'store/reducers/dashboardSlice';
import { getArrayOfUniqueValues } from 'utils/arrayOfUniqueValues';
import styles from '../style.module.scss';

interface Props {
    isScenario?: boolean;
}

export const Materials: React.FC<Props> = ({ isScenario = true }) => {
    const { data: projectReportData = [] } = projectReportAPI.useGetProjectReportQuery();

    const { scenario_uuids, project_uuids } = useSelector(selectDashboard);

    const [selectedData, setSelectedData] = useState<string>(project_uuids === null ? '' : project_uuids);

    const projectsOptions: { value: string; label: string; }[] = projectReportData.filter((el) => scenario_uuids === el.scenario_uuid).map((element) => {
        return {
            value: element.uuid,
            label: element.name
        };
    });

    const listOfQueries = isScenario ? [PossibleUrlQuery.scenario_uuids] : [PossibleUrlQuery.project_uuids];
    const objWithParams = isScenario ? {} : { [PossibleUrlQuery.project_uuids]: selectedData };

    // queries for main request
    const { queries } = useQueryParams(listOfQueries, objWithParams);

    // main request
    const {
        data: materialsData = [],
        isFetching: materialsDataLoading,
        error: materialsError
    } = materialsAPI.useGetMaterialsQuery({ params: queries }, {
        skip: !queries
    });

    // request for comparison mode
    const {
        comparisonReport: comparisonMaterialsData,
        isFetching: comparisonMaterialsDataLoading,
        error: comparisonMaterialsError
    } = useComparisonRequestData('/materials', isScenario, objWithParams);

    const { data: chartDataParams } = useChartDataParams(isScenario, selectedData);

    const isLoadingState = materialsDataLoading || comparisonMaterialsDataLoading;
    const isErrorState = materialsError || comparisonMaterialsError;

    if (isErrorState) {
        return <div className={styles.center}>
            <Error text='Something went wrong. Please try again later or click the button.' />
        </div>;
    }

    const chartData: {
        id: string,
        response: MaterialsType[],
        scenario: { value: number, label: string } | null,
        project: { value: number, label: string } | null,
    }[] = [
            {
                id: nanoid(),
                ...chartDataParams,
                response: materialsData,
            },
            ...comparisonMaterialsData
        ];

    const result = chartData.map((el) => {
        const nonZero = el.response.filter((i) => i.length > 0);
        return {
            ...el,
            response: nonZero
        };
    }).map((el) => {
        return {
            'x': el.response.map((i) => i.day),
            'y': el.response.map((i) => i.length),
            name: `${isScenario ? el.scenario?.label : el.project?.label}`,
            'type': 'line',
            hovertemplate: '%{fullData.name}: %{y}<extra></extra>',
            'transforms': [
                {
                    'type': 'groupby',
                    'groups': el.response.map((i) => i.material_type),
                    'styles': getArrayOfUniqueValues(el.response.map((i) => i.material_type)).map((el) => {
                        return {
                            'target': el,
                        };
                    })
                },
                {
                    'type': 'sort',
                    'target': el.response.map((i) => i.day),
                    'order': 'ascending'
                },
                {
                    'type': 'aggregate',
                    'groups': el.response.map((i) => i.day),
                    'aggregations': [
                        {
                            'target': 'y',
                            'func': 'sum',
                            'enabled': true
                        }
                    ]
                }
            ],
            'mode': 'lines+markers'
        };

    });

    return <>

        <div className={styles.chartBody}>
            <div style={{ minHeight: '500px' }}>
                {
                    isLoadingState && <Skeleton.ChartSkeleton />
                }
                {!isLoadingState && <>
                    {/* <div className={styles.download}>
                        <CSVButton
                            data={chartData}
                            filename='cumulative-materials.csv'
                            arrOfProps={['day', 'material_type', 'length', 'count']}
                        />
                    </div> */}
                    {!isScenario && <div className={classNames(styles.sliderBlock, styles.variableBlock)}>
                        <p className={styles.sliderBlockTitle}>Select project: </p>
                        <div className={classNames(styles.inputWrapper, styles.inputWrapperTransparent)}>
                            <Select
                                data={projectsOptions}
                                value={selectedData}
                                onChange={setSelectedData}
                                width={{ width: '600px' }}
                            />
                        </div>
                    </div>}
                    <Chart plotData={result}
                        y='Kilometers'
                        title='Cumulative Materials'
                        layout={{
                            'hoverlabel': {
                                'bgcolor': '#000',
                                'bordercolor': '#000',
                                'font': {
                                    'color': '#fff',
                                    'family': 'var(--default-font, var(--sans-serif))',
                                    'size': 13
                                }
                            },
                        }} />
                </>
                }
            </div>
        </div>
    </>;
};