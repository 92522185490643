import { Select } from 'elements/Select';
import styles from '../Table.module.scss';
import { numberOfEntries } from '../utils/numberOfEntries';
import { PrevIcon } from './Icons/PrevIcon';
import { NextIcon } from './Icons/NextIcon';

export const TableFooter = ({
    dataLength,
    selectedCount,
    setSelectedCount,
    currentPage,
    setCurrentPage
}: any) => {
    const countOfPages = Math.ceil(dataLength / selectedCount);

    const firstPageIndex = (currentPage - 1) * selectedCount + 1;
    const lastPageIndex = firstPageIndex + selectedCount - 1;

    return (<div className={styles.footer}>
        <div className={styles.paginationSettings}>
            Number of entries:

            <Select
                isSearch={false}
                data={numberOfEntries}
                value={numberOfEntries.find((el) => el.value === selectedCount)}
                onChange={setSelectedCount}
                width={
                    {
                        width: '75px',
                        minWidth: '75px',
                    }
                } />
        </div>

        <div className={styles.pagesSettings}>
            <span className={styles.paginationText}>
                {dataLength === 0 ? '0  -  0' : `${firstPageIndex}  -  ${lastPageIndex}`} of {countOfPages}
            </span>
            <div className={styles.buttons}>
                <PrevIcon
                    onClick={(currentPage !== 1) ? () => setCurrentPage(currentPage - 1) : () => { }}
                    disabled={currentPage !== 1}
                />
                <NextIcon onClick={(currentPage !== countOfPages && countOfPages !== 0) ? () => setCurrentPage(currentPage + 1) : () => { }}
                    disabled={currentPage !== countOfPages && countOfPages !== 0}
                />
            </div>
        </div>
    </div>);
};