import { Chart } from 'elements/Chart';
import { Select } from 'elements/Select';
import { Skeleton } from 'elements/Skeleton';
import React from 'react';

interface Props {
    plotData: any;
    options: any;
    value: any;
    onChange: (prop: any) => void;
    isScenario: boolean;
    isLoading?: boolean;
}
export const ChartConfig: React.FC<Props> = ({ plotData, options, value, onChange, isScenario, isLoading }) => {
    return <>
        {!isScenario && <Select
            label="Select project:"
            data={options}
            value={value}
            onChange={(value) => onChange(value)}
            width={{ width: '600px' }}
        />}
        {isLoading && <Skeleton.ChartSkeleton />}
        {!isLoading &&
            <Chart plotData={plotData}
                legend={{
                    x: 0,
                    y: 1.2,
                }}
                shapes={
                    [
                        {
                            line: { color: 'green', width: 2 },
                            type: 'line',
                            x0: 0,
                            x1: 1, xref: 'x domain', y0: 1250, y1: 1250, yref: 'y'
                        },
                        {
                            line: { color: 'red', width: 2 },
                            type: 'line',
                            x0: 0,
                            x1: 1, xref: 'x domain', y0: 1800, y1: 1800, yref: 'y'
                        },
                    ]
                }
            />
        }

    </>;
};