import { nanoid } from '@reduxjs/toolkit';
import { Error } from 'components/Error';
import { Chart } from 'elements/Chart';
import { Select } from 'elements/Select';
import { Skeleton } from 'elements/Skeleton';
import { useChartDataParams } from 'hooks/useChartDataParams';
import { useComparisonRequestData } from 'hooks/useComparisonRequestData';
import { PossibleUrlQuery, useQueryParams } from 'hooks/useQueryParams';
import { PruneResult } from 'models/ProjectPruning';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { projectReportAPI } from 'services/Project';
import { pruningAPI } from 'services/PruneResults';
import { selectDashboard } from 'store/reducers/dashboardSlice';
import { pruningPlotData } from 'utils/pruningPlotData/pruningPlotData';
import styles from '../style.module.scss';

export const Pruning: React.FC = () => {
    // queries for main request
    const { queries } = useQueryParams([
        PossibleUrlQuery.scenario_uuids,
        PossibleUrlQuery.aggregate_projects
    ], {
        [PossibleUrlQuery.aggregate_projects]: false
    });

    // main request
    const {
        data: pruningData = [],
        isFetching: pruningDataLoading,
        error: pruningDataError
    } = pruningAPI.useGetPruningQuery({ params: queries }, {
        skip: !queries
    });

    // request for comparison mode
    const {
        comparisonReport: comparisonPruningData,
        isFetching: comparisonPruningDataLoading,
        error: comparisonPruningError
    } = useComparisonRequestData('/prune-results', false,
        {
            [PossibleUrlQuery.aggregate_projects]: false
        }
    );

    const { data: projectReportData = [] } = projectReportAPI.useGetProjectReportQuery();

    const { scenario_uuids, project_uuids } = useSelector(selectDashboard);

    const [selectedData, setSelectedData] = useState<string>();

    const projectsOptions: { value: string; label: string; }[] = projectReportData.filter((el) => scenario_uuids === el.scenario_uuid).map((element) => {
        return {
            value: element.uuid,
            label: element.name
        };
    });

    useEffect(() => {
        if (scenario_uuids && !project_uuids) {
            setSelectedData(projectsOptions[0].value);
        }

        if (project_uuids) {
            setSelectedData(project_uuids);
        }
    }, [scenario_uuids, project_uuids]);

    const { data: chartDataParams } = useChartDataParams(false, selectedData); // think!!!!    

    // for first project
    const selectedPruneData = pruningData.filter((el) => el.project_uuid === selectedData);

    const isLoadingState = pruningDataLoading || comparisonPruningDataLoading;
    const isErrorState = pruningDataError || comparisonPruningError;

    if (isErrorState) {
        return <div className={styles.center}>
            <Error text='Something went wrong. Please try again later or click the button.' />
        </div>;
    }

    // main data object structure 
    // need this for possibility of compare data
    const chartData: {
        id: string,
        response: PruneResult[],
        scenario: { value: string, label: string } | null,
        project: { value: string, label: string } | null,
    }[] = [
            {
                id: nanoid(),
                ...chartDataParams,
                response: selectedPruneData,
            },
            ...comparisonPruningData
        ];

    const chartDataConfigured = chartData.map((el) => {
        return {
            ...el,
            response: pruningPlotData(el.response).data,
            line: pruningPlotData(el.response).uprnRoiRevMaxValue

        };
    });
    const data = chartDataConfigured.map((el) => {
        return {
            ...el,
            project_name: projectReportData.find((item) => item.uuid === el.project?.value)?.name,
            roi: el.response.map((item) => item.roi),
            property_count: el.response.map((item) => item.property_count),
            revenue: el.response.map((item) => item.revenue),
            total_capex: el.response.map((item) => item.total_capex),
            total_opex: el.response.map((item) => item.total_opex),
            cppp: el.response.map((item) => item.cppp)
        };
    });

    const shapes: {
        type: 'path' | 'line' | 'rect' | 'circle' | undefined;
        x0?: number,
        y0?: number,
        x1?: number,
        y1?: number,
        xref?: any,
        yref?: any,
        line: {
            color: string,
            width?: number
        }
    }[] = data.map((el) => {
        return {
            type: 'line',
            x0: el.line ? el.line : 0,
            x1: el.line ? el.line : 0,
            xref: 'x',
            y0: 0,
            y1: 1,
            yref: 'y domain',
            line: {
                color: 'rgb(0, 182, 0)',
                width: 2,
            }
        };
    });

    const roiPlot = data.map((el) => {
        return {
            x: el.property_count,
            y: el.roi,
            name: `${el.project?.label} - roi_norm`,
            type: 'line',
            transforms: [
                {
                    type: 'sort',
                    target: el.property_count,
                    order: 'ascending'
                }
            ],
            mode: 'lines+markers',
        };
    });
    const revenuePlot = data.map((el) => {
        return {
            x: el.property_count,
            y: el.revenue,
            name: `${el.project?.label} - revenue_pa`,
            yaxis: 'y2',
            type: 'line',
            transforms: [
                {
                    type: 'sort',
                    target: el.property_count,
                    order: 'ascending'
                }
            ],
            mode: 'lines+markers',
        };
    });

    const plotDataROI = [...roiPlot, ...revenuePlot];
    const total_capexPlot = data.map((el) => {
        return {
            x: el.property_count,
            y: el.total_capex,
            name: `${el.project?.label} - roi_norm`,
            type: 'line',
            transforms: [
                {
                    type: 'sort',
                    target: el.property_count,
                    order: 'ascending'
                }
            ],
            mode: 'lines+markers',
        };
    });
    const total_opexPlot = data.map((el) => {
        return {
            x: el.property_count,
            y: el.total_opex,
            name: `${el.project?.label} - revenue_pa`,
            yaxis: 'y2',
            type: 'line',
            transforms: [
                {
                    type: 'sort',
                    target: el.property_count,
                    order: 'ascending'
                }
            ],
            mode: 'lines+markers',
        };
    });

    const plotDataCapexOpex = [...total_capexPlot, ...total_opexPlot];

    const cpppPlot = data.map((el) => {
        return {
            x: el.property_count,
            y: el.cppp,
            name: `${el.project?.label} - cppp`,
            type: 'line',
            transforms: [
                {
                    type: 'sort',
                    target: el.property_count,
                    order: 'ascending'
                }
            ],
            mode: 'lines+markers',
        };
    });

    return <>
        <div className={styles.chartBody}>
            <div style={{ minHeight: '500px' }}>
                <>
                    <Select
                        data={projectsOptions}
                        value={projectsOptions.find((el) => el.value === selectedData)}
                        onChange={(number) => setSelectedData(number)}
                        label='Select project:'
                    />

                    {
                        isLoadingState && <Skeleton.ChartSkeleton />
                    }
                    {!isLoadingState && <>
                        <div style={{ position: 'relative' }}>
                            {/* <div className={styles.download}>
                        <CSVButton
                            data={chartDataConfigured}
                            filename='ROI_revenue_pa.csv'
                            arrOfProps={['property_count', 'roi', 'revenue']}
                        />
                    </div> */}
                            <Chart plotData={plotDataROI}
                                y='ROI in Years'
                                x='UPRN Count'
                                title='ROI vs Revenue P/A'
                                layout={{
                                    yaxis2: {
                                        title: 'Revenue P/A',
                                        overlaying: 'y',
                                        side: 'right',
                                        showline: false,
                                        linecolor: '#424242',
                                        linewidth: 1,
                                        showgrid: true,
                                        gridwidth: 1,
                                        family: 'Inter, sans-serif',
                                        size: 12,
                                        color: '#E5E5E5',
                                        gridcolor: '#424242',
                                        zerolinecolor: '#424242',
                                        titlefont: {

                                            family: 'Inter, sans-serif',
                                            size: 12,
                                            color: '#E5E5E5',
                                        }
                                    },
                                    'hovermode': 'x unified',
                                    'hoverlabel': {
                                        'bgcolor': '#000',
                                        'bordercolor': '#000',
                                        'font': {
                                            'color': '#fff',
                                            'family': 'var(--default-font, var(--sans-serif))',
                                            'size': 13
                                        }
                                    },
                                    // 'clickmode': 'select+event',
                                    // 'dragmode': 'select',
                                }}
                                shapes={shapes} />
                        </div>
                        <div style={{ position: 'relative' }}>
                            {/* <div className={styles.download}>
                            <CSVButton
                                data={chartDataConfigured}
                                filename='CAPEX_OPEX.csv'
                                arrOfProps={['property_count', 'total_capex', 'total_opex']}
                            />
                        </div> */}
                            <Chart plotData={plotDataCapexOpex}
                                y='Total CAPEX'
                                x='UPRN Count'
                                title='CAPEX vs OPEX'
                                layout={{
                                    yaxis2: {
                                        title: 'Total OPEX',
                                        overlaying: 'y',
                                        side: 'right',
                                        showline: false,
                                        linecolor: '#424242',
                                        linewidth: 1,
                                        showgrid: true,
                                        gridcolor: '#424242',
                                        gridwidth: 1,
                                        family: 'Inter, sans-serif',
                                        size: 12,
                                        color: '#E5E5E5',
                                        zerolinecolor: '#424242',
                                        titlefont: {

                                            family: 'Inter, sans-serif',
                                            size: 12,
                                            color: '#E5E5E5',
                                        }
                                    },

                                    'hovermode': 'x unified',
                                    'hoverlabel': {
                                        'bgcolor': '#000',
                                        'bordercolor': '#000',
                                        'font': {
                                            'color': '#fff',
                                            'family': 'var(--default-font, var(--sans-serif))',
                                            'size': 13
                                        }
                                    },
                                    // 'clickmode': 'select+event',
                                    // 'dragmode': 'select',

                                }}
                                shapes={shapes} />
                        </div>
                        <div style={{ position: 'relative' }}>
                            {/* <div className={styles.download}>
                            <CSVButton
                                data={chartDataConfigured}
                                filename='CPPP.csv'
                                arrOfProps={['property_count', 'cppp']}
                            />
                        </div> */}
                            <Chart plotData={cpppPlot}
                                y='CPPP'
                                x='UPRN Count'
                                title='CPPP'
                                layout={
                                    {
                                        'hovermode': 'x unified',
                                        'hoverlabel': {
                                            'bgcolor': '#000',
                                            'bordercolor': '#000',
                                            'font': {
                                                'color': '#fff',
                                                'family': 'var(--default-font, var(--sans-serif))',
                                                'size': 13
                                            }
                                        },
                                        // 'clickmode': 'select+event',
                                        // 'dragmode': 'select',
                                    }
                                }
                                shapes={shapes}
                            />
                        </div>
                    </>}

                </>

            </div>
        </div>
    </>;
};