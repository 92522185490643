import classNames from 'classnames';
import { Skeleton } from 'elements/Skeleton';
import styles from '../Table.module.scss';
import { nanoid } from '@reduxjs/toolkit';

export const TableBody = ({ data, config, rowClickHandler, selectedCount }: any) => {
    const rowsJSX = data.map((rowData: any) => {
        const renderedCells = config.map((configItem: any) => {
            return (
                <td
                    // onClick={isClickable ? () => navigate(`${pathname}/${rowData.id}`) : undefined}
                    onClick={rowClickHandler ? () => rowClickHandler(rowData.id) : () => undefined}
                    className={classNames(styles.cellPadding, styles.cellText, {
                        [styles.cellTextBold]: configItem.label.toLowerCase().includes('name')
                    })}
                    key={configItem.label + rowData.id}
                >
                    <div>{configItem.render(rowData as any)}</div>
                </td>
            );
        });

        return <tr key={rowData.id}>{renderedCells}</tr>;
    });

    const skeletonTable = [...new Array(selectedCount)].map(() => {
        const emptyCellSkeleton = config.map(() => {
            return (
                <td key={nanoid()} className={classNames(styles.cellPadding)}>
                    <Skeleton.TableCell />
                </td>
            );
        });

        return <tr key={nanoid()}>{emptyCellSkeleton}</tr>;
    });

    return (<>
        <tbody>
            {data.length === 0 ? skeletonTable : rowsJSX}
        </tbody>
    </>);
};