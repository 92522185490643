import { Button } from 'components/Button';
import styles from '../styles.module.scss';

export const Buttons = () => {
    return (
        <table className={styles.table}>
            <tr>
                <td>Button - primary</td>
                <td>
                    <Button isPrimary>Button Primary</Button>
                </td>
            </tr>
            <tr>
                <td>Button default</td>
                <td>
                    <Button>Button Default</Button>
                </td>
            </tr>
            <tr>
                <td>Button - disabled</td>
                <td>
                    <Button disabled>Button</Button>
                </td>
            </tr>
            <tr>
                <td>Button - active</td>
                <td>
                    <Button active>Button</Button>
                </td>
            </tr>
            <tr>
                <td>Button - isWide(width will be 100% of container)</td>
                <td>
                    <Button isWide>Button</Button>
                </td>
            </tr>
            <tr>
                <td>Button - isOutline</td>
                <td>
                    <Button isOutline>Button</Button>
                </td>
            </tr>
            <tr>
                <td>Button - with icon</td>
                <td>
                    <Button icon={
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M2.5 7.5L17.5 7.5M7.5 2.5L7.5 17.5M6.5 2.5H13.5C14.9001 2.5 15.6002 2.5 16.135 2.77248C16.6054 3.01217 16.9878 3.39462 17.2275 3.86502C17.5 4.3998 17.5 5.09987 17.5 6.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V6.5C2.5 5.09987 2.5 4.3998 2.77248 3.86502C3.01217 3.39462 3.39462 3.01217 3.86502 2.77248C4.3998 2.5 5.09987 2.5 6.5 2.5Z" stroke="#FAFAFA" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    }>Button</Button>
                </td>
            </tr>
            <tr>
                <td>Button - custom styling
                    <br />
                    <code >
                        background: red,<br />
                        padding: 20px 40px,<br />
                        font-size: 18px<br />
                    </code>
                </td>
                <td>
                    <Button
                        style={{
                            background: 'red',
                            padding: '20px 40px',
                            fontSize: 18
                        }}>Button</Button>
                </td>
            </tr>
        </table>);
};