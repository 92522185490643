import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { createBaseQueryWithAuth } from '../createBaseQueryWithAuth';
import { ProductCountsResponse } from 'models/OpenReachProductCounts';

// Get OpenReach product counts.
export const productCountsAPI = createApi({
    reducerPath: 'productCountsAPI',
    baseQuery: createBaseQueryWithAuth(`${process.env.REACT_APP_REPORTS_API_URL}/api/v1`),
    endpoints: (builder) => ({
        getProductCounts: builder.query<ProductCountsResponse, {params: string}>({
            query: ({params}) => {

                return {
                    url: `/demographics/openreach/product-counts?${params}`,
                    method: 'GET',
                };
            },
        }),
    }),
});

// https://reports-staging.infranomics.ai/api/v1/demographics/openreach/product-counts/