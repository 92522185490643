export const selectToken = {
    token: {
        colorBgElevated: 'var(--bg-element)',
        colorBgContainer: 'var(--bg-element)',
        colorBorder: 'var(--border)',
        colorText: 'var(--text-regular)',
        colorTextPlaceholder: 'var(--text-regular)',
        fontSize: 14,

        controlOutline: 'var(--main-dark-blue)',
        controlOutlineWidth: 4,
        controlPaddingHorizontal: 14,
        controlHeight: 44,
        clearBg: 'transparent',
    },
    components: {
        Select: {
            multipleItemBg: 'rgba(255, 0, 0, 0)',
            multipleItemBorderColor: '#f00',
            multipleItemColorDisabled: '#f0f',
        },
    },

};