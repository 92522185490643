interface Props {
  isVisible: boolean;
}

export const PasswordIcon:React.FC<Props> = ({isVisible}) => {
    return (!isVisible ? 
      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="16" viewBox="0 0 27 16" fill="none">
      <path d="M13.5 0C5.954 0 0 7.714 0 7.714C0 7.714 5.954 15.429 13.5 15.429C19.27 15.429 27 7.714 27 7.714C27 7.714 19.27 0 13.5 0ZM13.5 12.52C10.85 12.52 8.693 10.364 8.693 7.714C8.693 5.064 10.85 2.907 13.5 2.907C16.15 2.907 18.307 5.064 18.307 7.714C18.307 10.364 16.15 12.52 13.5 12.52ZM13.5 4.908C13.1271 4.90096 12.7566 4.96832 12.41 5.10615C12.0635 5.24398 11.7479 5.44951 11.4817 5.71072C11.2155 5.97193 11.0041 6.28359 10.8597 6.62747C10.7154 6.97135 10.641 7.34055 10.641 7.7135C10.641 8.08645 10.7154 8.45565 10.8597 8.79953C11.0041 9.14341 11.2155 9.45507 11.4817 9.71628C11.7479 9.97749 12.0635 10.183 12.41 10.3208C12.7566 10.4587 13.1271 10.526 13.5 10.519C14.2349 10.5051 14.935 10.2034 15.4498 9.67882C15.9646 9.1542 16.253 8.44852 16.253 7.7135C16.253 6.97848 15.9646 6.2728 15.4498 5.74817C14.935 5.22355 14.2349 4.92188 13.5 4.908Z" fill="#686868"/>
    </svg>
    :
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="16" viewBox="0 0 27 16" fill="none">
    <path d="M13.5 0C5.954 0 0 7.714 0 7.714C0 7.714 5.954 15.429 13.5 15.429C19.27 15.429 27 7.714 27 7.714C27 7.714 19.27 0 13.5 0ZM13.5 12.52C10.85 12.52 8.693 10.364 8.693 7.714C8.693 5.064 10.85 2.907 13.5 2.907C16.15 2.907 18.307 5.064 18.307 7.714C18.307 10.364 16.15 12.52 13.5 12.52Z" fill="#686868"/>
  </svg>
  );
};