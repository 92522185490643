import { nanoid } from '@reduxjs/toolkit';
import { InputNumber, Tag } from 'antd';
import classNames from 'classnames';
import { Error } from 'components/Error';
import { Tab } from 'components/Tab';
import { Chart } from 'elements/Chart';
import { Select } from 'elements/Select';
import { Skeleton } from 'elements/Skeleton';
import { Table } from 'elements/Table';
import { useChartDataParams } from 'hooks/useChartDataParams';
import { useComparisonRequestData } from 'hooks/useComparisonRequestData';
import { PossibleUrlQuery, useQueryParams } from 'hooks/useQueryParams';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { nodeCompletionAPI } from 'services/JobSequencing';
import { projectReportAPI } from 'services/Project';
import { selectDashboard } from 'store/reducers/dashboardSlice';
import styles from '../style.module.scss';
import { dataCalculation } from './utils/dataCalculation';

export const NodeCompletion: React.FC = () => {
    // queries for main request
    const { queries } = useQueryParams([
        PossibleUrlQuery.scenario_uuids
    ]);

    // main request
    const {
        data: nodeCompletionData = [],
        isFetching: nodeCompletionLoading,
        error: nodeCompletionDataError
    } = nodeCompletionAPI.useGetNodeCompletionQuery({ params: queries }, {
        skip: !queries
    });

    // request for comparison mode
    const {
        comparisonReport: comparisonNodeCompletionData,
        isFetching: comparisonNodeCompletionDataLoading,
        error: comparisonNodeCompletionError
    } = useComparisonRequestData('/job-sequencing/node-completion', false);

    const { data: projectReportData = [] } = projectReportAPI.useGetProjectReportQuery();

    const { scenario_uuids, project_uuids } = useSelector(selectDashboard);

    const [selectedData, setSelectedData] = useState<string>('');
    const [day, setDay] = useState<number>(1);

    const projectsOptions: { value: string; label: string; }[] = projectReportData.filter((el) => scenario_uuids === el.scenario_uuid).map((element) => {
        return {
            value: element.uuid,
            label: element.name
        };
    });

    useEffect(() => {
        if (scenario_uuids && !project_uuids) {
            setSelectedData(projectsOptions[0].value);
        }

        if (project_uuids) {
            setSelectedData(project_uuids);
        }
    }, [scenario_uuids, project_uuids]);

    const { data: chartDataParams } = useChartDataParams(false, selectedData); // think!!!

    const isLoadingState = nodeCompletionLoading || comparisonNodeCompletionDataLoading;
    const isErrorState = nodeCompletionDataError || comparisonNodeCompletionError;

    if (isErrorState) {
        return <div className={styles.center}>
            <Error text='Something went wrong. Please try again later or click the button.' />
        </div>;
    }

    // main data object structure 
    // need this for possibility of compare data

    const comparisonDataCalculation = comparisonNodeCompletionData.map((el: any) => {
        return {
            ...el,
            response: dataCalculation(el.response, day, el.project.value),
        };
    });
    const chartData: {
        id: string,
        response: any,
        scenario: { value: string, label: string } | null,
        project: { value: string, label: string } | null,
    }[] = [
            {
                id: nanoid(),
                ...chartDataParams,
                response: dataCalculation(nodeCompletionData, day, selectedData),
            },
            ...comparisonDataCalculation
        ];

    const data = chartData.map((el) => {
        return {
            ...el,
            day: el.response.map((el: { complete_day: any; }) => el.complete_day),
            anNodeCount: el.response.map((el: { an_node_completed_cumsum: any; }) => el.an_node_completed_cumsum),
            znNodeCount: el.response.map((el: { zn_node_completed_cumsum: any; }) => el.zn_node_completed_cumsum),
            dnNodeCount: el.response.map((el: { dn_node_completed_cumsum: any; }) => el.dn_node_completed_cumsum),
            rfs: el.response.map((el: { total_rfs: any; }) => el.total_rfs)
        };
    });

    const anNodeCountPlot = data.map((el) => {
        return {
            x: el.day,
            y: el.anNodeCount,
            name: `${el?.project?.label} - Total AN Nodes`,
            type: 'line',
            hovertemplate: '%{fullData.name}: %{y}<extra></extra>',
            transforms: [
                {
                    type: 'sort',
                    target: el.anNodeCount,
                    order: 'ascending'
                }
            ],
            mode: 'lines+markers',
        };
    });
    const znNodeCountPlot = data.map((el) => {
        return {
            x: el.day,
            y: el.znNodeCount,
            name: `${el?.project?.label} - Total ZN Nodes`,
            type: 'line',
            hovertemplate: '%{fullData.name}: %{y}<extra></extra>',
            transforms: [
                {
                    type: 'sort',
                    target: el.znNodeCount,
                    order: 'ascending'
                }
            ],
            mode: 'lines+markers',
        };
    });
    const dnNodeCountPlot = data.map((el) => {
        return {
            x: el.day,
            y: el.dnNodeCount,
            name: `${el?.project?.label} - Total DN Nodes`,
            type: 'line',
            hovertemplate: '%{fullData.name}: %{y}<extra></extra>',
            transforms: [
                {
                    type: 'sort',
                    target: el.dnNodeCount,
                    order: 'ascending'
                }
            ],
            mode: 'lines+markers',
        };
    });
    const rfsCountPlot = data.map((el) => {
        return {
            x: el.day,
            y: el.rfs,
            name: `${el?.project?.label} - Total RFS`,
            yaxis: 'y2',
            type: 'line',
            hovertemplate: '%{fullData.name}: %{y}<extra></extra>',
            transforms: [
                {
                    type: 'sort',
                    target: el.rfs,
                    order: 'ascending'
                }
            ],
            mode: 'lines+markers',
        };
    });

    const plotData = [...anNodeCountPlot, ...znNodeCountPlot, ...dnNodeCountPlot, ...rfsCountPlot];

    // TABLE CONFIG
    const tableConfig = [
        {
            label: 'Working Days',
            render: (data: { complete_day: any; }) => data.complete_day + 1,
            sortValue: (data: { complete_day: any; }) => data.complete_day + 1,
        },
        {
            label: 'DN Nodes Delta',
            render: (data: { dn_node_ids: any; }) => Array.isArray(data.dn_node_ids) ? data.dn_node_ids.length : 0,
            sortValue: (data: { dn_node_ids: any; }) => Array.isArray(data.dn_node_ids) ? data.dn_node_ids.length : 0,
        },
        {
            label: 'DN Node Ids',
            render: (data: { dn_node_ids: any; }) => Array.isArray(data.dn_node_ids) && data.dn_node_ids.map((el: string) => <Tag color='magenta' key={el}>{el}</Tag>),
            sortValue: (data: { dn_node_ids: any; }) => Array.isArray(data.dn_node_ids) && data.dn_node_ids.length,
        },
        {
            label: 'Total DN Nodes',
            render: (data: { dn_node_completed_cumsum: any; }) => data.dn_node_completed_cumsum,
            sortValue: (data: { dn_node_completed_cumsum: any; }) => data.dn_node_completed_cumsum,
        },
        {
            label: 'ZN Nodes Delta',
            render: (data: { zn_node_ids: any; }) => Array.isArray(data.zn_node_ids) ? data.zn_node_ids.length : 0,
            sortValue: (data: { zn_node_ids: any; }) => Array.isArray(data.zn_node_ids) ? data.zn_node_ids.length : 0,
        },
        {
            label: 'ZN Nodes IDs',
            render: (data: { zn_node_ids: any; }) => data.zn_node_ids && data.zn_node_ids.map((el: string) => <Tag key={el} color='magenta'>{el}</Tag>),
            sortValue: (data: { zn_node_ids: any; }) => data.zn_node_ids?.length,
        },
        {
            label: 'Total ZN Nodes',
            render: (data: { zn_node_completed_cumsum: any; }) => data.zn_node_completed_cumsum,
            sortValue: (data: { zn_node_completed_cumsum: any; }) => data.zn_node_completed_cumsum,
        },
        {
            label: 'AN Nodes Delta',
            render: (data: { an_node_ids: any; }) => Array.isArray(data.an_node_ids) ? data.an_node_ids.length : 0,
            sortValue: (data: { an_node_ids: any; }) => Array.isArray(data.an_node_ids) ? data.an_node_ids.length : 0,
        },
        {
            label: 'AN Node IDs',
            render: (data: { an_node_ids: any; }) => data.an_node_ids && data.an_node_ids.map((el: string) => <Tag key={el} color='magenta'>{el}</Tag>),
            sortValue: (data: { an_node_ids: any; }) => data.an_node_ids?.length,
        },
        {
            label: 'Total AN Nodes',
            render: (data: { an_node_completed_cumsum: any; }) => data.an_node_completed_cumsum,
            sortValue: (data: { an_node_completed_cumsum: any; }) => data.an_node_completed_cumsum,
        },
        {
            label: 'Total RFS',
            render: (data: { total_rfs: any; }) => data.total_rfs,
            sortValue: (data: { total_rfs: any; }) => data.total_rfs,
        },
        {
            label: 'Live customers',
            render: (data: { live_customers: any; }) => Math.round(data.live_customers),
            sortValue: (data: { live_customers: any; }) => Math.round(data.live_customers),
        },
    ];

    const tabConfig = chartData.map((el, i) => {
        return {
            label: el.project?.label,
            key: i + '',
            children:
                <>
                    <Table
                        config={tableConfig}
                        data={el.response}
                    />

                </>
        };
    });
    return <div>
        <div className={classNames(styles.sliderBlock, styles.variableBlock)}>
            <p className={styles.sliderBlockTitle}>Select project: </p>
            <div className={classNames(styles.inputWrapper, styles.inputWrapperTransparent)}>
                <Select
                    data={projectsOptions}
                    value={selectedData}
                    onChange={setSelectedData}
                    width={{ width: '600px' }}
                />
            </div>
        </div>
        <div className={classNames(styles.sliderBlock, styles.variableBlock)}>
            <p className={styles.sliderBlockTitle}>Average Voucher Value</p>
            <div className={styles.inputWrapper}>
                <InputNumber
                    defaultValue={day}
                    step={1}
                    min={1}
                    onChange={(value: number | null) => setDay(value || 0)} />
            </div>

        </div>
        {
            isLoadingState && <Skeleton.ChartSkeleton />
        }

        {!isLoadingState && <>
            <div style={{ position: 'relative' }}>
                {/* <div className={styles.download}>
                    <CSVButton
                        data={chartData}
                        filename='CPPP.csv'
                        arrOfProps={['complete_day', 'an_node_completed_cumsum', 'zn_node_completed_cumsum', 'dn_node_completed_cumsum', 'total_rfs']}
                    />
                </div> */}
                <Chart plotData={!nodeCompletionLoading && plotData}
                    y='ROI in Years'
                    x='UPRN Count'
                    title='ROI vs Revenue P/A'
                    layout={{
                        yaxis2: {
                            title: 'RFS',
                            overlaying: 'y',
                            side: 'right',
                            showline: false,
                            linecolor: '#424242',
                            linewidth: 1,
                            showgrid: true,
                            gridwidth: 1,
                            family: 'Inter, sans-serif',
                            size: 12,
                            color: '#E5E5E5',
                            gridcolor: '#424242',
                            zerolinecolor: '#424242',
                            titlefont: {

                                family: 'Inter, sans-serif',
                                size: 12,
                                color: '#E5E5E5',
                            }
                        },
                        'hovermode': 'x unified',
                        'hoverlabel': {
                            'bgcolor': '#000',
                            'bordercolor': '#000',
                            'font': {
                                'color': '#fff',
                                'family': 'var(--default-font, var(--sans-serif))',
                                'size': 13
                            }
                        },
                        'clickmode': 'select+event',
                        'dragmode': 'select',
                    }}
                />

            </div>

        </>
        }
        {/* @ts-ignore */}
        <Tab config={tabConfig} defaultValue='0' />

    </div>;
};