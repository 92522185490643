import classNames from 'classnames';
import React, { useState } from 'react';
import style from './style.module.scss';
import { PasswordIcon } from './icon/PasswordIcon';

interface Props {
    type?: string;
    value?: any;
    placeholder?: string;
    error?: {
        message: string | undefined;
    };
    required?: boolean;
    onChange?: any;
    onBlur?: any;
    onInput?: any;
    onFocus?: any;
    Password?: any;
    disabled?: boolean;
    className?: string;
    props?: any;
    registerProps?: any;
}

enum InputTypes {
    PASSWORD = 'password',
    TEXT = 'text'
}
export const Input: React.FC<Props> & { Password: typeof Password } = ({
    type = 'text',
    required,
    value,
    placeholder = 'Input here',
    error,
    disabled,
    onChange = () => { },
    onBlur = () => { },
    onInput = () => { },
    onFocus = () => { },
    className,
    registerProps }) => {
    return (<>
        <div className={classNames(style.inputWrapper, className, {
            [style.disabled]: disabled
        })}>
            <input className={classNames(style.input)}
                type={type}
                value={value}
                disabled={disabled}
                placeholder={placeholder}
                required={required}
                onChange={(event: any) => onChange(event)}
                onBlur={(event: any) => onBlur(event)}
                onInput={(event: any) => onInput(event)}
                onFocus={(event: any) => onFocus(event)}
                {...registerProps}
            />
            {error && error.message && <span data-testid="error-message" className={style.errorMessage}>{error.message}</span>}
        </div>
    </>);
};

const Password: React.FC<Props> = ({
    value,
    required,
    placeholder,
    error,
    disabled,
    onChange = () => { },
    onBlur = () => { },
    onInput = () => { },
    onFocus = () => { },
    className,
    registerProps }) => {

    const [inputType, setInputType] = useState(InputTypes.PASSWORD);

    const handleChange = () => {
        setInputType((prev) => prev === InputTypes.PASSWORD ? InputTypes.TEXT : InputTypes.PASSWORD);
    };

    return (<div className={classNames(style.inputWrapper, className, {
        [style.disabled]: disabled
    })}>
        <input
            data-testid="input-password"
            className={style.input}
            type={inputType}
            value={value}
            placeholder={placeholder}
            required={required}
            onChange={(event: any) => onChange(event)}
            onBlur={(event: any) => onBlur(event)}
            onInput={(event: any) => onInput(event)}
            onFocus={(event: any) => onFocus(event)}
            {...registerProps}
        />
        <button
            type='button'
            onClick={handleChange}
            className={style.visibilityIcon}>
            <PasswordIcon isVisible={inputType === InputTypes.TEXT} />
        </button>
        {error && error.message && <span className={style.errorMessage}>{error.message}</span>}
    </div>);
};

Input.Password = Password;