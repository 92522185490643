import { ConfigProvider, Select as SelectAndt, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { scenarioReportAPI } from 'services/Scenarios/ScenarioReportService';
import { addAreaId, addScenarioId, selectDashboard } from 'store/reducers/dashboardSlice';
import styles from './Select.module.scss';
import { tagRender } from './utils/TagRender';
import { selectToken } from './utils/designToken';

const { Option } = SelectAndt;

interface Props {
    isBlock?: boolean;
}

const Selects: React.FC<Props> = () => {
    const { area_uuids: areaId, scenario_uuids: scenarioId } = useSelector(selectDashboard);
    const dispatch = useDispatch();

    const { data: scenarioReportData = [], isLoading: scenarioReportLoading } = scenarioReportAPI.useGetScenarioReportQuery();

    const handleClickAreas = (value: any) => {
        dispatch(addAreaId(value));
    };

    const handleClickScenario = (value: any) => {
        dispatch(addScenarioId(value));
    };

    const areasArray: { uuid: string, name: string }[] = [];

    for (const scenario of scenarioReportData) {
        const find = areasArray.find((el) => {
            return el.uuid === scenario.area_uuid;
        });

        if (!find) {
            areasArray.push({
                uuid: scenario.area_uuid,
                name: scenario.name
            });
        }
    }

    const areaOptions: { value: string; label: string; }[] = areasArray.map((element) => {
        return {
            value: element.uuid,
            label: element.name
        };
    });

    const scenarioOptions: { value: string; label: string; }[] = scenarioReportData.filter((el) => el.area_uuid === areaId).map((element) => {
        return {
            value: element.uuid,
            label: element.name
        };
    });

    const getOptionsJSX = (options: { value: string; label: string; }[]) => {
        const optionJSX = options.map((item) => (
            <Option key={item.value} value={item.value}>
                <Tooltip title={item.label} placement="right">
                    <div style={{ width: '100%' }}>
                        <div
                            style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            {item.label}
                        </div>
                    </div>
                </Tooltip>
            </Option>
        ));

        return optionJSX;
    };

    return <>
        <ConfigProvider theme={{
            token: selectToken,

            components: {
                Select: { // @ts-ignore
                    clearBg: '#737373',
                }
            }
        }}>
            <div className={styles.select}>
                <label className={styles.selectLabel}>Area: </label>
                <SelectAndt
                    showSearch
                    className={styles.select}
                    value={areaId}
                    style={{ width: '355px', maxWidth: '400px' }}
                    placeholder="All"
                    onChange={handleClickAreas}
                    tagRender={tagRender}
                    maxTagCount='responsive'
                    allowClear
                    onClear={() => {
                        dispatch(addAreaId(null));
                    }}
                >
                    {getOptionsJSX(areaOptions)}
                </SelectAndt>

            </div>
            <div className={styles.select}>
                <label className={styles.selectLabel}>Scenario: </label>
                <SelectAndt
                    showSearch
                    className={styles.select}
                    value={scenarioId}
                    disabled={scenarioReportLoading}
                    style={{ width: '355px', maxWidth: '400px' }}
                    placeholder="All"
                    onChange={handleClickScenario}
                    tagRender={tagRender}
                    allowClear
                    onClear={() => {
                        dispatch(addScenarioId(null));
                    }}
                >
                    {getOptionsJSX(scenarioOptions)}
                </SelectAndt>
            </div>
        </ConfigProvider >
    </>;
};

export default Selects;