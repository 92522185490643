import { Slider } from 'elements/Slider';
import styles from '../styles.module.scss';

export const Sliders = () => {

    return (
        <table className={styles.table}>
            <tr>
                <td>Slider</td>
                <td>
                    <Slider
                        value={10}
                        max={30}
                    />
                </td>
            </tr>
            <tr>
                <td>Slider - range</td>
                <td>
                    <Slider
                        isRange={true}
                        min={0}
                        max={5000}
                        value={[30, 70]}
                        step={1}
                        // eslint-disable-next-line no-console
                        onChange={(val: any) => console.log(val)}
                    />
                </td>
            </tr>
        </table>);
};