import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'components/Button';
import { Checkbox } from 'elements/Checkbox';
import { Input } from 'elements/Input';
import { useAuth } from 'hooks/useAuth';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Bounce, toast } from 'react-toastify';
import { tokenAPI } from 'services/Token';
import { LoginInputs, defaultValues, validationSchema } from './FormValidation';
import style from './style.module.scss';

import { StartFormLayout } from 'elements/StartFormLayout';
import 'react-toastify/dist/ReactToastify.css';

export const LoginPage: React.FC = () => {
    const { setToken } = useAuth();
    const [fetchLoginToken, { error: fetchLoginTokenError }] = tokenAPI.useFetchLoginTokenMutation();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        control
    } = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = async (data: typeof defaultValues) => {
        const body = new URLSearchParams({
            username: data.email,
            password: data.password,
            scope: 'all'
        });

        const result = await fetchLoginToken(body);
        // @ts-ignore
        setToken(result?.data?.access_token);
    };

    useEffect(() => {
        // @ts-ignore
        if (fetchLoginTokenError && fetchLoginTokenError?.status) {
            // @ts-ignore
            toast.error(fetchLoginTokenError?.data?.detail, {
                position: 'top-right',
                autoClose: 9000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [fetchLoginTokenError]);

    watch(LoginInputs.EMAIL);
    watch(LoginInputs.PASSWORD);
    watch(LoginInputs.REMEMBER);

    return (<>
        <StartFormLayout>
            <div className=''>
                <h1 className={style.title}>Welcome back!</h1>
                <p className={style.subtitle}>Please enter your details</p>
            </div>
            {/* @ts-ignore */}
            <form onSubmit={handleSubmit(onSubmit)} className={style.form}>
                <Input
                    type='text'
                    placeholder='Email'
                    className={style.input}
                    error={{
                        message: errors.email?.message
                    }}
                    registerProps={
                        { ...register(LoginInputs.EMAIL, { required: true }) }
                    }
                />
                <Input.Password
                    placeholder='Password'
                    className={style.input}
                    error={{
                        message: errors.password?.message
                    }}
                    registerProps={
                        { ...register(LoginInputs.PASSWORD, { required: true }) } // trigger form submit 
                    }
                />
                <div className={style.flexContainer}>
                    <Controller
                        name={LoginInputs.REMEMBER}
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <Checkbox
                            text='Remember for 30 days'
                            isChecked={field.value}
                            onChange={field.onChange}
                            registerProps={
                                { ...register(LoginInputs.REMEMBER) }
                            }
                        />}
                    />
                </div>
                <Button type="submit" isWide className={style.button}>
                    Log in
                </Button>
            </form>

            {/* <p className={style.signUpText}>Don’t have an account? <Link to={ROUTES.SIGN_UP}>Sign up</Link></p> */}
        </StartFormLayout>
    </>);
};