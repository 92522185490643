import { FC, ReactNode, useMemo, useState } from 'react';
import { Theme, ThemeContext } from '../lib/ThemeContext';
// import { useThemeDetector } from 'hooks/useThemeDetector';

interface ThemeProviderProps {
    children: ReactNode
}

export const ThemeProvider: FC<ThemeProviderProps> = (props) => {
    // const isDarkTheme = useThemeDetector();

    // const usersTheme = isDarkTheme ? Theme.DARK : Theme.LIGHT;

    // const defaultTheme = localStorage.getItem(LOCAL_STORAGE_THEME_KEY) as Theme || Theme.LIGHT;
    const defaultTheme = Theme.LIGHT;

    const [theme, setTheme] = useState<Theme>(defaultTheme);
    
    const defaultProps = useMemo(() => ({
        theme,
        setTheme,
    }), [theme]);

    return (
        <ThemeContext.Provider value={defaultProps}>
            {props.children}
        </ThemeContext.Provider>
    );
};