export const getPieData = (data: any) => {
    const dataObj = data[0];

    const config = {
        accommodation: {
            prefix: 'accommodation_',
            dataValues: [
                'accommodation_detached',
                'accommodation_semi_detached',
                'accommodation_terraced',
                'accommodation_purpose_built_block_of_flats_or_tenement',
                'accommodation_part_of_converted_or_shared_house',
                'accommodation_part_of_other_converted_building',
                'accommodation_in_a_commercial_building',
                'accommodation_caravan_or_other_mobile_or_temporary_structure',

            ]
        },
        bedrooms: {
            prefix: 'beds_',
            dataValues: [
                'beds_1',
                'beds_2',
                'beds_3',
                'beds_4_or_more',
            ]
        },
        health: {
            prefix: 'health_',
            dataValues: [
                'health_very_good_health',
                'health_good_health',
                'health_fair_health',
                'health_bad_health',
                'health_very_bad_health',
            ]
        },
        qualifications: {
            prefix: 'qualifications_',
            dataValues: [
                // "qualifications_no_qualifications",
                // "qualifications_level_1_and_entry_level",
                // "qualifications_level_2",
                // "qualifications_apprenticeship",
                // "qualifications_level_3",
                // "qualifications_level_4_and_above",
                // "qualifications_other",
                'qualification_no_qualifications',
                'qualification_level_1_and_entry_level',
                'qualification_level_2',
                'qualification_apprenticeship',
                'qualification_level_3',
                'qualification_level_4_and_above',
                'qualification_other',
            ]
        },
        economic_activity: {
            prefix: 'economic_',
            dataValues: [
                'economic_110_active_exc_students_employed', // uprn_count / economic_active_exc_students_employed
                'economic_120_active_exc_students_unemployed',
                'economic_210_active_student_employed',
                'economic_220_active_student_unemployed',
                'economic_310_inactive_retired',
                'economic_310_inactive_student',
                'economic_310_inactive_looking_after_home_or_family',
                'economic_310_inactive_long_term_sick_or_disabled',
                'economic_310_inactive_other',
                // "economic_active_exc_students",
                // "economic_active_exc_students_employed",
                // "economic_active_exc_students_employed_employee",
                // "economic_active_exc_students_employed_employee_part_time",
                // "economic_active_exc_students_employed_employee_full_time",
                // "economic_active_exc_students_self_employed_with_employees",
                // "economic_active_exc_students_self_employed_part_time_with_employees",
                // "economic_active_exc_students_self_employed_full_time_with_employees",
                // "economic_active_exc_students_self_employed_no_employees",
                // "economic_active_exc_students_self_employed_part_time_no_employees",
                // "economic_active_exc_students_self_employed_full_time_no_employees",
                // "economic_active_exc_students_unemployed",
                // "economic_active_student",
                // "economic_active_student_employed",
                // "economic_active_student_employed_employee",
                // "economic_active_student_employed_employee_part_time",
                // "economic_active_student_employed_employee_full_time",
                // "economic_active_student_self_employed_with_employees",
                // "economic_active_student_self_employed_part_time_with_employees",
                // "economic_active_student_self_employed_full_time_with_employees",
                // "economic_active_student_self_employed_no_employees",
                // "economic_active_student_self_employed_part_time_no_employees",
                // "economic_active_student_self_employed_full_time_no_employees",
                // "economic_active_student_unemployed",
                // "economic_inactive",
                // "economic_inactive_retired",
                // "economic_inactive_student",
                // "economic_inactive_looking_after_home_or_family",
                // "economic_inactive_long_term_sick_or_disabled",
                // "economic_inactive_other",

            ]
        },
        household_composition: {
            prefix: 'household_',
            dataValues: [
                'household_11_one_person_aged_66_and_over',
                'household_11_one_person_other',
                'household_21_single_family_all_aged_66_and_over',
                'household_22_single_family_married_or_civil_couple',
                'household_23_single_family_cohabiting_couple_family',
                'household_24_single_family_lone_parent_family',
                'household_25_single_family_other_single_family_household',
                'household_31_other_with_dependent_children',
                'household_31_other_including_all_full_time_students_and_all_aged_66_years_an'

            ],
        },
        household_size: {
            prefix: 'household_size_',
            dataValues: [
                'household_size_0',
                'household_size_1',
                'household_size_2',
                'household_size_3',
                'household_size_4',
                'household_size_5',
                'household_size_6',
                'household_size_7',
                'household_size_8_or_more',
            ],
        },
        occupation: {
            prefix: 'occupation_',
            dataValues: [
                'occupation_managers_directors_and_senior_officials',
                'occupation_professional_occupations',
                'occupation_associate_professional_and_technical_occupations',
                'occupation_administrative_and_secretarial_occupations',
                'occupation_skilled_trades_occupations',
                'occupation_caring_leisure_and_other_service_occupations',
                'occupation_sales_and_customer_service_occupations',
                'occupation_process_plant_and_machine_operatives',
                'occupation_elementary_occupations',
            ]
        },
    };

    const pieData: { [key: string]: any } = { total_uprns: dataObj?.oa_property_count };
    for (const [key, value] of Object.entries(config)) {
        const pieValues = value.dataValues.map((v) => {

            return 100 * Number(dataObj[v]);
        });
        const pieLabels = value.dataValues.map((v) => { return v.replace(value.prefix, ''); });
        pieData[key] = {
            labels: pieLabels,
            values: pieValues,
        };
    };

    return { pieData };

};