import { Input } from 'elements/Input';
import style from './style.module.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema, defaultValues, LoginInputs } from './FormValidation';
import { ROUTES } from 'constants/routes';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'components/Button';
import { useEffect } from 'react';
import { Bounce, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { StartFormLayout } from 'elements/StartFormLayout';
import { userAPI } from 'services/User';

export const SignupPage = () => {
    const params = useParams() as { id: string };
  
    const [signupUser, { error: signupUserError }] = userAPI.useSignupUserMutation();
    const [createUser, { error: createUserError }] = userAPI.useCreateUserMutation();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = async (data: typeof defaultValues) => {
        // ask about
        if(params.id) {
            await signupUser({
                username: data.name,
                email: data.email,
                password: data.password,
                token: params.id
            });
        } else {
            await createUser({
                username: data.name,
                email: data.email,
                password: data.password
            });

        }
    };

    useEffect(() => {
        // @ts-ignore
        if (signupUserError && signupUserError?.status) {
            // @ts-ignore
            toast.error(signupUserError?.data?.detail, {
                position: 'top-right',
                autoClose: 9000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }

        // @ts-ignore
        if (createUserError && createUserError?.status) {
            // @ts-ignore
            toast.error(createUserError?.data?.detail, {
                position: 'top-right',
                autoClose: 9000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [signupUserError, createUserError]);

    watch(LoginInputs.NAME);
    watch(LoginInputs.EMAIL);
    watch(LoginInputs.PASSWORD);

    return (<>
        <StartFormLayout>
            <div className=''>
                <h1 className={style.title}>Create an account</h1>
                <p className={style.subtitle}>Please enter your details</p>
            </div>
            {/* @ts-ignore */}
            <form onSubmit={handleSubmit(onSubmit)} className={style.form}>
                <Input
                    type='text'
                    placeholder='Enter your name'
                    className={style.input}
                    error={{
                        message: errors.name?.message
                    }}
                    registerProps={
                        { ...register(LoginInputs.NAME, { required: true }) }
                    }
                />
                <Input
                    type='text'
                    placeholder='Enter your email'
                    className={style.input}
                    error={{
                        message: errors.email?.message
                    }}
                    registerProps={
                        { ...register(LoginInputs.EMAIL, { required: true }) }
                    }
                />
                <Input.Password
                    placeholder='Create password'
                    className={style.input}
                    error={{
                        message: errors.password?.message
                    }}
                    registerProps={
                        { ...register(LoginInputs.PASSWORD, { required: true }) }
                    }
                />
                <Button type="submit" isWide className={style.button}>
                    Get started
                </Button>
            </form>

            <p className={style.signUpText}>Already have an account? <Link to={ROUTES.SIGN_IN}>Log in</Link></p>
        </StartFormLayout>
    </>);
};