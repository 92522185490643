import classNames from 'classnames';
import { Select } from 'elements/Select';
import { PossibleUrlQuery, useQueryParams } from 'hooks/useQueryParams';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { projectReportAPI } from 'services/Project';
import { projectSummaryReportAPI } from 'services/ProjectSummary/ProjectSummaryReportService';
import { scenarioReportAPI } from 'services/Scenarios/ScenarioReportService';
import { addComparisonAreaId } from 'store/reducers/comparisonSlice';
import { addAreaId, addProjectsId, addScenarioId, selectDashboard } from 'store/reducers/dashboardSlice';
import styles from './Select.module.scss';

const SelectProjects: React.FC = () => {
    const params: any = useParams();
    const dispatch = useDispatch();
    const { area_uuids: areaId, project_uuids: projectsId, } = useSelector(selectDashboard);

    const [selectedProjects, setSelectedProjects] = useState(projectsId);

    const { data: scenarioReportData = [] } = scenarioReportAPI.useGetScenarioReportQuery();
    const { data: projectReportData = [] } = projectReportAPI.useGetProjectReportQuery();

    // queries for main request
    const { queries } = useQueryParams([
        PossibleUrlQuery.scenario_uuids,
        PossibleUrlQuery.aggregate_projects
    ], {
        [PossibleUrlQuery.aggregate_projects]: false
    });

    // main request
    const {
        data: projectSummaryData = []
    } = projectSummaryReportAPI.useGetProjectReportQuery({ params: queries }, {
        skip: !queries
    });

    useEffect(() => {
        if (projectsId === null) {
            setSelectedProjects(null);
        } else {
            setSelectedProjects(projectsId);
        }
    }, [projectsId]);

    useEffect(() => {
        if (areaId === null) {
            const areaIdValue = scenarioReportData.find((el) => el.area_uuid === params.param1)?.area_uuid;
            dispatch(addAreaId(areaIdValue));
            dispatch(addComparisonAreaId(areaIdValue));
        }

        dispatch(addScenarioId(params.param1));
        dispatch(addProjectsId(projectsId));

    }, [params]);

    useEffect(() => {
        dispatch(addScenarioId(params.param1));
        dispatch(addProjectsId(projectsId));
    }, []);

    const scenarioOptions: { value: string; label: string; }[] = projectSummaryData.map((element) => {
        return {
            value: element.project_uuid!,
            label: projectReportData.find((item) => item.uuid === element.project_uuid)!.name
        };
    });

    const handleClickProject = (value: any) => {
        dispatch(addProjectsId(value));
        setSelectedProjects(value);
    };

    return <div data-testid="SelectProjects" className={classNames(styles.sort)}>
        <Select
            data={scenarioOptions}
            label='Projects name:'
            value={selectedProjects}
            isAllowClear
            onChange={handleClickProject}
            width={{ width: '300px' }}
            antdProps={{
                onClear: () => {
                    dispatch(addProjectsId(null));
                }
            }}
        />
    </div>;
};

export default SelectProjects;