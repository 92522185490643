import { getArrayOfUniqueObjectsById } from 'utils/arrayOfUniqueValues/arrayOfUniqueValues';

export const setRecent = (key = 'recent', dataObj = {}) => {
    const dataFromLS = JSON.parse(localStorage.getItem(key) || '[]');

    if (dataFromLS.length) {
        const newRecent = dataFromLS.slice(0, 2);

        newRecent.unshift(dataObj);

        localStorage.setItem(key, JSON.stringify(getArrayOfUniqueObjectsById(newRecent)));
    } else {
        localStorage.setItem(key, JSON.stringify([dataObj]));
    }
};