import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { createBaseQueryWithAuth } from '../createBaseQueryWithAuth';
import { CapexResponse } from 'models/CapexOpex';

export const capexAPI = createApi({
    reducerPath: 'capexAPI',
    baseQuery: createBaseQueryWithAuth(`${process.env.REACT_APP_REPORTS_API_URL}/api/v1`),
    endpoints: (builder) => ({
        getCapex: builder.query<CapexResponse, {params: string}>({
            query: ({params}) => {

                return {
                    url: `/costs/capex?${params}`,
                    method: 'GET',
                };
            },
        }),
    }),
});