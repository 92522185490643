import styles from './Navigation.module.scss';
import { ROUTES } from 'constants/routes';
import { useAuth } from 'hooks/useAuth';
import { NavItem } from 'elements/NavItem';
import { AreaIcon, HomeIcon, LogoIcon, ScenarioIcon, TeamIcon } from './icon';
import { Link } from 'react-router-dom';

interface Props {
}

const Navigation: React.FC<Props> = () => {
    const { token } = useAuth();

    if (!token) {
        return null;
    }
    return <div data-testid="navigation" className={styles.navigation}>
        <Link to={ROUTES.ROOT} className={styles.logoContainer} data-testid="nav-logo">
            <div className={styles.logo}>
                <LogoIcon />
            </div>
            <div className={styles.logoText}>
                <h2>Infranomics</h2>
            </div>
        </Link>
        <NavItem data-testid="nav-home"
            link={ROUTES.ROOT}
            icon={<HomeIcon />}>
            Home
        </NavItem>
        <NavItem data-testid="nav-area"
            link={ROUTES.AREAS}
            icon={<AreaIcon />}>
            Area
        </NavItem>
        <NavItem data-testid="nav-scenario"
            link={ROUTES.DASHBOARD}
            icon={<ScenarioIcon />}>
            Scenario
        </NavItem>
        <NavItem data-testid="nav-team"
            link={ROUTES.CREATE_USER}
            icon={<TeamIcon />}>
            Team
        </NavItem>
    </div>;
};

export default Navigation;