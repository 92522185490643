import { useTheme, Theme } from 'app/providers/ThemeProvider';
import { DarkThemeIcon } from './icons/DarkThemeIcon';
import { LightThemeIcon } from './icons/LightThemeIcon';
import style from './style.module.scss';
import classNames from 'classnames';

export const ThemeSwitcher = () => {
    const { theme, toggleTheme } = useTheme();

    return (<>
        <div className={style.themeContainer} data-testid='theme-switcher'>
            <input
                className={style.themeInput}
                type='checkbox'
                onChange={toggleTheme}
            />
            <div className={classNames(style.themeItem, {
            [style.themeItemActive]: theme === Theme.LIGHT
        })}>
            <DarkThemeIcon fill={theme === Theme.LIGHT ? 'var( --theme-switcher-active-item-svg)' : undefined} />
        </div>
        <div className={classNames(style.themeItem, {
            [style.themeItemActive]: theme !== Theme.LIGHT
        })}>
            <LightThemeIcon fill={theme !== Theme.LIGHT ? 'var( --theme-switcher-active-item-svg)' : undefined} />
        </div>
        </div>
    </>);
};