export const numberOfEntries = [
    {
        value: 5,
        label: '5'
    },
    {
        value: 7,
        label: '7'
    },
    {
        value: 10,
        label: '10'
    },
    {
        value: 14,
        label: '14'
    },
];