import classNames from 'classnames';
import React from 'react';
import style from './style.module.scss';
import { SearchIcon } from './icon/SearchIcon';

interface Props {
    value?: any;
    placeholder?: string;
    onChange?: any;
    onBlur?: any;
    onInput?: any;
    onFocus?: any;
    className?: string;
}
export const SearchBox: React.FC<Props> = ({
    value, 
    placeholder = 'Search here...',
    onChange = () => { }, 
    onBlur = () => { }, 
    onInput = () => { }, 
    onFocus = () => { } ,
    className
}) => {
    return (<>
        <div className={classNames(style.inputWrapper, className)}>
            <input className={classNames(style.input)}
                type='text'
                value={value}
                placeholder={placeholder}
                onChange={(event: any) => onChange(event)}
                onBlur={(event: any) => onBlur(event)}
                onInput={(event: any) => onInput(event)}
                onFocus={(event: any) => onFocus(event)}
            />
            <SearchIcon className={style.searchIcon} />
        </div>
    </>);
};