import styles from './Sort.module.scss';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { Select } from '../Select';
import { Favourite } from '../Favourite';
import { useState } from 'react';
import { AreaModal } from 'components/AreaModal';
import { Button } from 'components/Button';

interface Props {
}

const Sort: React.FC<Props> = () => {
    const params = useParams();

    const [showModal, setShowModal] = useState(false);

    const areaPage = Object.keys(params).length === 0;
    const scenariosPage = Object.keys(params).length === 1;
    const projectsPage = Object.keys(params).length === 2;

    return <div data-testid="Sort" className={classNames(styles.sort,
        { [styles.width420]: projectsPage },
        { [styles.width482]: scenariosPage }
    )}>
        {(scenariosPage || projectsPage) && <Select />}
        {scenariosPage && <Favourite />}
        {
            areaPage ?
                <Button onClick={() => setShowModal(true)}
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M9.99996 4.16602V15.8327M4.16663 9.99935H15.8333" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>}
                    style={{
                        padding: '10px 16px',
                        fontSize: '14px'
                    }}
                >
                    Add Area

                </Button>
                : scenariosPage ?
                    <Button onClick={() => undefined}
                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M9.99996 4.16602V15.8327M4.16663 9.99935H15.8333" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>}
                        style={{
                            padding: '10px 16px',
                            fontSize: '14px'
                        }}
                    >
                        Add Scenario

                    </Button>
                    :
                    <Button onClick={() => undefined}
                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M9.99996 4.16602V15.8327M4.16663 9.99935H15.8333" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>}
                        style={{
                            padding: '10px 16px',
                            fontSize: '14px'
                        }}
                    >
                        Add Project

                    </Button>
        }
        {showModal && <AreaModal setShowModal={setShowModal} />}
    </div>;
};

export default Sort;