import styles from '../styles.module.scss';
import { Select } from 'elements/Select';

export const Selects = () => {

    const data = [
        {
            value: 'item 1',
            label: 'item 1'
        },
        {
            value: 'item 2',
            label: 'item 2'
        },
        {
            value: 'item 3',
            label: 'item 3'
        }
    ];

    return (
        <table className={styles.table}>
            <tr>
                <td>Select</td>
                <td>
                    <Select data={data} />
                </td>
            </tr>
            <tr>
                <td>Select - preselected value </td>
                <td>
                    <Select data={data} value={data[1]} />
                </td>
            </tr>
            <tr>
                <td>Select - with label text </td>
                <td>
                    <Select
                        data={data}
                        label='Label:'
                    />
                </td>
            </tr>
            <tr>
                <td>Select - is tag mode </td>
                <td>
                    <Select
                        isTags
                        data={data}
                        label='Label:'
                    />
                </td>
            </tr>
            <tr>
                <td>Select - custom width styles </td>
                <td>
                    <Select
                        data={data}
                        width={
                            { width: '400px', }
                        }
                    />
                </td>
            </tr>
            <tr>
                <td>Select - allow clear </td>
                <td>
                    <Select
                        data={data}
                        isAllowClear
                    />
                </td>
            </tr>
        </table>);
};