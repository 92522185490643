import React from 'react';
import styles from './Button.module.scss';
import classNames from 'classnames';

interface Props {
    children?: any;
    onClick?: () => void;
    icon?: any;
    active?: boolean;
    disabled?: boolean;
    isWide?: boolean;
    style?: any;
    isOutline?: boolean;
    isPrimary?: boolean;
    isRound?: boolean;
    type?: 'button' | 'submit' | 'reset' | undefined;
    className?: string;
}

const Button: React.FC<Props> = ({ className, type = 'button', children, onClick = () => { }, icon, active, disabled, isWide, isRound, style, isOutline, isPrimary }) => {
    const clickHandler = () => {
        onClick();
    };

    return (<button
        data-testid='Button'
        className={classNames(styles.button, className, {
            [styles.active]: active,
            [styles.wide]: isWide,
            [styles.disabled]: disabled,
            [styles.outline]: isOutline,
            [styles.primary]: isPrimary,
            [styles.round]: isRound
        })}
        onClick={clickHandler}
        disabled={disabled}
        style={{
            ...style
        }}
        type={type}
    >

        {icon}
        {children}
    </button>);
};

export default Button;