import styles from '../styles.module.scss';
import { Checkbox } from 'elements/Checkbox';
import { useState } from 'react';

export const Checkboxes = () => {
    const [checked, setChecked] = useState(false);

    return (
        <table className={styles.table}>
            <tr>
                <td>Checkbox</td>
                <td>
                    <Checkbox text='Remember me' isChecked={checked} onChange={() => setChecked(prev => !prev)} />
                </td>
            </tr>
            <tr>
                <td>Checkbox - checked</td>
                <td>
                    <Checkbox text='Remember me' isChecked={!checked} onChange={() => setChecked(prev => !prev)} />
                </td>
            </tr>
            <tr>
                <td>Checkbox - disabled</td>
                <td>
                    <Checkbox 
                    isDisabled
                    isChecked={checked} 
                    text='Remember me' 
                    onChange={() => setChecked(prev => !prev)} />
                </td>
            </tr>
           
        </table>);
};