import styles from '../DemographicsTable.module.scss';
import { Table } from 'elements/Table';
import { projectReportAPI } from 'services/Project';
import { numberFormatter } from 'utils/priceFormatter';

export const DemographicsTable = ({ data = [] }: any) => {
    const columns = Object.keys(data[0]);

    const filterColumns = columns.filter((el) => el !== 'oa_property_count' && el !== 'project_uuid');
    filterColumns.unshift('oa_property_count');

    const { data: projectReportData = [] } = projectReportAPI.useGetProjectReportQuery();

    if (columns.includes('project_uuid')) {
        filterColumns.unshift('project_uuid');
    }

    const tableConfig = filterColumns.map((key) => {

        const configObj: {
            label: string,
            render: (data: any) => string,
            sortValue: (data: any) => any
        } = {
            label: key.split('_').map((el) => el[0].toUpperCase() + el.slice(1)).join(' '),
            render: (data: any) => `${((data[key]) * 100).toFixed(3)}%`,
            sortValue: (data: any) => data[key],
        };
        if (key === 'project_uuid') {
            configObj.label = 'Project';
            configObj.render = (data: any) => projectReportData.find((el) => el.uuid === data[key])?.name || '';
        }

        if (key === 'oa_property_count') {
            configObj.label = 'Property Count';
            configObj.render = (data: any) => numberFormatter(data[key]);
        }

        return configObj;
    });

    return (<div className={styles.tablesSection}>
        <div className={styles.tableSection}>
            <Table
                config={tableConfig}
                data={data} />
        </div>

    </div>);
};