import { NodeCompletion } from 'models/NodeCompletion';

export const dataCalculation = (data: NodeCompletion[], day: number, selectedData: string) => {
    const groupBy = (items: any[], key: string | number) =>
        items.reduce(
            (result, item) => ({
                ...result,
                [item[key]]: [...(result[item[key]] || []), item],
            }),
            {}
        );
    const sumBy = (items: any, key: string | number) => items.reduce((a: any, b: any) => a + (b[key] || 0), 0);

    // Reference external variables with curly brackets or using JS variables
    // const data = formatDataAsArray({{ nodeDayCreation.data }});
    const project = selectedData;
    const dayGroupIndex = day;
    // return data.filter(v => v.project == project);

    const projectData = data.filter(v => v.project_uuid === project);
    const projectDataIdx = projectData.map(v => {
        const day = (v.complete_day).toPrecision(15);
        const day2 = (dayGroupIndex).toPrecision(15);
        const result = parseFloat(day);
        const result2 = parseFloat(day2);
        const r = result / result2;

        return {
            day_group: Math.floor(r),
            ...v
        };
    });

    const groupedDayIdx: { [key: string]: NodeCompletion[] } = groupBy(projectDataIdx, 'day_group');
    const returnData = [];

    for (const [, values] of Object.entries(groupedDayIdx)) {
        const dn_node_ids_raw = values.map(v => v.dn_node_ids).filter(v => !!v);
        const dn_node_ids = dn_node_ids_raw.length === 0 ? null : dn_node_ids_raw.flat();
        const zn_node_ids_raw = values.map(v => v.zn_node_ids).filter(v => !!v);
        const zn_node_ids = zn_node_ids_raw.length === 0 ? null : zn_node_ids_raw.flat();
        const an_node_ids_raw = values.map(v => v.an_node_ids).filter(v => !!v);
        const an_node_ids = an_node_ids_raw.length === 0 ? null : an_node_ids_raw.flat();
        const lastValue = values[values.length - 1];

        returnData.push({
            complete_day: lastValue.complete_day + 1,
            project: values[0].project_uuid,
            dn_nodes_completed: sumBy(values, 'dn_nodes_completed'),
            dn_node_ids,
            dn_node_completed_cumsum: lastValue.dn_node_completed_cumsum,
            zn_nodes_completed: sumBy(values, 'zn_nodes_completed'),
            zn_node_ids,
            zn_node_completed_cumsum: lastValue.zn_node_completed_cumsum,
            an_nodes_completed: sumBy(values, 'an_nodes_completed'),
            an_node_ids,
            an_node_completed_cumsum: lastValue.an_node_completed_cumsum,
            // total_rfs: lastValue.total_rfs,
            live_customers: lastValue.live_customers,
        });
    };

    return returnData;
};