import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userAPI } from 'services/User';
import { selectDashboard } from 'store/reducers/dashboardSlice';
import { urlFormat } from 'utils/urlFormat';

export enum PossibleUrlQuery {
    area_uuids = 'area_uuids',
    // company_uuids = 'company_uuids',
    scenario_uuids = 'scenario_uuids',
    project_uuids = 'project_uuids',
    aggregate_projects = 'aggregate_projects',
    geom_type = 'geom_type',
}
export const useQueryParams = (queriesParams: PossibleUrlQuery[], params?: { [key: string]: any }) => {
    const [queries, setQueries] = useState('');
    const { data: currentUser } = userAPI.useGetCurrentUserQuery();
    // const companyUUID = currentUser?.company_uuid;

    const dashboardSelectData = useSelector(selectDashboard);
    const obj: { [key: string]: string | boolean | number } = {};

    for (const key of queriesParams) {
        obj[key] = dashboardSelectData[key];
    }
    obj.company_uuids = '018d5f82-80c1-7879-9001-cf6bcc4424da'; // temporary solution

    if (params) {
        for (const key in params) {
            obj[key] = params[key];
        }
    }

    useEffect(() => {
        if (queriesParams.every(el => obj[el] || obj[el] === false)) {
            const params = urlFormat(obj);
            setQueries(params);
        }
    }, [queriesParams, currentUser]);

    return {
        queries,
    };
};
