import classNames from 'classnames';
import style from './style.module.scss';

interface Props { 
    text: string;
    isChecked?: boolean;
    onChange?: any;
    isDisabled?: boolean;
    registerProps?: any;
}
export const Checkbox:React.FC<Props> = ({text, isChecked = false, onChange = () => {}, isDisabled, registerProps}) => {
    return (<>
        <label className={classNames(style.checkboxWrap, {
            [style.disabled]: isDisabled
        })} onChange={!isDisabled && onChange}>
            <div className={style.checkbox}>
                <input type="checkbox" checked={isChecked} disabled={isDisabled} onChange={() => {}} {...registerProps}/>
                <span className={classNames(style.checkmark, {
                    [style.checkmarkActive]: isChecked
                })}></span>
            </div>
            {text}
        </label>
    </>);
};