import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const polygonAPI = createApi({
  reducerPath: 'polygonAPI',
  // baseQuery: createBaseQueryWithAuth(`${process.env.REACT_APP_REPORTS_API_URL}/api/v1`),
  baseQuery: fetchBaseQuery({ baseUrl: 'https://atlas-polygon.infranomics.ai/api/v1' }),
  endpoints: (builder) => ({
    getPolygon: builder.mutation<any, { body: any }>({
      query: ({ body }) => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');

        return {
          headers,
          url: '/polygon',
          method: 'POST',
          body
        };
      },
    }),
  }),
});
