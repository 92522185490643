import classNames from 'classnames';
import { Button } from 'components/Button';
import { BillOfMaterials } from 'components/Charts/BillOfMaterials';
// import { CPPP } from 'components/Charts/CPPP';
import { BestProjectByROI } from 'components/Charts/BestProjectbyROI';
import { CPPP } from 'components/Charts/CPPP';
import { Cashflow } from 'components/Charts/Cashflow';
import { Demographics } from 'components/Charts/Demographics';
import { Materials } from 'components/Charts/Materials';
import { MultiScenarioProjectPlots } from 'components/Charts/MultiScenarioProjectPlots';
import { NodeCompletion } from 'components/Charts/NodeCompletion';
import { OpenReachLineSpeeds } from 'components/Charts/OpenReachLineSpeeds';
import { Pruning } from 'components/Charts/Pruning';
import { Variables } from 'components/Charts/Variables/Variables';
import { Workdays } from 'components/Charts/Workdays';
import { ComparisonChartModal } from 'components/ComparisonChartModal/ComparisonChartModal';
import { Tab } from 'components/Tab';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFullComparisonData, deleteItemInComparisonData, selectComparisonData } from 'store/reducers/comparisonSlice';
import { Tag } from '../../components/Tag';
import styles from './HelicopterView.module.scss';

export const Charts: React.FC = () => {
    const comparisonData = useSelector(selectComparisonData);

    const dispatch = useDispatch();
    const [isModalQShow, setisModalQShow] = useState<boolean>(false);
    const [isUseDataFromLS] = useState<boolean | null>(null);

    useEffect(() => {
        const getDataFromLS = localStorage.getItem('comparisonData');

        if (getDataFromLS) {
            setisModalQShow(true);
        }
    }, []);

    useEffect(() => {

        if (isUseDataFromLS === true) {
            const getDataFromLS = JSON.parse(localStorage.getItem('comparisonData') || '[]');

            getDataFromLS.length && dispatch(addFullComparisonData(getDataFromLS));

        }

        if (isUseDataFromLS === false) {
            localStorage.removeItem('comparisonData');
        }

    }, [isModalQShow, isUseDataFromLS]);

    useEffect(() => {
        if (comparisonData.length) {
            localStorage.setItem('comparisonData', JSON.stringify(comparisonData));
        }

    }, [comparisonData]);

    const [isModalShown, setIsModalShown] = useState<boolean>();
    const [isEditMode, setEditMode] = useState<boolean>(false);
    const [editId, setEditId] = useState<string>('');

    const [averageVoucherValue, setAverageVoucherValue] = useState<number>(1000);
    const [averageOPPPPerAnnum, setAverageOPPPPerAnnum] = useState<number>(0.00);

    const [revenuePeriod, setRevenuePeriod] = useState<number>(6);
    const [CPPPAssetValue1, setCPPPAssetValue1] = useState<number>(1500.00);
    const [CPPCAssetValue, setCPPCAssetValue] = useState<number>(3000.00);
    const [borrowingCouponRate, setBorrowingCouponRate] = useState<number>(0.12);

    const handleDelete = (id: string) => {
        dispatch(deleteItemInComparisonData(id));
    };

    const handleEdit = (id: string) => {
        setIsModalShown(true);
        setEditMode(true);
        setEditId(id);
    };

    const tagsJSX = comparisonData?.map((el: any) => {
        const { area, projects, scenario, id } = el;

        return <Tag
            key={id}
            id={id}
            areaName={area.label}
            scenarioName={scenario.label}
            projectName={projects?.label || []}
            onDelete={() => handleDelete(id)}
            onEdit={() => handleEdit(id)}
        />;
    });

    // cashflow
    const tabConfig = [
        {
            label: 'By Scenario',
            key: 'By_Scenario_Requirements',
            children:
                <>
                    <Cashflow
                        isScenario={true}
                    />
                    <Materials
                        isScenario={true}
                    />

                    <Workdays
                        isScenario={true}
                    />
                </>
        },
        {
            label: 'By Project',
            key: 'By_Project_Requirements',
            children:
                <>
                    <Cashflow
                        isScenario={false}
                    />
                    <Materials
                        isScenario={false}
                    />
                    <Workdays
                        isScenario={false}
                    />
                </>
        }
    ];

    // bill of materials
    const tabConfigBillOfMaterials = [
        {
            label: 'By Scenario',
            key: 'By_Scenario_Bill',
            children:
                <>
                    <BillOfMaterials
                        isScenario={true} />
                </>
        },
        {
            label: 'By Project',
            key: 'By_Project_Bill',
            children:
                <>
                    <BillOfMaterials
                        isScenario={false} />
                </>
        }
    ];

    // Open Reach Line Speeds
    const tabOpenReachLineSpeeds = [
        {
            label: 'By Scenario',
            key: 'By_Scenario_Line_Speeds',
            children:
                <> <OpenReachLineSpeeds
                    isScenario={true}
                />
                </>
        },
        {
            label: 'By Project',
            key: 'By_Project_Line_Speeds',
            children:
                <>
                    <div className={classNames(styles.openreachTables)}>

                        <OpenReachLineSpeeds
                            isScenario={false}
                        />
                    </div>
                </>
        }
    ];

    return <>
        <div className={styles.comparisonSection}>

            <Button onClick={() => setIsModalShown(true)}
                icon={
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M10 4.16699V15.8337M4.16667 10.0003H15.8333" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                }>
                <span className={styles.plus} />Add Comparison
            </Button>
            {tagsJSX}
        </div>
        {/* 🟥 - Map View of scenarios more questions than answers */}

        {/* <div className={styles.chartSection}>
            <div className={styles.chartHeader}>
                <h2 className={styles.chartTitle}>Map View of Scenarios</h2>
            </div>
            <div className={styles.chartBody}>
                <MapPlot
                    scenarioIds={scenarioId || []}
                />
            </div>
        </div> */}

        {/* 🟨 - Variables */}
        <div className={styles.chartSection}>
            <div className={styles.chartHeader}>
                <h2 className={styles.chartTitle}>Variables</h2>
            </div>
            <div className={styles.chartBody}>
                <div>
                    <Variables
                        setAverageOPPPPerAnnum={setAverageOPPPPerAnnum}
                        setAverageVoucherValue={setAverageVoucherValue}
                        setRevenuePeriod={setRevenuePeriod}
                        setCPPPAssetValue1={setCPPPAssetValue1}
                        setCPPCAssetValue={setCPPCAssetValue}
                        setBorrowingCouponRate={setBorrowingCouponRate}
                        averageVoucherValue={averageVoucherValue}
                        averageOPPPPerAnnum={averageOPPPPerAnnum}
                        revenuePeriod={revenuePeriod}
                        CPPPAssetValue1={CPPPAssetValue1}
                        CPPCAssetValue={CPPCAssetValue}
                        borrowingCouponRate={borrowingCouponRate}
                    />
                </div>
            </div>
        </div>

        {/* 🟨 - API doesn't exist */}
        {/* <div className={styles.chartSection}>
            <div className={styles.chartHeader}>
                <h2 className={styles.chartTitle}>Scenario Summary</h2>
            </div>
            <ScenarioSummaryTables areaId={areaId as number}
                scenarioIds={scenarioId || []}
                projectIds={projectId || []}

                comparisonData={comparisonData}
                variables={{
                    averageVoucherValue,
                    avgOpppPa: averageOPPPPerAnnum,
                    revenuePeriod,
                    cpppAssetValue: CPPPAssetValue1,
                    couponRate: borrowingCouponRate,
                    cppcAssetvalue: CPPCAssetValue,
                }}
            />
        </div> */}

        {/* 🟨 - check values */}
        <CPPP variables={{
            averageVoucherValue,
            avgOpppPa: averageOPPPPerAnnum,
            revenuePeriod,
            cpppAssetValue: CPPPAssetValue1,
            couponRate: borrowingCouponRate,
            cppcAssetvalue: CPPCAssetValue,
        }} />

        {/* ✅ DONE - MultiScenarioProjectPlots */}
        <div className={styles.chartSection}>
            <div className={styles.chartHeader}>
                <h2 className={styles.chartTitle}>Multi Scenario Project Plots</h2>

            </div>
            <div className={styles.chartBody}>
                <MultiScenarioProjectPlots
                    variables={{
                        averageVoucherValue,
                        avgOpppPa: averageOPPPPerAnnum,
                        revenuePeriod,
                        cpppAssetValue: CPPPAssetValue1,
                        couponRate: borrowingCouponRate,
                        cppcAssetvalue: CPPCAssetValue,
                    }} />
            </div>
        </div>

        {/* ✅ DONE - BestProjectByROI */}
        <div className={styles.chartSection} style={{ marginTop: '60px' }}>
            <div className={styles.chartHeader}>
                <h2 className={styles.chartTitle}>Best Project for Each Area by ROI</h2>
            </div>
            <BestProjectByROI variables={{
                averageVoucherValue,
                avgOpppPa: averageOPPPPerAnnum,
                revenuePeriod,
                cpppAssetValue: CPPPAssetValue1,
                couponRate: borrowingCouponRate,
                cppcAssetvalue: CPPCAssetValue,
            }}
            />
        </div>

        {/* ✅ DONE - Cashflow, Customers, Materials & Workday Requirements */}
        <div className={styles.chartSection}>
            <div className={styles.chartHeader}>
                <h2 className={styles.chartTitle}>Cashflow, Customers, Materials & Workday Requirements</h2>
            </div>
            <div className={styles.chartBody}>
                <div className={styles.download}>
                </div>
                <Tab config={tabConfig} defaultValue='By_Scenario_Requirements' />

            </div>
        </div>

        {/* ✅ DONE - Pruning */}
        <div className={styles.chartSection}>
            <div className={styles.chartHeader}>
                <h2 className={styles.chartTitle}>Project Pruning</h2>
            </div>
            <div className={styles.chartBody}>
                <div className={styles.download}>
                </div>
                <Pruning />
            </div>
        </div>

        {/* ✅ DONE - NodeCompletion */}
        <div className={styles.chartSection}>
            <div className={styles.chartHeader}>
                <h2 className={styles.chartTitle}>Node Completion</h2>

            </div>
            <div className={styles.chartBody}>
                <NodeCompletion />
            </div>
        </div>

        {/* ✅ DONE - NodeCompletion */}
        <div className={styles.chartSection}>
            <div className={styles.chartHeader}>
                <h2 className={styles.chartTitle}>Bill of Materials</h2>
            </div>
            <div className={styles.chartBody}>
                <div className={styles.download}>
                </div>
                <Tab config={tabConfigBillOfMaterials} defaultValue='By_Scenario_Bill' />

            </div>
        </div>

        {/* ✅ DONE - OpenReachLineSpeeds */}
        <div className={styles.chartSection}>
            <div className={styles.chartHeader}>
                <h2 className={styles.chartTitle}>Open Reach Line Speeds</h2>
            </div>
            <div className={styles.chartBody}>
                <div className={styles.download}>
                </div>
                <Tab config={tabOpenReachLineSpeeds} defaultValue='By_Scenario_Line_Speeds' />

            </div>
        </div>

        {/* ✅ DONE - Demographics */}
        <div className={styles.chartSection}>
            <div className={styles.chartHeader}>
                <h2 className={styles.chartTitle}>Demographics</h2>
            </div>
            <div className={styles.chartBody}>
                <div className={styles.download}>
                </div>
                <Demographics />
            </div>
        </div>

        {isModalShown && <ComparisonChartModal
            onClose={() => {
                setIsModalShown(false);
                setEditMode(false);
                setEditId('');
            }}
            isEditMode={isEditMode}
            editId={editId}
        />}

        {/* {isModalQShow && <DataFromLSModal
            handleClose={() => {
                setisModalQShow(false);
            }}
            handleConfirm={() => {
                setUsageDataFromLS(true);
                setisModalQShow(false);
            }}
            handleCancel={
                () => {
                    setisModalQShow(false);
                    setUsageDataFromLS(false);
                }
            }
        />} */}

    </>;

};
