import classNames from 'classnames';
import styles from './BreadCrumbs.module.scss';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getCrumbName } from '../utils/getCrumbName';
import { Breadcrumb } from 'elements/Breadcrumb';

interface Props {
}

const BreadCrumbs: React.FC<Props> = () => {
  const location = useLocation();

  const breadcrumbs = location.pathname.split('/').filter(segment => segment !== '');
  const [, setCurrentIndex] = useState(0);

  useEffect(() => {
    setCurrentIndex(breadcrumbs.length - 1);
  }, [breadcrumbs]);

  const items = breadcrumbs.map((breadcrumb, index) => {
    const isLast = index === breadcrumbs.length - 1;
    const path = `/${breadcrumbs.slice(0, index + 1).join('/')}`;

    return isLast ? {
      title: <span data-testid='crumb' className={classNames(styles.crumb, { [styles.active]: isLast })}>
        {getCrumbName(index)}
      </span>
    } : {
      title: <Link
        data-testid='crumb'
        to={path}
        className={classNames(styles.crumb, { [styles.active]: isLast })}
      >
        {getCrumbName(index)}
      </Link>
    };
  });

  return (<>
    <Breadcrumb
      items={items}
    />
  </>);
};

export default BreadCrumbs;