import styles from '../styles.module.scss';
import { Input } from 'elements/Input';
import { useState } from 'react';

export const Inputs = () => {
    const [changeValue, setChangeValue] = useState<string>('');

    return (
        <table className={styles.table}>
            <tr>
                <td>Input - text</td>
                <td>
                    <Input
                        type='text'
                        placeholder='Input'
                    />
                </td>
            </tr>
            <tr>
                <td>Input - with value</td>
                <td>
                    <Input
                        type='text'
                        placeholder='Input'
                        value={10}
                    />
                </td>
            </tr>
            <tr>
                <td>Input - onChange</td>
                <td>
                    <Input
                        type='text'
                        placeholder='Input'
                        value={changeValue}
                        onChange={(e: any) => {
                            setChangeValue(e.target.value);
                        }}
                    />
                </td>
            </tr>
            <tr>
                <td>Input - onFocus</td>
                <td>
                    <Input
                        type='text'
                        placeholder='Input'
                        // eslint-disable-next-line no-console
                        onFocus={() => console.log('Element on Focus!')}
                    />
                </td>
            </tr>
            <tr>
                <td>Input - onBlur</td>
                <td>
                    <Input
                        type='text'
                        placeholder='Input'
                        // eslint-disable-next-line no-console
                        onBlur={() => console.log('Element on Blur!')}
                    />
                </td>
            </tr>

            <tr>
                <td>Input - disabled</td>
                <td>
                    <Input
                        type='text'
                        placeholder='Input'
                        disabled
                    />
                </td>
            </tr>

            <tr>
                <td>Input.Password</td>
                <td>
                    <Input.Password
                        placeholder='Input'
                    />
                </td>
            </tr>

        </table>);
};