import classNames from 'classnames';
import styles from '../Table.module.scss';

export const getIcon = (
  label: string,
  sortBy: string | null,
  sortOrder: string | null) => {
    
  let asc = null;

  if (label !== sortBy || sortOrder === null) {
    return;
  }

  if (sortOrder === 'asc') {
    asc = true;
  }

  if (sortOrder === 'desc') {
    asc = false;
  }

  return (
  <svg className={classNames(styles.arrow, { [styles.asc]: asc })} xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
  <path d="M4.5 4.5L0 0H9L4.5 4.5Z" fill="var(--icon-color)"/>
</svg>);
};
